// actions.js
import { SET_SELECTED_RESOURCES } from '../constants/actionTypes';

const initialState = {
  selectedResources: [],
};

const resourcelistReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_RESOURCES:
      return {
        ...state,
        selectedResources: action.selectedResources,
      };
    default:
      return state;
  }
};

export default resourcelistReducer;
