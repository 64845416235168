import React from 'react';
import cyberstransparent from '@assets/img/brand-logo.svg';

const AppLogoLoader = () => {
  return (
    <div
      style={{
        minWidth: '100vw',
        height: '100vh',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0',
        left: '0',
      }}>
      <img src={cyberstransparent} style={{ width: '252px' }} alt="logo" />
    </div>
  );
};

export default AppLogoLoader;
