import { Box, Skeleton } from '@mui/material';

function CloudProviderBarSkeleton() {
  return (
    <Box
      className="Borders RoundedCorners"
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap="10px"
      height="40px"
      marginBottom="15px"
      backgroundColor="white"
      paddingLeft="10px"
      paddingRight="10px">
      <Skeleton width="80px" />
      <Skeleton width="80px" />
      <Skeleton width="80px" />
      <Skeleton width="80px" />
    </Box>
  );
}

export default CloudProviderBarSkeleton;
