import React from 'react';
import PropTypes from 'prop-types';
import { default as ReactSelect, components } from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
import { Box } from '@mui/material';

const MultiReactSelect = ({ disabled, isSearchable = true, isMulti, ...props }) => {
  const style = () => ({
    // control: styles => ({ ...styles, backgroundColor: 'transparent' }),
    control: base => ({
      ...base,
      borderRadius: 8,
      // transtion: 0.3,
      color: '#767980',
      fontFamily: 'Manrope',
      fontSize: '14px',
      fontWeight: '600',
      // lineHeight: "24px",
      // width: "min(100%, 570px)",
      // minheigh: 46,
      padding: 12,
      // height: state.isFocused ? 46 : 46,
      // boxShadow: 0,
      // "&:hover": {
      // cursor: "pointer",
      // fontWeight: "400",
      border: '1px solid #cbd5e0',
      height: 45,
      backgroundColor: 'transparent',
    }),
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        border: '1px solid',
      };
    },
    multiValueLabel: styles => ({
      ...styles,
      color: 'black',
    }),
    menu: provided => ({
      ...provided,

      background: 'white',
    }),
  });

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Box
        // style={{ transform: open ? 'rotate(180deg)' : '', transition: 'all 200ms' }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
            <path
              d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
              fill="#484D56"
            />
          </svg>
        </Box>
      </components.DropdownIndicator>
    );
  };

  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        components={{
          animatedComponents,
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        style={style}
        isMulti={isMulti}
        isDisabled={disabled}
        className="basic-multi-select"
        classNamePrefix="select"
        closeMenuOnSelect={false}
        options={[props.allOption, ...props.options]}
        onChange={selected => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return props.onChange(props.options);
          } else {
            return props.onChange(selected);
          }
        }}
        isSearchable={isSearchable}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      isMulti={isMulti}
      components={{
        animatedComponents,
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      style={style}
      className="basic-multi-select"
      classNamePrefix="select"
      isDisabled={disabled}
      isSearchable={isSearchable}
    />
  );
};

MultiReactSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

MultiReactSelect.defaultProps = {
  allOption: {
    value: '*',
    label: 'Select all',
  },
};

export default MultiReactSelect;
