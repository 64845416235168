import { applyMiddleware, createStore } from 'redux';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// import { promiseMiddleware, localStorageMiddleware } from './middleware';
import reducer from './reducers/_reducer';

//import { routerMiddleware } from 'react-router-redux'
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = routerMiddleware(history);

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('remediationData');
    if (serializedState === null) {
      return undefined;
    }
    return { riskRremediation: { data: JSON.parse(serializedState) } };
  } catch (err) {
    return undefined;
  }
};
const saveState = state => {
  try {
    const serializedState = JSON.stringify(state.riskRremediation.data);
    localStorage.setItem('remediationData', serializedState);
  } catch {
    // Ignore write errors.
  }
};

const persistedState = loadState();

const getMiddleware = () => {
  if (import.meta.env.MODE === 'production') {
    // return applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware, thunk);
    return applyMiddleware(myRouterMiddleware, thunk);
  } else {
    // Enable additional logging in non-production environments.
    // return applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware, thunk, createLogger());
    return applyMiddleware(myRouterMiddleware, thunk);
  }
};

export const store = createStore(reducer, persistedState, composeWithDevTools(getMiddleware()));

store.subscribe(() => {
  saveState(store.getState());
});
