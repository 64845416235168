import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Box } from '@mui/material';
import CustomDropdownOption from './ScopeDropdownOption';

const ScopeDropdown = ({ options, value, setValue, disabled, isLoading, singleSelect, onChange }) => {
  //   const options = [
  //     { label: 'Drift', children: ['Create', 'Modify', 'Delete'] },
  //     { label: 'Threat' },
  //     { label: 'Remediation', children: ['One Click / Manual', 'Auto Remediation'] },
  //   ];
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleOutsideClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  // const handleOnOptionSelect = option => {
  //   //check if selected options contains the passed option. if yes, remove it from selected options. otherwise add
  //   if (value.some(op => op.value === option.value)) {
  //     setValue(prev => prev.filter(op => op.value !== option.value));
  //   } else {
  //     setValue(prev => [...prev, option]);
  //   }
  // };

  return (
    <div className="CustomDropdown__Container" ref={ref}>
      <Box
        className="CustomDropdown__ValueContainer"
        onClick={() => setOpen(prev => !prev)}
        style={{ pointerEvents: disabled || isLoading ? 'none' : '', width: '150px' }}>
        <input
          className="CustomDropdown__ValueInput"
          onChange={() => {}}
          style={{ width: '115px' }}
          value={
            value.length === options.length
              ? 'Scope: Global'
              : `Scope: ${value[0]} ${value.length > 1 ? `+${value.length > 1 ? value.length - 1 : ''}` : ''}`
          }
        />
        <div style={{ flexGrow: '1' }} />
        <Box
          className="CustomDropdown__DropdownArrow"
          style={{ transform: open ? 'rotate(180deg)' : '', transition: 'all 200ms', height: '40px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
            <path
              d="M-3.1883e-08 9.7294C-2.31213e-08 9.52895 0.0703583 9.35078 0.211075 9.21715C0.492508 8.92762 0.985017 8.92762 1.26645 9.21715L4.50293 12.2684L7.71596 9.21715C7.99739 8.92762 8.4899 8.92762 8.77134 9.21715C9.07622 9.48441 9.07622 9.95212 8.77134 10.2194L5.01889 13.7829C4.73746 14.0724 4.24495 14.0724 3.96352 13.7829L0.211075 10.2194C0.0703582 10.0857 -3.96713e-08 9.90757 -3.1883e-08 9.7294Z"
              fill="#484D56"></path>
          </svg>
        </Box>
      </Box>

      <div className="CustomDropdown__Options" style={{ display: open ? 'block' : 'none' }}>
        {options.map((option, index) => (
          <CustomDropdownOption
            key={`${index * 2}`}
            option={option}
            index={index}
            length={options.length}
            options={options}
            onClick={onChange}
            // selected={value.some(op => op === option.value) || value.some(op => op === 'Global')}
            selected={value.includes('Global') || value.includes(option.value)}
            value={value}
            setValue={setValue}
            singleSelect={singleSelect}
          />
        ))}
      </div>
    </div>
  );
};

export default ScopeDropdown;
