import { SET_TIME_FILTER, SET_TIME_RANGE } from '@root/constants/actionTypes';
import { subMinutes, format } from 'date-fns';
const now = new Date();
const from = subMinutes(now, 15);

const initialState = {
  timeFilter: '24h', // Initial value for time filter
  timeRange: { from: format(from, 'yyyy/MM/dd HH:mm:ss'), to: format(now, 'yyyy/MM/dd HH:mm:ss') }, // Initial value for time range
};

const timeFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIME_FILTER:
      return { ...state, timeFilter: action.payload };
    case SET_TIME_RANGE:
      return { ...state, timeRange: action.payload };
    default:
      return state;
  }
};

export default timeFilterReducer;
