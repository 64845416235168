import _ from 'lodash';
import { FETCH_RESOURCE_DETAIL, CLEAR_RESOURCE_DETAIL } from '@constants/actionTypes';

const defaultState = {
  tableData: [],
  specifications: [],
  resourceType: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_RESOURCE_DETAIL:
      if (!_.isEmpty(action.payload))
        return {
          ...state,
          tableData: action.payload['data'],
          specifications: action.payload['specifications'],
          resourceType: action.payload['resourceType'],
        };
      break;
    case CLEAR_RESOURCE_DETAIL:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
