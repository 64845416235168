import React from 'react';
import Flex from './Flex';
import './loader.scss';
const Loader = props => (
  <Flex style={{ height: props.height || '100%', justifyContent: 'center', alignItems: 'center' }}>
    <div className="loader default default-01">
      <div className="loader-container">
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
      </div>
    </div>
  </Flex>
);

export default Loader;
