import { FETCH_DASHBOARD_DATA, FETCH_USER_POLICY_DATA, FETCH_USER_POLICY_TABLE } from '@constants/actionTypes';
import { axiosHelper } from '@root/services/axios';

export const fetchDashboardData = async dispatch => {
  const account_info = {
    account_id: '375018187935',
    report_for: 'dashboard',
  };
  const res = await axiosHelper.axiosPost('scanresult', { data: account_info });
  //console.log(res)
  // const res = await agent.Cspa.scan_repot(account_info);
  return dispatch({
    type: FETCH_DASHBOARD_DATA,
    payload: res,
  });
};

export const fetchuserPolicyData = async dispatch => {
  const res = await axiosHelper.axiosGet('user_policy', true);
  if (res) {
    window.localStorage.setItem('User Policy', JSON.stringify(res.data.permission));
  }
  return dispatch({
    type: FETCH_USER_POLICY_DATA,
    payload: res,
  });
};

export const fetchuserPolicyTableData = async dispatch => {
  const res = await axiosHelper.axiosPost('user_policy_table', true);
  if (res) {
    window.localStorage.setItem('User Policy Table', JSON.stringify(res.data.data));
  }
  return dispatch({
    type: FETCH_USER_POLICY_TABLE,
    payload: res,
  });
};
