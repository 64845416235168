import Box from "@mui/material/Box";
import agent from "@root/agent";
import InfoBox from "@root/components/InfoBox";
import MultiReactSelect from "@root/components/ui/multiReactSelect";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import Input from "reactstrap/lib/Input";
import "./style.scss";
import Tooltip from "@root/components/v2/ui-elements/Tooltip";

const customSelect = {
  control: (baseStyles) => ({
    ...baseStyles,
    border: "1px solid #CBD5E0;",
    borderRadius: "5px",
    padding: "4px",
    boxShadow: "0px 8px 18px 0px rgba(93, 106, 131, 0.05);",

    color: "#484D56",
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: "5px",
    padding: "10px",

    color: "#484D56",
    fontSize: "14px",
    fontFamily: "Manrope",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: "#767980",
    background: state.isFocused ? "#EDF2F7" : "#FFF",

    fontSize: "14px",
    fontFamily: "Manrope",
    ":active": {
      ...baseStyles[":active"],
      backgroundColor: state.isSelected ? "#FFF" : "#EDF2F9",
    },
  }),
  indicatorSeparator: () => ({}),
  input: (baseStyles) => ({
    ...baseStyles,
    color: "#484D56",
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
  }),
};

const SetupForm = ({
  isEditMode,
  currentSetup,
  environments,
  META,
  setDisableNext,
  setCurrentSetup,
  setupErrorMessages,
  canShowSetupErrors,
  isPolicyNameAlreadyExisting,
}) => {
  const selectedEnvironment = useMemo(() => {
    if (!currentSetup.envId) return;

    return environments.find((item) => item.id === currentSetup.envId.value);
  }, [currentSetup.envId, environments]);

  useEffect(() => {
    if (
      META?.data.environments.filter(
        (item) => item.enviromentId === currentSetup.envId?.value.toString()
      )[0] &&
      !META?.data.environments.filter(
        (item) => item.enviromentId === currentSetup.envId?.value.toString()
      )[0]?.autoRemediation &&
      !META?.data.environments.filter(
        (item) => item.enviromentId === currentSetup.envId?.value.toString()
      )[0]?.oneClickRemediation
    ) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [currentSetup, META, setDisableNext]);

  const { data: scanPresent, isFetching: isFetchingScanPresent } = useQuery(
    [`scan_present`],
    () =>
      agent.Cspa.scan_present({
        scope: ["Global"],
        environments: [],
        cloud_provider: "aws",
      })
  );

  // Validate if selected environment requires OCR to be enabled.
  useEffect(() => {
    if (selectedEnvironment) {
      if (
        selectedEnvironment &&
        selectedEnvironment.oneClickRemediation &&
        (!selectedEnvironment.lastScanTime ||
          selectedEnvironment.scanStatus !== "complete")
      ) {
        setCurrentSetup((currentConfig) => ({
          ...currentConfig,
          ocrSetupRequired: true,
        }));
        // } else if (
        //   selectedEnvironment &&
        //   selectedEnvironment.oneClickRemediation &&
        //   !selectedEnvironment.ocrEnabledInEnvironment &&
        //   selectedEnvironment.lastCompletedScan
        // ) {
        //   setCurrentSetup((currentConfig) => ({
        //     ...currentConfig,
        //     ocrSetupRequired: true,
        //   }));
      } else {
        setCurrentSetup((currentConfig) => ({
          ...currentConfig,
          ocrSetupRequired: false,
        }));
      }
    }
  }, [selectedEnvironment, setCurrentSetup]);

  // Fetch environments
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await agent.OCR.get_policy_meta();
  //       setEnvironments(response.data.environments);
  //     } catch (err) {
  //     }
  //   })();
  // }, []);

  return (
    <div id="setupForm" style={{ width: "100%" }}>
      <div>
        <div
          style={{
            color: "#1A202C",
            fontSize: "14px",
            fontFamily: "Manrope",
            fontWeight: 700,
          }}
        >
          Policy Name<span style={{ color: "red" }}>*</span>{" "}
          <Tooltip title={"Enter a unique policy name"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              style={{
                position: "relative",
                marginLeft: "4.5px",
                top: "-1.5px",
                transform: "rotate(180deg)",
              }}
            >
              <path
                d="M8 4.5V8.7M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                stroke="#718096"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M8.6998 10.7996C8.6998 11.1862 8.3864 11.4996 7.9998 11.4996C7.61321 11.4996 7.2998 11.1862 7.2998 10.7996C7.2998 10.413 7.61321 10.0996 7.9998 10.0996C8.3864 10.0996 8.6998 10.413 8.6998 10.7996Z"
                fill="#718096"
              />
            </svg>
          </Tooltip>
        </div>
        <Input
          className="SetupForm__Input"
          value={currentSetup.policyName ?? ""}
          onChange={(e) => {
            e.persist();
            setCurrentSetup((currentConfig) => ({
              ...currentConfig,
              policyName: e.target.value,
            }));
          }}
          type="text"
          placeholder="Enter Policy Name"
        ></Input>

        {canShowSetupErrors && setupErrorMessages.nameError ? (
          <Box color="#f00">{setupErrorMessages.nameError}</Box>
        ) : null}

        {isPolicyNameAlreadyExisting ? (
          <Box color="#f00">Name already in use.</Box>
        ) : null}
      </div>
      <div>
        <div
          style={{
            color: "#1A202C",
            fontSize: "14px",
            fontFamily: "Manrope",
            fontWeight: 700,
            marginBottom: "10px",
          }}
        >
          Environment ID<span style={{ color: "red" }}>*</span>{" "}
          <Tooltip title={"Select single environment ID"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              style={{
                position: "relative",
                marginLeft: "5px",
                top: "-1.5px",
                transform: "rotate(180deg)",
              }}
            >
              <path
                d="M8 4.5V8.7M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                stroke="#718096"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M8.6998 10.7996C8.6998 11.1862 8.3864 11.4996 7.9998 11.4996C7.61321 11.4996 7.2998 11.1862 7.2998 10.7996C7.2998 10.413 7.61321 10.0996 7.9998 10.0996C8.3864 10.0996 8.6998 10.413 8.6998 10.7996Z"
                fill="#718096"
              />
            </svg>
          </Tooltip>
        </div>
        <MultiReactSelect
          disabled={isEditMode}
          allowSelectAll={false}
          name="env_select"
          value={currentSetup.envId}
          onChange={(e) => {
            setCurrentSetup((currentConfig) => ({
              ...currentConfig,
              envId: e,
            }));
          }}
          inputProps={{
            id: "env_select",
          }}
          className="env_select"
          placeholder="Select Environment"
          options={environments.map((env) => ({
            label: env.id,
            value: env.id,
            data: env,
          }))}
          styles={customSelect}
        />

        {canShowSetupErrors && setupErrorMessages.environmentIdError ? (
          <Box color="#f00">{setupErrorMessages.environmentIdError}</Box>
        ) : null}
      </div>
      {/* {selectedEnvironment &&
      selectedEnvironment.ocrEnabledInContract &&
      selectedEnvironment.ocrEnabledInEnvironment &&
      !selectedEnvironment.lastCompletedScan ? (
        <InfoBox
          message="Please run a full scan and then proceed with policy creation."
          style={{ marginTop: "24px" }}
        />
      ) : selectedEnvironment &&
        selectedEnvironment.ocrEnabledInContract &&
        !selectedEnvironment.ocrEnabledInEnvironment &&
        selectedEnvironment.lastCompletedScan ? (
        <InfoBox
          message="Please enable Automated remediation module for the selected environment then proceed with policy creation."
          style={{ marginTop: "24px" }}
        />
      ) : selectedEnvironment &&
        (!selectedEnvironment.ocrEnabledInContract ||
          !selectedEnvironment.lastCompletedScan) ? (
        <InfoBox
          message="Please enable Automated remediation module and run a full scan for the selected environment and then proceed with policy creation."
          style={{ marginTop: "24px" }}
        />
      ) : null} */}
      {META?.data.environments.filter(
        (item) => item.enviromentId === currentSetup.envId?.value.toString()
      )[0] &&
      !META?.data.environments.filter(
        (item) => item.enviromentId === currentSetup.envId?.value.toString()
      )[0]?.autoRemediation &&
      !META?.data.environments.filter(
        (item) => item.enviromentId === currentSetup.envId?.value.toString()
      )[0]?.oneClickRemediation ? (
        <InfoBox
          message='Please enable "Automated Remediation" or "One Click Remediation" module for the selected environment then proceed with policy creation.'
          style={{ marginTop: "24px" }}
        />
      ) : null}
      {!scanPresent?.data?.data?.is_scan && !isFetchingScanPresent ? (
        <InfoBox
          message="Please run a full scan and then proceed with policy creation."
          style={{ marginTop: "24px" }}
        />
      ) : null}
    </div>
  );
};

export default SetupForm;
