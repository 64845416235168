import { useEnvironmentsData } from '@root/hooks/environments';

export const GetCloudProvider = (envId: string) => {
  const { data } = useEnvironmentsData();

  return data?.data?.find(env => env.id === envId)?.cloud_provider;
};

export const GetEnvTags = (envId: string) => {
  const { data } = useEnvironmentsData();

  return data?.data?.find(env => env.id === envId)?.tags.map(tag => tag.name);
};

// export const GetEnvName = (envId: string) => {
//   const { data } = useEnvironmentsData();

//   return data?.data?.find(env => env.id === envId)?.id;
// };
