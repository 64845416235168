import _ from 'lodash';
import {
  CLOUD_INVENTORY_GLOBAL_FILTERS_SET,
  FETCH_INVENTORY_DB_COMPLIANCE_SUMMARY,
  FETCH_INVENTORY_DB_EVALUATION_OVERTIME,
  FETCH_INVENTORY_DB_INTERNET_EXPOSED_RESOURCES,
  FETCH_INVENTORY_DB_RESOURCE_OVERTIME,
  FETCH_INVENTORY_DB_RESOURCE_SUMMARY,
  FETCH_INVENTORY_DB_TOP_NON_COMPLAINT,
  FETCH_INVENTORY_GLOBAL_FILTERS,
  LOADING_FOR_INVENTORY_GRAPHS,
} from '../constants/actionTypes';

const defaultState = {
  trigger: false,
  cloud: 'aws',
  compliance_summary: { data: {}, status: 'fetching' },
  resource_overtime: { data: { sevenDays: {}, twentyFourHours: {} }, status: 'fetching' },
  evaluation_overtime: { data: { sevenDays: {}, twentyFourHours: {} }, status: 'fetching' },
  total_non_compliant: { data: {}, status: 'fetching' },
  internet_exposed_resources: { data: {}, status: 'fetching' },
  resource_summary: { data: {}, status: 'fetching' },
  filters: { data: {}, status: 'fetching', currentQuery: {} },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_GLOBAL_FILTERS:
      if (!_.isEmpty(action.payload)) {
        console.log(`action.payload`, action.payload);
        return {
          ...state,
          trigger: action.payload.notInitial,
          filters: {
            ...state.filters,
            status: action.payload['filterData']['status'],
            data: action.payload['filterData'].data.data.filters,
            query: action.payload['query'],
          },
        };
      } else {
        return {
          ...state,
          trigger: false,
        };
      }
    case FETCH_INVENTORY_DB_COMPLIANCE_SUMMARY:
      if (action.payload) {
        return {
          ...state,
          trigger: false,
          compliance_summary: { ...action.payload.data },
        };
      }
      return {
        ...state,
        trigger: false,
        compliance_summary: { ...state.compliance_summary, status: 200 },
      };

    case LOADING_FOR_INVENTORY_GRAPHS:
      if (action.payload === 'resource_overtime') {
        return {
          ...state,
          resource_overtime: { ...defaultState.resource_overtime },
        };
      } else if (action.payload === 'evaluation_overtime') {
        return {
          ...state,
          evaluation_overtime: { ...defaultState.evaluation_overtime },
        };
      } else if (action.payload === 'total_non_compliant') {
        return {
          ...state,
          total_non_compliant: { ...defaultState.total_non_compliant },
        };
      } else {
        return state;
      }
    case FETCH_INVENTORY_DB_RESOURCE_OVERTIME:
      if (action.payload) {
        return {
          ...state,
          trigger: false,
          resource_overtime: {
            status: 200,
            data: {
              sevenDays: action.payload.sevenDays.data.data,
              twentyFourHours: action.payload.twentyFourHours.data.data,
            },
          },
        };
      }

      return {
        ...state,
        trigger: false,
        resource_overtime: {
          status: 200,
          data: {
            sevenDays: {},
            twentyFourHours: {},
          },
        },
      };

    case FETCH_INVENTORY_DB_EVALUATION_OVERTIME:
      if (action.payload) {
        return {
          ...state,
          trigger: false,
          evaluation_overtime: {
            status: 200,
            data: {
              sevenDays: action.payload.sevenDays.data.data,
              twentyFourHours: action.payload.twentyFourHours.data.data,
            },
          },
        };
      }

      return {
        ...state,
        trigger: false,
        evaluation_overtime: {
          status: 200,
          data: {
            sevenDays: {},
            twentyFourHours: {},
          },
        },
      };

    case FETCH_INVENTORY_DB_TOP_NON_COMPLAINT:
      if (action.payload) {
        return {
          ...state,
          trigger: false,
          total_non_compliant: { ...action.payload.data },
        };
      }
      return {
        ...state,
        trigger: false,
        total_non_compliant: { ...state.total_non_compliant, status: 200 },
      };

    case FETCH_INVENTORY_DB_INTERNET_EXPOSED_RESOURCES:
      if (action.payload) {
        return {
          ...state,
          trigger: false,
          internet_exposed_resources: { ...action.payload.data, status: 200 },
        };
      }
      return {
        ...state,
        trigger: false,
        internet_exposed_resources: { ...state.internet_exposed_resources, status: 200 },
      };

    case FETCH_INVENTORY_DB_RESOURCE_SUMMARY:
      if (action.payload) {
        return {
          ...state,
          trigger: false,
          resource_summary: { ...action.payload.data },
        };
      }
      return {
        ...state,
        trigger: false,
        resource_summary: { ...state.resource_summary, status: 200 },
      };

    case CLOUD_INVENTORY_GLOBAL_FILTERS_SET:
      return {
        ...state,
        cloud: action.payload,
      };
    case 'Action_name':
      return {
        trigger: false,
        cloud: 'aws',
        compliance_summary: { data: {}, status: 'fetching' },
        resource_overtime: { data: { sevenDays: {}, twentyFourHours: {} }, status: 'fetching' },
        evaluation_overtime: { data: { sevenDays: {}, twentyFourHours: {} }, status: 'fetching' },
        total_non_compliant: { data: {}, status: 'fetching' },
        internet_exposed_resources: { data: {}, status: 'fetching' },
        resource_summary: { data: {}, status: 'fetching' },
        filters: { data: {}, status: 'fetching', currentQuery: {} },
      };

    default:
      return state;
  }
};
