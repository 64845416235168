import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './styles.scss';

const NAV_CONFIG = [
  {
    url: '/environments',
    name: 'Environments',
  },
  // {
  //   url: '/reports',
  //   name: 'REPORTS',
  // },
  {
    url: '/rules',
    name: 'Rules',
  },
  {
    url: '/risk',
    name: 'Risk',
  },
  {
    url: '/identity',
    name: 'Identity',
  },
];

const PlugNav = ({ titleKey = '', showNav = true }) => {
  let { pathname } = useLocation();
  const isMenuLocked = useSelector(state => state.onboarding.isMenuLocked);

  return (
    <div className="plug-nav">
      <div className="title">{titleKey}</div>
      <div className="pn-right">
        {
          // showSelect && <CustomDropdown />
        }
        {showNav && (
          <div>
            <ul className="nav navbar-nav">
              {NAV_CONFIG.map(el => (
                <li
                  key={el.name}
                  className={el.url === pathname ? 'active' : ''}
                  style={{
                    textTransform: 'unset',
                  }}>
                  <Link to={el.url} className={isMenuLocked ? 'disabled' : ''}>
                    {el.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlugNav;
