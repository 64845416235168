import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import remove from '@assets/img/remove.png';
function SubTenatSwitchingErrorModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <img src={remove} alt="Logo" style={{ height: '1.5rem', width: '1.5rem' }} />
          &nbsp;&nbsp;Something Went Wrong
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Contact Support to enable Multi Tenancy</p>
      </Modal.Body>
      {/* <Modal.Footer> */}
      <div
        style={{
          textAlign: 'center',
          marginTop: '0.5rem',
          marginBottom: '1rem',
        }}
      >
        <Button
          style={{
            width: '9rem',
            backgroundColor: 'white',
            color: '#109CF1',
          }}
          onClick={props.onHide}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
export default SubTenatSwitchingErrorModal;
