export const ComplianceGenerator = name => {
  switch (name) {
    case 'nist-800-53-rev4.json':
      return 'NIST 800-53 Rev 4';
    case 'nist-800-53-rev-4':
      return 'NIST 800-53 Rev 4';
    case 'NIST':
      return 'NIST 800-53 Rev 4';
    case 'CCM':
      return 'CSA CCM 3.0';
    case 'csa-ccm-3.0.json':
      return 'CSA CCM 3.0';
    case 'csa-ccm-3.0':
      return 'CSA CCM 3.0';
    case 'csa-ccm-3':
      return 'CSA CCM 3.0';
    case 'cis-1.3.0.json':
      return 'AWS CIS v1.3.0';
    case 'cis-1.3.0':
      return 'AWS CIS v1.3.0';
    case 'CIS':
      return 'AWS CIS v1.3.0';
    case 'aws-well-architected-framework.json':
      return 'AWAF';
    case 'aws-well-architected-framework':
      return 'AWAF';
    case 'iso-27001-2013.json':
      return 'ISO 27001 2013';
    case 'ISO 27001 2013':
      return 'ISO 27001 2013';
    case 'iso-1.0.0.json':
      return 'ISO 27001 2013';
    case 'iso-1.0.0':
      return 'ISO 27001 2013';
    case 'ISO':
      return 'ISO 27001 2013';
    case 'AWAF':
      return 'AWAF';
    case 'awaf.json':
      return 'AWAF';
    case 'nist-800-53-rev-4.json':
      return 'NIST 800-53 Rev 4';
    case 'aws-cis-1.3.0':
      return 'AWS CIS v1.3.0';
    case 'aws-cis-1.3.0.json':
      return 'AWS CIS v1.3.0';
    default:
      return name;
  }
};

export const ReverseComplianceGenerator = name => {
  switch (name) {
    case 'CSA CCM 3.0':
      return 'CSA CCM 3.0';
    case 'AWS CIS v1.3.0':
      return 'AWS CIS v1.3.0';
    case 'AWAF':
      return 'AWAF';
    case 'ISO 27001 2013':
      return 'ISO 27001 2013';
    default:
      return name;
  }
};
