import _ from 'lodash';

const RouterConfig = {
  '/dashboard': {
    level: 0,
    text: 'Dashboard',
  },
  '/environments': {
    level: 0,
    text: 'Environments',
  },
  '/environments/connect': {
    level: 1,
    text: 'Connect',
  },
  '/scan-history': {
    level: 0,
    text: 'Scan History',
  },
  '/schedule': {
    level: 1,
    text: 'New Scan',
  },
  // '/scan-report/': {
  //   level: 1,
  //   text: 'Scan - ',
  // },
  '/scan-report/': {
    level: 2,
    text: 'Scan - ',
  },
  '/compliance': {
    level: 0,
    text: 'Compliance',
  },
  '/compliance/breakdown': {
    level: 1,
    text: 'Breakdown',
  },

  '/rules': {
    level: 0,
    text: 'Rules',
  },
  '/risk': {
    level: 0,
    text: 'Risk',
  },
  '/assets': {
    level: 0,
    text: 'Assets',
  },
  // '/remediation': {
  //   level: 1,
  //   text: 'Remediation',
  // },
  // '/remediation': {
  //   level: 2,
  //   text: 'Remediation',
  // },
  '/remediation': {
    level: 3,
    text: 'Remediation',
  },
};

const BreadCrumbUtil = (path, stack) => {
  const pathInStack = stack.filter(el => el.url === path);
  if (pathInStack.length > 0) {
    let index = stack.findIndex(el => el.url === path);
    return [...stack].slice(0, index + 1);
  }
  if (path !== '/') {
    let pick = RouterConfig[path];
    if (!pick) {
      Object.keys(RouterConfig).map(r => {
        const paramArr = path.split(r);
        const param = paramArr.length > 0 ? paramArr[1] : null;
        if (path.includes(r) && !param.includes('/')) {
          pick = {
            ...RouterConfig[r],
            text: RouterConfig[r]['text'] + param,
          };
        }
      });
    }
    if (!_.isEmpty(pick)) {
      return pick.level === 0 ? [{ ...pick, url: path }] : [...stack, { ...pick, url: path }];
    }
  }
  return [];
};

export default BreadCrumbUtil;
