import React from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from '@mui/material/Button';

import "./style.scss";

function SelectedResourcesModal({ open, resources, isResourceAny, onClose }) {
  return (
    <Dialog open={open}>
      <DialogTitle>Selected Resources</DialogTitle>

      <DialogContent style={{ overflowX: "hidden" }}>
        {!isResourceAny && !resources.length ? (
          <div
            style={{
              width: "300px",
              padding: "16px",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            No resources selected
          </div>
        ) : null}

        {isResourceAny ? (
          <div
            style={{
              width: "300px",
              padding: "16px",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            Any region currently available or may be available in the future are
            selected.
          </div>
        ) : (
          resources.map((r, i) => (
            <div
              key={r.resource_id}
              className="CreatePolicy__SelectedResourcesModalItem"
            >
              {i + 1}. {r.resource_name}
            </div>
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectedResourcesModal;
