import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';
import './style.scss';
import { Button } from 'reactstrap';
import { format, isSameDay, isBefore, isAfter, subDays } from 'date-fns';
import useTimeFilter from '@root/hooks/SelectTimeFilter';
// import _ from 'lodash';

const TimeRangeSelector = ({ setOpenCustomSelector, setOpen, setValue }) => {
  const { timeRange } = useTimeFilter();
  const [startDate, setStartDate] = useState(new Date(timeRange.from.replace(/-/g, '/')));
  const [endDate, setEndDate] = useState(new Date(timeRange.to.replace(/-/g, '/')));
  const [startError, setStartError] = useState('');
  const [toError, setToError] = useState('');
  const { updateTimeRange } = useTimeFilter();
  console.log(timeRange.from, 'timetange');
  const today = new Date();
  const ninetyDaysAgo = subDays(today, 90);
  const validateStartDate = newStartDate => {
    setStartError('');
    if (isAfter(newStartDate, new Date())) {
      setStartError('Start date cannot be in the future');
    } else if (isBefore(endDate, newStartDate)) {
      setToError('End date cannot be before start date');
    } else {
      setToError('');
    }
  };

  const validateEndDate = newEndDate => {
    setToError('');
    if (isAfter(newEndDate, new Date())) {
      setToError('End date cannot be in the future');
    } else if (isBefore(newEndDate, startDate)) {
      setToError('End date cannot be before start date');
    }
  };
  console.log(startDate, 'startDate');
  const handleStartDateChange = newStartDate => {
    setStartDate(newStartDate);
    validateStartDate(newStartDate);
  };

  const handleEndDateChange = newEndDate => {
    setEndDate(newEndDate);
    validateEndDate(newEndDate);
  };

  const handleTimeRange = () => {
    if (!startError && !toError) {
      updateTimeRange({ from: format(startDate, 'yyyy/MM/dd HH:mm:ss'), to: format(endDate, 'yyyy/MM/dd HH:mm:ss') });

      if (isSameDay(startDate, endDate)) {
        setValue(format(startDate, 'h:mm a') + ' - ' + format(endDate, 'h:mm a'));
      } else {
        setValue(format(startDate, 'MMM d, h:mm a') + ' - ' + format(endDate, 'MMM d, h:mm a'));
      }

      setOpenCustomSelector(false);
      setOpen(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="time_range">
        <div style={{ height: '203px' }}>
          <p className="input_heading m-0" style={{ paddingTop: '15px' }}>
            Start time
          </p>
          <DateTimePicker
            disableFuture
            minDate={ninetyDaysAgo}
            maxDate={today}
            InputProps={{
              style: {
                color: '#484d56',
                marginTop: '0px',
                fontFamily: 'Manrope',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 1,
                height: '40px',
              },
            }}
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                sx={{ marginTop: '8px' }}
                error={Boolean(startError)}
                // helperText={startError}
              />
            )}
          />
          <p className="error-msg m-0" style={{ display: startError ? 'block' : 'none' }}>
            {startError}
          </p>
          <p className="input_heading m-0" style={{ paddingTop: '10px' }}>
            End time
          </p>
          <DateTimePicker
            disableFuture
            minDate={ninetyDaysAgo}
            maxDate={today}
            onError={setToError}
            InputProps={{
              style: {
                color: '#484d56',
                fontFamily: 'Manrope',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 1,
                height: '40px',
                marginTop: '0px',
              },
            }}
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                sx={{ marginTop: '8px' }}
                error={Boolean(toError)}
                //   helperText={toError}
              />
            )}
          />
          <p className="error-msg m-0" style={{ display: toError ? 'block' : 'none' }}>
            {toError}
          </p>
        </div>

        <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'end', gap: '10px' }}>
          <Button
            color="primary"
            className="fs--1"
            onClick={() => setOpenCustomSelector(false)}
            style={{
              fontSize: '14px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              color: '#484D56',
              background: '#F7FAFC',
              border: '1px solid #CBD5E0',
              lineHeight: 1,
            }}>
            Cancel
          </Button>
          <Button
            color="primary"
            className="fs--1"
            onClick={handleTimeRange}
            disabled={Boolean(startError) || Boolean(toError)}
            style={{
              fontSize: '14px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              color: '#FFFFFF',
              background: '#194BFB',
              border: '1px solid #CBD5E0',
              lineHeight: 1,
            }}>
            Apply
          </Button>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default TimeRangeSelector;
