import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PlugNav from '../../containers/_PlugNav';
import MainLayout from '../../layouts/MainLayout';

import RouteLoader from '@components/v2/global/RouteLoader';
import agent from '@root/agent';
import { makeEnvironmentTagsKey } from '@root/query/environment-tags';
import { useQuery } from '@tanstack/react-query';
import { ChangePassword, ConnectCloudAccount, NewScan, Onboarding, ScanHistory } from '../loadable';

export default () => {
  const { data: envData, isFetching: isFetchingEnvData } = useQuery(
    makeEnvironmentTagsKey(),
    agent.Environment.fetch_environments_and_cloud_providers,
  );

  return (
    <MainLayout topNav={<PlugNav />}>
      <Suspense fallback={<RouteLoader />}>
        <Switch>
          <Route path="/onboarding" exact component={Onboarding} />
          <Route path="/scan-history" exact component={ScanHistory} />
          <Route path="/profile/change-password" exact component={ChangePassword} />
          <Route path="/environments/connect" exact component={ConnectCloudAccount} />
          <Route path="/scan-history/scanner" exact component={NewScan} />
          {!isFetchingEnvData &&
            (envData?.data.length === 0 ? (
              <Route>
                <Redirect to={'/onboarding'} />
              </Route>
            ) : (
              <Route>
                <Redirect to={'/scan-history'} />
              </Route>
            ))}
        </Switch>
      </Suspense>
    </MainLayout>
  );
};
