// reducers/editEnvironmentReducer.js
import { UPDATE_EDIT_ENVIRONMENT } from '@constants/actionTypes';

const initialState = {
  isEditEnvironment: false,
};

const editEnvironmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EDIT_ENVIRONMENT:
      return {
        ...state,
        isEditEnvironment: action.payload,
      };
    default:
      return state;
  }
};

export default editEnvironmentReducer;
