import {
  COMPLIANCE_BY_CONTROL,
  COMPLIANCE_BY_CONTROL_EXPAND,
  COMPLIANCE_BY_CONTROL_RULE_EXPAND,
  COMPLIANCE_BY_RESOURCE,
  COMPLIANCE_BY_RESOURCE_EXPAND,
  COMPLIANCE_BY_RESOURCE_TYPE,
  COMPLIANCE_BY_RESOURCE_TYPE_EXPAND,
  COMPLIANCE_DOWNLOAD,
  CONTROL_SUMMARIES,
  FETCH_EVENTS,
  FETCH_EVENT_ATTRIBUTES,
  FETCH_EVENT_FILTER,
  FLUSH_SCAN_REPORT,
  RESOURCE_SUMMARIES,
  SCAN_SUMMARIES,
} from '@constants/actionTypes';

const defaultState = {
  status: 'fetching',
  isFetchingScanReport: false,
  scanInformation: {},
  complianceByResource: [],
  complianceByResourceType: [],
  complianceByControl: [],
  complianceByResourceFilter: [],
  complianceByResourceTypeFilter: [],
  complianceByControlFilter: [],
  environment_tags: [],
  resourceSummary: {},
  controlSummary: {},
  complianceByResourceExpansion: {},
  compliance_by_resource_type_expansion: {},
  compliance_by_control_expansion: {},
  compliance_by_control_rule_expansion: {},
  totalControlCount: 0,
  totalComplianceCount: null,
  downloadData: [],
  events: {
    events: [],
    events_count: 1,
    status: 'loading',
  },
  events_filter: [],
  event_attributes: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SCAN_SUMMARIES: {
      return {
        ...state,
        scanInformation: { ...action.payload.data.data, scan_id: action.payload.scan_id },
      };
    }

    case RESOURCE_SUMMARIES: {
      return {
        ...state,
        resourceSummary: { ...action.payload.data.data },
      };
    }

    case CONTROL_SUMMARIES: {
      return {
        ...state,
        controlSummary: { ...action.payload.data.data.control_overview },
        totalControlCount: Object.keys(action.payload.data.data.control_overview).length,
      };
    }

    case COMPLIANCE_BY_RESOURCE: {
      return {
        ...state,
        status: '200',
        totalComplianceCount: action.payload.data.data.compliance_by_resource.length,
        complianceByResource: action.payload.data.data.compliance_by_resource.map(item => ({
          ...item,
          id: item.sr_no,
        })),
      };
    }

    case COMPLIANCE_DOWNLOAD: {
      return {
        ...state,
        downloadData: action.payload.data,
      };
    }

    case COMPLIANCE_BY_RESOURCE_EXPAND:
      return {
        ...state,
        status: '200',
        complianceByResourceExpansion: {
          ...state.complianceByResourceExpansion,
          [`${action.key}`]:
            action.payload.data.data.compliance_by_resource_expansion === 'No Applicable Rules for the resource'
              ? 'No Applicable Rules for the resource'
              : action.payload.data.data.compliance_by_resource_expansion.map(item => ({
                  ...item,
                  id: item.sr_no,
                })),
        },
      };

    case COMPLIANCE_BY_RESOURCE_TYPE: {
      return {
        ...state,
        status: 200,
        totalComplianceCount: action.payload.data.data.compliance_by_resource_type.length,
        complianceByResourceType: action.payload.data.data.compliance_by_resource_type.map(item => ({
          ...item,
          id: item.resource_type,
        })),
      };
    }

    case COMPLIANCE_BY_RESOURCE_TYPE_EXPAND:
      return {
        ...state,
        status: '200',
        compliance_by_resource_type_expansion: {
          ...state.compliance_by_resource_type_expansion,
          totalNum: action.payload.data.data.num_count,
          [`${action.key}`]: action.payload.data.data.compliance_by_resource_type_expansion.map((item, index) => ({
            ...item,
            id: index + 1,
          })),
        },
      };

    case COMPLIANCE_BY_CONTROL: {
      return {
        ...state,
        status: 200,
        isFetchingScanReport: false,
        totalComplianceCount: action.payload.data.data.compliance_by_control.length,
        complianceByControl: action.payload.data.data.compliance_by_control.map((item, index) => ({
          ...item,
          id: index,
        })),
      };
    }

    case COMPLIANCE_BY_CONTROL_EXPAND:
      return {
        ...state,
        status: '200',
        compliance_by_control_expansion: {
          ...state.compliance_by_control_expansion,
          totalNum: action.payload.data.data.num_count,
          [`${action.key}`]: action.payload.data.data.compliance_by_control_expansion.map((item, index) => ({
            ...item,
            id: index + 1,
          })),
        },
      };

    case COMPLIANCE_BY_CONTROL_RULE_EXPAND:
      return {
        ...state,
        status: 200,
        compliance_by_control_rule_expansion: {
          ...state.compliance_by_control_rule_expansion,
          totalNum: action.payload.data.data.num_count,
          [`${action.key}`]: action.payload.data.data.compliance_by_control_rule_expansion.map(item => ({
            ...item,
            id: item.resource_id,
          })),
        },
      };
    // case SET_IS_FETCHING_SCAN_REPORT: {
    // 	return { ...state, status: action.payload.status };
    // }
    // case FETCH_SCAN_REPORT:
    // 	if (action.payload) {
    // 		let counterStart = action.payload.data.data.tabs_data.compliant_non_compliant.length;
    // 		let nonEvaluated = [];
    // 		if (!_.isEmpty(action.payload.data.data.tabs_data.non_evaluated_resources))
    // 			nonEvaluated = action.payload.data.data.tabs_data.non_evaluated_resources.map((item) => {
    // 				counterStart = counterStart + 1;
    // 				let element = { ...item, sr_no: counterStart };
    // 				return element;
    // 			});
    // 		let oldTable = action.payload.data.data.tabs_data.compliant_non_compliant;
    // 		action.payload.data.data.tabs_data.compliant_non_compliant = [...oldTable, ...nonEvaluated];
    // 		const scanInfo = SetScanInfo(action.payload.data.data);
    // 		const { resource, resourceType, resourceControl } = SetTableData(action.payload.data.data);
    // 		return {
    // 			...state,
    // 			scanInformation: { ...scanInfo, scan_id: action.payload.scan_id },
    // 			complianceByResource: resource.table.map((item) => ({ ...item, id: item.sr_no })),
    // 			complianceByResourceType: resourceType.table,
    // 			complianceByControl: resourceControl.table,
    // 			status: action.payload.status,
    // 			environment_tags: action.payload.data.data.environment_tags
    // 		};
    // 	} else {
    // 		return {
    // 			...state,
    // 			status: 200,
    // 		};
    // 	}
    // case "SET_FILTERED_RESOURCE_DATA_REPORT":
    // 	if (action.payload) {
    // 		return {
    // 			...state,
    // 			complianceByResourceFilter: action.payload.data,
    // 			filteredby: action.payload.key
    // 		}
    // 	}

    // case "FETCH_FILTER_CONTROL_DATA":
    // 	if (action.payload) {
    // 		return {
    // 			...state,
    // 			complianceByControl: action.payload.data

    // 		}
    // 	}

    // case "SET_FILTERED_RESOURCE_TYPE_DATA_REPORT":
    // 	if (action.payload) {
    // 		return {
    // 			...state,
    // 			complianceByResourceTypeFilter: action.payload.data

    // 		}
    // 	}

    // case "SET_FILTERED_CONTROL_DATA_REPORT":
    // 	if (action.payload) {
    // 		return {
    // 			...state,
    // 			complianceByControlFilter: action.payload.data

    // 		}
    // 	}

    case FETCH_EVENTS:
      return {
        ...state,
        status: 200,
        events: {
          events: action.payload['data']['results'] || [],
          events_count: action.payload['data']['count'],
          status: 200,
          errorMessage: action.payload['data']['message'],
        },
      };

    case FETCH_EVENT_FILTER:
      return {
        ...state,
        status: 200,
        events_filter: action.payload.data.data.events_filter,
      };

    case FETCH_EVENT_ATTRIBUTES:
      return {
        ...state,
        status: 200,
        event_attributes: action.payload.data.event_attributes,
      };

    case FLUSH_SCAN_REPORT:
      return { ...defaultState };
    default:
      return state;
  }
};
