export const APP_LOAD = 'APP_LOAD';
export const FETCH_RISK_DATE_COMPLETE = 'FETCH_RISK_DATE_COMPLETE';
export const FETCH_RULES_DATE_COMPLETE = 'FETCH_RULES_DATE_COMPLETE';
export const SET_LOGIN = 'SET_LOGIN';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_USER = 'SET_USER';

export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const REDIRECT = 'REDIRECT';
export const PH_SP = 'PH_SP';
export const PHISHINGDASHBOARD_LOAD = 'PHISHINGDASHBOARD_LOAD';
export const PHISHINGDASHBOARD_UNLOAD = 'PHISHINGDASHBOARD_UNLOAD';
export const NEWASSEMENT_LOAD = 'NEWASSEMENT_LOAD';
export const NEWASSEMENT_UNLOAD = 'NEWASSEMENT_UNLOAD';
export const ASSEMENT_LOAD = 'ASSEMENT_LOAD';
export const ASSEMENT_UNLOAD = 'ASSEMENT_UNLOAD';
export const PHISHEDUSER_LOAD = 'PHISHEDUSER_LOAD';
export const PHISHEDUSER_UNLOAD = 'PHISHEDUSER_UNLOAD';

// edit enviromemt
export const UPDATE_EDIT_ENVIRONMENT = 'UPDATE_EDIT_ENVIRONMENT';
// Rules

export const SEND_RESOURCES = 'SEND_RESOURCES';

// Environments actions
export const SET_SELECTED_ENVIRONMENT_TAGS = 'SET_SELECTED_ENVIRONMENT_TAGS';
export const SET_SELECTED_ENVIRONMENTS = 'SET_SELECTED_ENVIRONMENTS';
// time filter
export const SET_TIME_FILTER = 'SET_TIME_FILTER';
export const SET_TIME_RANGE = 'SET_TIME_RANGE';

// Dashboard Actions
export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
export const FETCH_USER_POLICY_DATA = 'FETCH_USER_POLICY_DATA';
export const FETCH_USER_POLICY_TABLE = 'FETCH_USER_POLICY_TABLE';

//COmpliance
export const FETCH_COMPLIANCE_DASHBOARD_DATA = 'FETCH_COMPLIANCE_DASHBOARD_DATA';
export const UPDATE_COMPLIANCE_DASHBOARD_FILTER = 'UPDATE_COMPLIANCE_DASHBOARD_FILTER';
export const FETCH_INDIVIDUAL_COMPLIANCE_DATA = 'FETCH_INDIVIDUAL_COMPLIANCE_DATA';
export const FETCH_INDIVIDUAL_COMPLIANCE_EXPANSION = 'FETCH_INDIVIDUAL_COMPLIANCE_EXPANSION';
export const REMOVE_INDIVIDUAL_COMPLIANCE_EXPANSION = 'REMOVE_INDIVIDUAL_COMPLIANCE_EXPANSION';
export const UPDATE_INDIVIDUAL_COMPLIANCE_FILTER = 'UPDATE_INDIVIDUAL_COMPLIANCE_FILTER';

// Scan Report
export const FETCH_SCAN_REPORT = 'FETCH_SCAN_REPORT';
export const FLUSH_SCAN_REPORT = 'FLUSH_SCAN_REPORT';
export const SET_IS_FETCHING_SCAN_REPORT = 'SET_IS_FETCHING_SCAN_REPORT';
export const UPDATE_TABULAR_NAVIGATION = 'UPDATE_TABULAR_NAVIGATION';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const UPDATE_RESOURCE_TYPE = 'UPDATE_RESOURCE_TYPE';
export const UPDATE_CONTROLS = 'UPDATE_CONTROLS';
export const SCAN_FILTER_KEY_CHANGE = 'SCAN_FILTER_KEY_CHANGE';
export const SCAN_APPLY_FILTER = 'SCAN_APPLY_FILTER';
export const SCAN_REMOVE_FILTER = 'SCAN_REMOVE_FILTER';
export const SCAN_RESET_FILTER = 'SCAN_RESET_FILTER';
export const SCAN_REPORT_SCROLL_POS = 'SCAN_REPORT_SCROLL_POS';

//scan report table2

export const COMPLIANCE_BY_RESOURCE = 'COMPLIANCE_BY_RESOURCE';
export const COMPLIANCE_BY_RESOURCE_FILTER = 'COMPLIANCE_BY_RESOURCE_FILTER';
export const COMPLIANCE_DOWNLOAD = 'COMPLIANCE_DOWNLOAD';
export const COMPLIANCE_BY_RESOURCE_EXPAND = 'COMPLIANCE_BY_RESOURCE_EXPAND';
export const SCAN_SUMMARIES = 'SCAN_SUMMARIES';
export const RESOURCE_SUMMARIES = 'RESOURCE_SUMMARIES';
export const CONTROL_SUMMARIES = 'CONTROL_SUMMARIES';
export const COMPLIANCE_BY_RESOURCE_TYPE = 'COMPLIANCE_BY_RESOURCE_TYPE';
export const COMPLIANCE_BY_RESOURCE_TYPE_EXPAND = 'COMPLIANCE_BY_RESOURCE_TYPE_EXPAND';
export const COMPLIANCE_BY_CONTROL = 'COMPLIANCE_BY_CONTROL';
export const COMPLIANCE_BY_CONTROL_EXPAND = 'COMPLIANCE_BY_CONTROL_EXPAND';
export const COMPLIANCE_BY_CONTROL_RULE_EXPAND = 'COMPLIANCE_BY_CONTROL_RULE_EXPAND';
// export const

// Scan history
export const FETCH_SCAN_HISTORY = 'FETCH_SCAN_HISTORY';
export const CHANGE_SCAN_HISTORY_PAGE = 'CHANGE_SCAN_HISTORY_PAGE';
export const SCAN_HISTORY_FILTER_KEY_CHANGE = 'SCAN_HISTORY_FILTER_KEY_CHANGE';
export const SCAN_HISTORY_APPLY_FILTER = 'SCAN_HISTORY_APPLY_FILTER';
export const SCAN_HISTORY_REMOVE_FILTER = 'SCAN_HISTORY_REMOVE_FILTER';
export const SCAN_HISTORY_RESET_FILTER = 'SCAN_HISTORY_RESET_FILTER';

// Remediation
export const GENERATE_REMEDIATION_PAGE_FOR_SCAN = 'GENERATE_REMEDIATION_PAGE_FOR_SCAN';
export const UPDATE_REMEDIATION_EXPAND_DETAILS = 'UPDATE_REMEDIATION_EXPAND_DETAILS';
export const REMEDIATION_SCAN_DETAILS_UPDATE = 'REMEDIATION_SCAN_DETAILS_UPDATE';
export const DISENGAGE_REMEDIATION_SOCKET_ACC = 'DISENGAGE_REMEDIATION_SOCKET_ACC';

export const REMEDIATION_SOCKET_OPEN = 'REMEDIATION_SOCKET_OPEN';
export const REMEDIATION_SOCKET_CLOSE = 'REMEDIATION_SOCKET_CLOSE';
export const REMEDIATION_SOCKET_SEND = 'REMEDIATION_SOCKET_SEND';
export const REMEDIATION_SOCKET_RECEIVE = 'REMEDIATION_SOCKET_RECEIVE';

// export const REMEDIATION_STORE_RESOURCE_INFO = "REMEDIATION_STORE_RESOURCE_INFO";
// export const REMEDIATION_ADD_AWS_CONFIG_RESOURCE = "REMEDIATION_ADD_AWS_CONFIG_RESOURCE";
// export const REMEDIATION_STORE_RULES = "REMEDIATION_STORE_RULES";
// export const REMEDIATION_ADD_INSTRUCTIONS_RULES = "REMEDIATION_ADD_INSTRUCTIONS_RULES";
// export const REMEDIATION_STORE_REMEDIATION_SCAN = "REMEDIATION_STORE_REMEDIATION_SCAN";
// export const REMEDIATION_ADD_LAST_DATA = "REMEDIATION_ADD_LAST_DATA";
// export const REMEDIATION_REMOVE_LAST_DATA = "REMEDIATION_REMOVE_LAST_DATA";
// export const REMEDIATION_UPDATE_LAST_DATA = "REMEDIATION_UPDATE_LAST_DATA";

// Risk
export const FETCH_RISK_DATA = 'FETCH_RISK_DATA';
export const FETCH_RISK_EXCEPTION_DATA = 'FETCH_RISK_EXCEPTION_DATA';
export const FETCH_GLOBAL_RISK_FILTERS = 'FETCH_GLOBAL_RISK_FILTERS';
export const UPDATE_GLOBAL_RISK_FILTERS = 'UPDATE_GLOBAL_RISK_FILTERS';
export const FETCH_RISK_EXPANSION_DATA = 'FETCH_RISK_EXPANSION_DATA';
export const FETCH_EXCEPTION_RISK_EXPANSION_DATA = 'FETCH_EXCEPTION_RISK_EXPANSION_DATA';
export const REMOVE_RISK_EXPANSION_DATA = 'REMOVE_RISK_EXPANSION_DATA';
export const FETCH_RISK_EXPORT_DATA = 'FETCH_RISK_EXPORT_DATA';
export const TABLE_SORT_TRIGGERED = 'TABLE_SORT_TRIGGERED';
export const UPDATE_DATA = 'UPDATE_DATA';
export const SET_SELECTED_RESOURCES = 'SET_SELECTED_RESOURCES';

//Rules
export const FETCH_RULES_DATA = 'FETCH_RULES_DATA';
export const FLUSH_RULES_DATA = 'FLUSH_RULES_DATA';
export const RULES_FILTER_CHANGE = 'RULES_FILTER_CHANGE';

// Inventory - Dashboard
export const FETCH_INVENTORY_DB_COMPLIANCE_SUMMARY = 'FETCH_INVENTORY_DB_COMPLIANCE_SUMMARY';
// Graphs
export const LOADING_FOR_INVENTORY_GRAPHS = 'LOADING_FOR_INVENTORY_GRAPHS';
export const FETCH_INVENTORY_DB_RESOURCE_OVERTIME = 'FETCH_INVENTORY_DB_RESOURCE_OVERTIME';
export const FETCH_INVENTORY_DB_EVALUATION_OVERTIME = 'FETCH_INVENTORY_DB_EVALUATION_OVERTIME';
export const FETCH_INVENTORY_DB_TOP_NON_COMPLAINT = 'FETCH_INVENTORY_DB_TOP_NON_COMPLAINT';
// Cards
export const FETCH_INVENTORY_DB_INTERNET_EXPOSED_RESOURCES = 'FETCH_INVENTORY_DB_INTERNET_EXPOSED_RESOURCES';
export const FETCH_INVENTORY_DB_RESOURCE_SUMMARY = 'FETCH_INVENTORY_DB_RESOURCE_SUMMARY';
// Filters
export const CLOUD_INVENTORY_GLOBAL_FILTERS_SET = 'CLOUD_INVENTORY_GLOBAL_FILTERS_SET';
export const FETCH_INVENTORY_GLOBAL_FILTERS = 'FETCH_INVENTORY_GLOBAL_FILTERS';
export const UPDATE_INVENTORY_GLOBAL_FILTERS = 'UPDATE_INVENTORY_GLOBAL_FILTERS';

// One Click Remediation
export const ONE_CLICK_CLOUD_GLOBAL_FILTERS_SET = 'ONE_CLICK_CLOUD_GLOBAL_FILTERS_SET';
export const ONE_CLICK_SETUP_FORM_SET = 'ONE_CLICK_SETUP_FORM_SET';
export const ONE_CLICK_INPUT_FORM_SET = 'ONE_CLICK_INPUT_FORM_SET';
export const ONE_CLICK_SETTINGS_FORM_SET = 'ONE_CLICK_SETTINGS_FORM_SET';

// Resource Evaluation
export const FETCH_RESOURCES_EVALUATION = 'FETCH_RESOURCES_EVALUATION';
export const RESET_RESOURCES_EVALUATION = 'RESET_RESOURCES_EVALUATION';
export const FETCH_RESOURCE_DETAIL = 'FETCH_RESOURCE_DETAIL';
export const CLEAR_RESOURCE_DETAIL = 'CLEAR_RESOURCE_DETAIL';
export const LOAD_RESOURCE_EVALUATION_CONFIGURATION_DATA = 'LOAD_RESOURCE_EVALUATION_CONFIGURATION_DATA';

// Error Actions
export const ERROR_API_CALL = 'ERROR_API_CALL';
export const ERROR_API_RES = 'ERROR_API_RES';

// Socket Actions
export const SOCKET_CONNECT_REQUEST = 'SOCKET_CONNECT_REQUEST';
export const SOCKET_DISCONNECT_REQUEST = 'SOCKET_DISCONNECT_REQUEST';
export const SOCKET_EMIT_EVENT = 'SOCKET_EMIT_EVENT';

// Banner notifications
export const NOTIFICATION_BANNER_ELEMENT_UPDATE = 'NOTIFICATION_BANNER_ELEMENT_UPDATE';
export const NOTIFICATION_BANNER_ELEMENT_CREATE = 'NOTIFICATION_BANNER_ELEMENT_CREATE';
export const NOTIFICATION_BANNER_ELEMENT_REMOVE = 'NOTIFICATION_BANNER_ELEMENT_REMOVE';
export const NOTIFICATION_BELL_DATA = 'NOTIFICATION_BELL_DATA';
export const NOTIFICATION_BELL_DATA_UPDATE = 'NOTIFICATION_BELL_DATA_UPDATE';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';
// export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
// export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
// export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
// export const NOTIFICATION_DISMISS = "NOTIFICATION_DISMISS";
// // export const NOTIFICATION_HIDE = "NOTIFICATION_HIDE";
// // export const NOTIFICATION_SHOW = "NOTIFICATION_SHOW";

// export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
// export const NOTIFICATION_REMOVE_BY_ID = "NOTIFICATION_REMOVE_BY_ID";
// export const NOTIFICATION_REMOVE = "NOTIFICATION_REMOVE";
// export const NOTIFICATION_REMOVE_BY_DATA = "NOTIFICATION_REMOVE_BY_DATA";

//Notifications
export const FETCH_MASTER_EVENTS_DATA = 'FETCH_MASTER_EVENTS_DATA';
export const FETCH_CHILD_EVENTS_DATA = 'FETCH_CHILD_EVENTS_DATA';
export const FETCH_NON_COMPLIANT_CHILD_EVENTS_DATA = 'FETCH_NON_COMPLIANT_CHILD_EVENTS_DATA';
export const FETCH_GROUP_WITH_USERS_DATA = 'FETCH_GROUP_WITH_USERS_DATA';
export const CLEAR_GROUP_WITH_USER_DATA = 'CLEAR_GROUP_WITH_USER_DATA';
export const CLEAR_CHILD_EVENTS_DATA = 'CLEAR_CHILD_EVENTS_DATA';
export const FETCH_NOTIFICATION_DATA = 'FETCH_NOTIFICATION_DATA';
export const FILTER_SUGGESTIONS_NOTIFICATION = 'FILTER_SUGGESTIONS_NOTIFICATION';
export const FILTER_SUGGESTIONS_LOGS = 'FILTER_SUGGESTIONS_LOGS';
export const FETCH_LOGS_NOTIFICATION_DATA = 'FETCH_LOGS_NOTIFICATION_DATA';
export const CLEAR_NOTIFICATION_DATA = 'FETCH_NOTIFICATION_DATA';
export const CLEAR_LOGS_NOTIFICATION_DATA = 'CLEAR_LOGS_NOTIFICATION_DATA';

// History Page
export const MANUAL_SCAN_HISTORY_FETCH = 'MANUAL_SCAN_HISTORY_FETCH';
export const MANUAL_SCAN_SOCKET_UPDATE = 'MANUAL_SCAN_SOCKET_UPDATE';
export const STOP_MANUAL_SCAN_UPDATE = 'STOP_MANUAL_SCAN_UPDATE';
export const AUTONOMOUS_SCAN_HISTORY_FETCH = 'AUTONOMOUS_SCAN_HISTORY_FETCH';
export const REAL_TIME_SCAN_FETCH = 'REAL_TIME_SCAN_FETCH';

// Scanner page
export const CLOUD_ACCOUNTS_FETCH = 'CLOUD_ACCOUNTS_FETCH';
export const SET_FULL_SCAN_REQUEST_BODY = 'SET_FULL_SCAN_REQUEST_BODY';
export const CLEAR_FULL_SCAN_REQUEST_BODY = 'CLEAR_FULL_SCAN_REQUEST_BODY';
export const SET_LIVE_SCAN_REQUEST_BODY = 'SET_LIVE_SCAN_REQUEST_BODY';
export const SET_LIVE_SCAN_LATEST_CONFIG = 'SET_LIVE_SCAN_LATEST_CONFIG';
export const CLEAR_LIVE_SCAN_LATEST_CONFIG = 'CLEAR_LIVE_SCAN_LATEST_CONFIG';
export const CLEAR_LIVE_SCAN_REQUEST_BODY = 'CLEAR_LIVE_SCAN_REQUEST_BODY';
export const CLEAR_LIVE_SCAN_RESPONSE = 'CLEAR_LIVE_SCAN_RESPONSE';
export const FULL_SCAN_RESPONSE_SAVE = 'FULL_SCAN_RESPONSE_SAVE';
export const LIVE_SCAN_RESPONSE_SAVE = 'LIVE_SCAN_RESPONSE_SAVE';
export const LIVE_SCAN_RULE_FETCH_START = 'LIVE_SCAN_RULE_FETCH_START';
export const LIVE_SCAN_RULE_FETCH_END = 'LIVE_SCAN_RULE_FETCH_END';
export const LIVE_SCAN_EXPANSION_UPDATE = 'LIVE_SCAN_EXPANSION_UPDATE';
export const LIVE_SCAN_SCANNED_ACCOUNT_UPDATE = 'LIVE_SCAN_SCANNED_ACCOUNT_UPDATE';
export const LIVE_SCAN_LAST_PAGE_UPDATE = 'LIVE_SCAN_LAST_PAGE_UPDATE';
export const LIVE_SCAN_FILTER_APPLY = 'LIVE_SCAN_FILTER_APPLY';
export const LIVE_SCAN_FILTER_REMOVE = 'LIVE_SCAN_FILTER_REMOVE';
export const LIVE_SCAN_FILTER_KEY_CHANGE = 'LIVE_SCAN_FILTER_KEY_CHANGE';
export const LIVE_SCAN_FILTER_RESET = 'LIVE_SCAN_FILTER_RESET';

// Live Scan
export const ALL_RULES_FETCH = 'ALL_RULES_FETCH';
export const ALL_SERVICES_FETCH = 'ALL_SERVICES_FETCH';
export const RULES_FOR_SERVICE_FETCH = 'RULES_FOR_SERVICE_FETCH';
export const ERROR_LIVE_SCAN_API = 'ERROR_LIVE_SCAN_API';
export const LIVE_SCAN_SOCKET_OPEN = 'LIVE_SCAN_SOCKET_OPEN';
export const LIVE_SCAN_SOCKET_SEND = 'LIVE_SCAN_SOCKET_SEND';
export const LIVE_SCAN_SOCKET_RECEIVE = 'LIVE_SCAN_SOCKET_RECEIVE';
export const DISENGAGE_LIVE_SCAN_SOCKET_ACC = 'DISENGAGE_LIVE_SCAN_SOCKET_ACC';
export const LIVE_SCAN_SOCKET_CLOSE = 'LIVE_SCAN_SOCKET_CLOSE';
export const LIVE_SCAN_REMEDIATION_UPDATE = 'LIVE_SCAN_REMEDIATION_UPDATE';

// Onboarding
export const LOCK_MENU_ITEMS = 'LOCK_MENU_ITEMS';
export const UNLOCK_MENU_ITEMS = 'UNLOCK_MENU_ITEMS';

export const CHANGE_SIDEBAR_WIDTH = 'CHANGE_SIDEBAR_WIDTH';

// RBAC
export const FETCH_RBAC_USERS_DATA = 'FETCH_RBAC_USERS_DATA';
export const CLEAR_RBAC_USERS_DATA = 'CLEAR_RBAC_USERS_DATA';
export const SET_USERS_MODAL_DATA = 'SET_USERS_MODAL_DATA';
export const CLEAR_USERS_MODAL_DATA = 'CLEAR_USERS_MODAL_DATA';
export const FETCH_RBAC_GROUPS_DATA = 'FETCH_RBAC_GROUPS_DATA';
export const CLEAR_RBAC_GROUPS_DATA = 'CLEAR_RBAC_GROUPS_DATA';
export const SET_GROUPS_MODAL_DATA = 'SET_GROUPS_MODAL_DATA';
export const CLEAR_GROUPS_MODAL_DATA = 'CLEAR_GROUPS_MODAL_DATA';
export const FETCH_RBAC_AUDIT_DATA = 'FETCH_RBAC_AUDIT_DATA';
export const FETCH_RBAC_CLOUD_ACCOUNT = 'FETCH_RBAC_CLOUD_ACCOUNT';

// IN-PROGRESS STORE ACTIONS
export const ADD_ONGOING_REMEDIATION = 'ADD_ONGOING_REMEDIATION';
export const REMOVE_ONGOING_REMEDIATIONS = 'REMOVE_ONGOING_REMEDIATIONS';

// TENANT ACTIONS
export const SET_TENANTS = 'SET_TENANTS';

//EVENTS ACTIONS
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENT_FILTER = 'FETCH_EVENT_FILTER';
export const FETCH_EVENT_ATTRIBUTES = 'FETCH_EVENT_ATTRIBUTES';

// NEW INVENTORY 2

export const FETCH_RESOURCE_COMPLIANCE = 'FETCH_RESOURCE_COMPLIANCE';

export const FETCH_RESOURCE_RISK = 'FETCH_RESOURCE_RISK';

export const FETCH_RESOURCE_OVERTIME = 'FETCH_RESOURCE_OVERTIME';
export const FETCH_RESOURCE_NON_COMPLIANT = 'FETCH_RESOURCE_NON_COMPLIANT';
export const FETCH_ALERT_SEVERITY = 'FETCH_ALERT_SEVERITY';

export const FETCH_RESOURCE_TABLE = 'FETCH_RESOURCE_TABLE';
export const FETCH_RESOURCE_LIST = 'FETCH_RESOURCE_LIST';
export const CLEAR_RESOURCE_LIST = 'CLEAR_RESOURCE_LIST';
export const FETCH_SERVICE_CATEGORY_LIST = 'FETCH_SERVICE_CATEGORY_LIST';
export const FETCH_SERVICE_LIST = 'FETCH_SERVICE_LIST';
export const FETCH_SERVICE_LIST_TYPES = 'FETCH_SERVICE_LIST_TYPES';
export const FETCH_KEY_INSIGHT_TRIGGERS = 'FETCH_KEY_INSIGHT_TRIGGERS';
export const FETCH_INVENTORY_RESOURCE_RISK = 'FETCH_INVENTORY_RESOURCE_RISK';
export const FETCH_INVENTORY_EVALUATION = 'FETCH_INVENTORY_EVALUATION';
export const FETCH_KEY_INSIGHT_RUNTIMES = 'FETCH_KEY_INSIGHT_RUNTIMES';
export const FETCH_KEY_INSIGHT_RESOURCES = 'FETCH_KEY_INSIGHT_RESOURCES';
export const FETCH_EXPOSED_SECRETS = 'FETCH_EXPOSED_SECRETS';
export const UPDATE_KEY_INSIGHTS_PAGE = 'UPDATE_KEY_INSIGHTS_PAGE';
// aws-s3
export const FETCH_KEY_INSIGHT_DEFAULT_ENCRYPTION = 'FETCH_KEY_INSIGHT_DEFAULT_ENCRYPTION';
export const FETCH_KEY_INSIGHT_BUCKET_WITHOUT_RESOURCE = ' FETCH_KEY_INSIGHT_BUCKET_WITHOUT_RESOURCE';
export const FETCH_KEY_INSIGHT_UNENCRYPT_BUCKETS = ' FETCH_KEY_INSIGHT_UNENCRYPT_BUCKETS';

export const FETCH_ALL_RESOURCES_PAGE = 'FETCH_ALL_RESOURCES_PAGE';
export const UPDATE_ALL_RESOURCES_PAGE = 'UPDATE_ALL_RESOURCES_PAGE';

export const FETCH_TAG_ENV_LIST = 'FETCH_TAG_ENV_LIST';
export const FETCH_TAG_RESOURCE_TYPE_LIST = 'FETCH_TAG_RESOURCE_TYPE_LIST';
export const FETCH_TAG_REGIONS_LIST = 'FETCH_TAG_REGIONS_LIST';
export const FETCH_TAG_RESOURCE_LIST = 'FETCH_TAG_RESOURCE_LIST';

export const FETCH_LIST_REGIONS = 'FETCH_LIST_REGIONS';
export const FETCH_LAST_UPDATE = 'FETCH_LAST_UPDATE';

export const UPDATING_RESOURCE_OVERTIME = 'UPDATING_RESOURCE_OVERTIME';
export const UPDATING_RESOURCE_NON_COMPLIANT = 'UPDATING_RESOURCE_NON_COMPLIANT';

// Compliance Page
export const FETCH_ALL_COMPLIANCE_CATEGORIES = 'FETCH_ALL_COMPLIANCE_CATEGORIES';
export const UPDATE_CRITICAL_RESOURCES_PAGE = 'UPDATE_CRITICAL_RESOURCES_PAGE';
export const FETCH_CRITICAL_RESOURCES_PAGE = 'FETCH_CRITICAL_RESOURCES_PAGE';
export const FETCH_SERVICE_LIST_PROPERTY_KEYS = 'FETCH_SERVICE_LIST_PROPERTY_KEYS';
export const UPDATE_ALERT_SEVERITY = 'UPDATE_ALERT_SEVERITY';
export const FETCH_SERVICE_LIST_PROPERTY_VALUES = 'FETCH_SERVICE_LIST_PROPERTY_VALUES';

export const UPDATE_REGIONS = 'UPDATE_REGIONS';
export const UPDATE_RESOURCE_LIST = 'UPDATE_RESOURCE_LIST';
