import { SOCKET_CONNECT_REQUEST, SOCKET_DISCONNECT_REQUEST } from '../constants/actionTypes';

const defaultState = {
  isSocketActive: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SOCKET_CONNECT_REQUEST:
      return { ...state, isSocketActive: true };
    case SOCKET_DISCONNECT_REQUEST:
      return {
        ...state,
        isSocketActive: false,
      };
    default:
      return state;
  }
};
