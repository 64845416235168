import { FETCH_SCAN_REPORT, FLUSH_SCAN_REPORT, SET_IS_FETCHING_SCAN_REPORT } from '@constants/actionTypes';
import { SetScanInfo, SetTableData } from '@root/utilities/scanReportReducerUtils';
import _ from 'lodash';

const defaultState = {
  status: 'fetching',
  isFetchingScanReport: false,
  scanInformation: {},
  complianceByResource: [],
  complianceByResourceType: [],
  complianceByControl: [],
  complianceByResourceFilter: [],
  complianceByResourceTypeFilter: [],
  complianceByControlFilter: [],
  environment_tags: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_SCAN_REPORT: {
      return { ...state, status: action.payload.status };
    }
    case FETCH_SCAN_REPORT:
      if (action.payload) {
        let counterStart = action.payload.data.data.tabs_data.compliant_non_compliant.length;
        let nonEvaluated = [];
        if (!_.isEmpty(action.payload.data.data.tabs_data.non_evaluated_resources))
          nonEvaluated = action.payload.data.data.tabs_data.non_evaluated_resources.map(item => {
            counterStart = counterStart + 1;
            let element = { ...item, sr_no: counterStart };
            return element;
          });
        let oldTable = action.payload.data.data.tabs_data.compliant_non_compliant;
        action.payload.data.data.tabs_data.compliant_non_compliant = [...oldTable, ...nonEvaluated];
        const scanInfo = SetScanInfo(action.payload.data.data);
        const { resource, resourceType, resourceControl } = SetTableData(action.payload.data.data);
        return {
          ...state,
          scanInformation: { ...scanInfo, scan_id: action.payload.scan_id },
          complianceByResource: resource.table.map(item => ({ ...item, id: item.sr_no })),
          complianceByResourceType: resourceType.table,
          complianceByControl: resourceControl.table,
          status: action.payload.status,
          environment_tags: action.payload.data.data.environment_tags,
        };
      } else {
        return {
          ...state,
          status: 200,
        };
      }
    case 'SET_FILTERED_RESOURCE_DATA_REPORT':
      if (action.payload) {
        return {
          ...state,
          complianceByResourceFilter: action.payload.data,
          filteredby: action.payload.key,
        };
      }
      break;

    case 'FETCH_FILTER_CONTROL_DATA':
      if (action.payload) {
        return {
          ...state,
          complianceByControl: action.payload.data,
        };
      }
      break;

    case 'SET_FILTERED_RESOURCE_TYPE_DATA_REPORT':
      if (action.payload) {
        return {
          ...state,
          complianceByResourceTypeFilter: action.payload.data,
        };
      }
      break;

    case 'SET_FILTERED_CONTROL_DATA_REPORT':
      if (action.payload) {
        return {
          ...state,
          complianceByControlFilter: action.payload.data,
        };
      }
      break;

    case FLUSH_SCAN_REPORT:
      return { ...defaultState };
    default:
      return state;
  }
};
