import {
  ONE_CLICK_CLOUD_GLOBAL_FILTERS_SET,
  ONE_CLICK_INPUT_FORM_SET,
  ONE_CLICK_SETUP_FORM_SET,
} from '@root/constants/actionTypes';

const defaultState = {
  cloud: 'aws',
  policies: [],
  reports: [],
  setup: {
    policyname: null,
    environmentID: null,
  },
  input: {
    service: null,
    rule: null,
    resourceType: null,
    regions: null,
  },
  settings: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ONE_CLICK_CLOUD_GLOBAL_FILTERS_SET:
      return {
        ...state,
        cloud: action.payload,
      };
    case ONE_CLICK_SETUP_FORM_SET:
      return {
        ...state,
        setup: {
          ...state.setup,
          [action.payload.key]: action.payload.value,
        },
      };
    case ONE_CLICK_INPUT_FORM_SET:
      return {
        ...state,
        input: {
          ...state.setup,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
