import React from 'react';
import { default as MuiTooltip, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => <MuiTooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fafafa',
      color: '#121212',
      boxShadow: theme.shadows[2],
      fontSize: 11,
      fontFamily: 'Manrope',
      maxWidth: 'none',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#efefef',
    },
  }),
);

function TopOpenTooltip({ title, children }) {
  return (
    <LightTooltip title={title} placement={'top-start'} arrow>
      {children}
    </LightTooltip>
  );
}

export default TopOpenTooltip;
