import _ from 'lodash';
import {
  FETCH_SCAN_HISTORY,
  SCAN_HISTORY_APPLY_FILTER,
  CHANGE_SCAN_HISTORY_PAGE,
  SCAN_HISTORY_FILTER_KEY_CHANGE,
  SCAN_HISTORY_REMOVE_FILTER,
  SCAN_HISTORY_RESET_FILTER,
} from '../constants/actionTypes';

const defaultState = {
  status: 'loading',
  scanHistoryObj: null,
  currentPage: 1,
  scanHistoryFiltered: null,
  filters: {
    filterKeys: [
      {
        field: 'environment_id',
        text: 'Environment ID',
      },
      {
        field: 'environment_name',
        text: 'Environment Name',
      },
      {
        field: 'assessment_name',
        text: 'Assessment Name',
      },
      {
        field: 'cloud_provider',
        text: 'Cloud Provider',
      },
      {
        field: 'status',
        text: 'Status',
      },
    ],
    appliedFilters: [],
    suggestions: [],
  },
  reRender: false,
};

const _reAdjustFilters = (d, f) => {
  let resData = [...d];
  f.map(itm => {
    let temp = resData.filter(el => el[itm['field']] === itm['q']);
    resData = [...temp];
  });
  return resData;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_SCAN_HISTORY: {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          status: action.payload['status'],
          scanHistoryObj: !_.isEmpty(action.payload['data']['data']['history'])
            ? action.payload['data']['data']['history']
            : [],
          scanHistoryFiltered: !_.isEmpty(action.payload['data']['data']['history'])
            ? action.payload['data']['data']['history']
            : [],
        };
      }
      break;
    }
    case CHANGE_SCAN_HISTORY_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case SCAN_HISTORY_FILTER_KEY_CHANGE: {
      let { key } = action.payload;
      return {
        ...state,
        filters: {
          ...state['filters'],
          suggestions: [...new Set(state.scanHistoryFiltered.map(el => el[key]))],
        },
      };
    }
    case SCAN_HISTORY_APPLY_FILTER: {
      let { newFilter } = action.payload;
      let result = [];
      let newAppliedFilters = [...state['filters'].appliedFilters];
      if (
        state['filters'].appliedFilters.filter(el => el.field === newFilter.field && el.q === newFilter.q).length === 0
      ) {
        newAppliedFilters = [...newAppliedFilters, newFilter];
        result = state.scanHistoryFiltered.filter(el => el[newFilter['field']] === newFilter['q']);
      }
      return {
        ...state,
        scanHistoryFiltered: [...result],
        filters: {
          ...state.filters,
          appliedFilters: [...newAppliedFilters],
        },
        reRender: !state.reRender,
      };
    }
    case SCAN_HISTORY_REMOVE_FILTER: {
      let { index } = action.payload;
      const newFilters = [...state['filters'].appliedFilters];
      if (newFilters.length) newFilters.splice(index, 1);
      const result = _reAdjustFilters([...state.scanHistoryObj], [...newFilters]);
      return {
        ...state,
        scanHistoryFiltered: [...result],
        filters: {
          ...state['filters'],
          appliedFilters: [...newFilters],
        },
        reRender: !state.reRender,
      };
    }
    case SCAN_HISTORY_RESET_FILTER:
      return {
        ...state,
        scanHistoryFiltered: [...state.scanHistoryObj],
        filters: {
          ...state['filters'],
          appliedFilters: [],
          suggestions: [],
        },
        reRender: !state.reRender,
      };
    default:
      return state;
  }
};
