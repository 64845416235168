import React from 'react';
import { Col, Row } from 'reactstrap';

const Footer = () => (
  <footer>
    <Row noGutters className="d-flex flex-row-reverse text-right text-10 p-2">
      <Col sm="auto">
        {/* <p className="mb-0 text-600">
         Powered By <span className="d-none d-sm-inline-block">| </span>
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy; <a href="https://seclogic.io/">SecLogic</a>
        </p> */}
      </Col>
    </Row>
  </footer>
);

export default Footer;
