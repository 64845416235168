import * as Sentry from '@sentry/react';

const DEV_CONFIG = {
  // Used on LOCAL, DEV, QA
  tracesSampleRate: 0.5, // Capture 50% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 50%.
  replaysOnErrorSampleRate: 0.5, // 50% sample rate when sampling sessions where errors occur.
};

const PROD_CONFIG = {
  tracesSampleRate: 0.1, // Capture 10% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%.
  replaysOnErrorSampleRate: 1.0, // 100% sample rate when sampling sessions where errors occur.
};

function initializeSentry() {
  if (import.meta.env.DEV) {
    // Sentry is disabled on local and PROD
    return;
  }

  const CONFIG = ['development', 'staging'].includes(import.meta.env.MODE) ? DEV_CONFIG : PROD_CONFIG;
  const environment = { development: 'DEV', staging: 'QA', production: 'PROD' }[import.meta.env.MODE] || 'PROD';

  Sentry.init({
    dsn: 'https://da62bbdc47045d351359fd7842194ad7@o4505891948462080.ingest.sentry.io/4505914363346944',
    environment,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/dev\.cyberqshield\.seclogic\.io/,
          /^https:\/\/qa\.cyberqshield\.seclogic\.io/,
          /^https:\/\/cyberqshield\.seclogic\.io/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: CONFIG.tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate: CONFIG.replaysSessionSampleRate,
    replaysOnErrorSampleRate: CONFIG.replaysOnErrorSampleRate,
  });
}

export { initializeSentry };
