import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useQuery } from '@root/hooks/useQuery';

import AWSIcon from '@root/assets/img/header/cloud_providers/aws.png';
import AzureIcon from '@root/assets/img/header/cloud_providers/azure.png';
import GCPIcon from '@root/assets/img/header/cloud_providers/gcp.png';
import {
  UPDATE_ALL_RESOURCES_PAGE,
  UPDATE_CRITICAL_RESOURCES_PAGE,
  UPDATE_KEY_INSIGHTS_PAGE,
  UPDATE_REGIONS,
  UPDATE_RESOURCE_LIST,
} from '@root/constants/actionTypes';
import { useEnvironmentTags, useEnvironments, useSelectedEnvironments } from '@root/hooks/environments';
import useTimeFilter from '@root/hooks/SelectTimeFilter.jsx';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import './style.scss';

import EnvironmentDropdown from './EnvionmentDropdown';
import ScopeDropdown from './ScopeDropdown';
import TimeFilterDropdown from './TimeFilterDropdown';
// import TimeRangeSelector from './TimeRange';
// import { setTimeFilter } from '@root/containers/global_actions';

function HeaderContent() {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();
  const fromRunTime = query.get('fromRunTime');
  const fromRuntimeThreat = query.get('fromRuntimeThreat');
  const fromAccount = query.get('account');
  const fromScanHistory = query.get('from');
  const masterRuleId = query.get('masterRuleId');
  const fromDashBoardOpenThreats = query.get('fromDashBoardOpenThreats');
  const fromDashboardResourceTrend = query.get('fromDashboardResourceTrend');
  const environmentTags = useEnvironmentTags();
  const environments = useEnvironments();
  const fromScanToEvent = query.get('fromScanReport');
  const fromLinkCopySideOverlay = query.get('fromLinkCopySideOverlay');
  const fromDefaultTimeRange = query.get('fromDefaultTimeRange');
  const defaultTimeRange = query.get('defaultTimeRange');
  const fromEventsOverview = query.get('fromEventsOverview');
  const { selectedEnvironmentTags, selectedEnvironments, setSelectedEnvironmentTags, setSelectedEnvironments } =
    useSelectedEnvironments();
  const { timeFilter, updateTimeFilter } = useTimeFilter();
  const [navStack, setNavStack] = useState([]);
  const [rangeValue, setRangeValue] = useState('15m');
  // const [value, setValue] = useState('15m');
  useEffect(() => {
    if (
      location.pathname === '/events/all' &&
      (fromRunTime ||
        masterRuleId ||
        fromDashBoardOpenThreats ||
        fromRuntimeThreat ||
        fromScanHistory === 'scan_history' ||
        fromLinkCopySideOverlay)
    ) {
      setRangeValue('90d');
    } else if (location.pathname === '/events/overview') {
      setRangeValue('1d');
    } else if (location.pathname === '/events/all' && fromDashboardResourceTrend) {
      setRangeValue(timeFilter === '24h' ? '1d' : timeFilter);
    } else if (location.pathname === '/events/all' && fromDefaultTimeRange && defaultTimeRange) {
      setRangeValue(
        defaultTimeRange === '24 hours' || defaultTimeRange === '1 day'
          ? '1d'
          : defaultTimeRange?.includes('minutes')
          ? defaultTimeRange.replace('minutes', 'm').replace(/\s/g, '')
          : defaultTimeRange?.includes('hour')
          ? defaultTimeRange.replace(/hour(s)?/, 'h').replace(/\s/g, '')
          : defaultTimeRange?.includes('day')
          ? defaultTimeRange.replace(/day(s)?/, 'd').replace(/\s/g, '')
          : defaultTimeRange,
      );
    }
  }, [
    fromDashBoardOpenThreats,
    fromRuntimeThreat,
    fromRunTime,
    location.pathname,
    masterRuleId,
    fromScanHistory,
    timeFilter,
    fromLinkCopySideOverlay,
    defaultTimeRange,
    fromDefaultTimeRange,
    fromDashboardResourceTrend,
    fromEventsOverview,
  ]);

  useEffect(() => {
    const unlist = history.listen(location => {
      setNavStack(prev => {
        if (prev.length >= 4) {
          prev.splice(0, 1);
        }

        if (prev.includes(location.pathname)) {
          const newStack = prev.filter(path => path !== location.pathname);
          newStack.push(location.pathname);
          return newStack;
        }

        return [...prev, location.pathname];
      });
    });

    return unlist;
  }, [history]);

  useEffect(() => {
    console.log(navStack, 'navStack');
  }, [navStack]);

  const setSelectedEnvironmentsRef = useRef(setSelectedEnvironments);
  setSelectedEnvironmentsRef.current = setSelectedEnvironments;

  const [disabledEnvironmentOptionValues, setDisabledEnvironmentOptionValues] = useState([]);

  const environmentTagOptions = useMemo(() => {
    return environmentTags.map(tag => ({ label: tag, value: tag }));
  }, [environmentTags]);

  const timeFilterOptions = useMemo(() => {
    if (
      location.pathname === '/events/all' ||
      location.pathname === '/events/suppressed' ||
      location.pathname === '/events/overview'
    )
      return [
        { label: 'Last 15m', value: '15m' },
        { label: 'Last 30m', value: '30m' },
        { label: 'Last 1h', value: '1h' },
        { label: 'Last 6h', value: '6h' },
        { label: 'Last 12h', value: '12h' },
        { label: 'Last 1d', value: '1d' },
        { label: 'Last 2d', value: '2d' },
        { label: 'Last 7d', value: '7d' },
        { label: 'Last 30d', value: '30d' },
        { label: 'Last 90d', value: '90d' },
      ];

    return [
      { label: '24h', value: '24h' },
      { label: '7d', value: '7d' },
      { label: '30d', value: '30d' },
      { label: '90d', value: '90d' },
    ];
  }, [location.pathname]);

  const environmentOptions = useMemo(() => {
    const awsEnvironments = [];
    const gcpEnvironments = [];
    const azureEnvironments = [];

    for (const env of environments) {
      if (env.cloudProvider === 'aws') {
        awsEnvironments.push({ label: env.id, value: env.id, data: env });
      }

      if (env.cloudProvider === 'gcp') {
        gcpEnvironments.push({ label: env.id, value: env.id, data: env });
      }

      if (env.cloudProvider === 'azure') {
        azureEnvironments.push({ label: env.id, value: env.id, data: env });
      }
    }

    const groups = [];

    if (awsEnvironments.length) {
      groups.push({
        label: 'AWS',
        options: awsEnvironments,
        icon: AWSIcon,
      });
    }

    if (gcpEnvironments.length) {
      groups.push({
        label: 'GCP',
        options: gcpEnvironments,
        icon: GCPIcon,
      });
    }

    if (azureEnvironments.length) {
      groups.push({
        label: 'Azure',
        options: azureEnvironments,
        icon: AzureIcon,
      });
    }

    return groups;
  }, [environments]);

  const handleEnvironmentTagsChange = useCallback(
    selectedTags => {
      const prev = selectedEnvironmentTags;

      if (!prev.includes('Global') && selectedTags.includes('Global')) {
        // Global tag is being selected. We unselect all others.
        // Also, all environments are selectable when Global tag is selected.
        setDisabledEnvironmentOptionValues([]);
        setSelectedEnvironmentTags(['Global']);
      } else {
        if (selectedTags.length) {
          let finalSelectedTags;

          if (prev.includes('Global')) {
            // Unselect Global when other tags are selected.
            finalSelectedTags = selectedTags.filter(t => t !== 'Global');
          } else {
            finalSelectedTags = selectedTags;
          }

          // START: Disable environment options for unselected tags.
          const disabledEnvValues = [];
          const selectedEnvironmentTagsMap = {};
          for (const t of finalSelectedTags) {
            selectedEnvironmentTagsMap[t] = true;
          }
          for (const env of environments) {
            if (!env.tags.find(t => selectedEnvironmentTagsMap[t])) {
              disabledEnvValues.push(env.id);
            }
          }
          setDisabledEnvironmentOptionValues(disabledEnvValues);
          // END: Disable environment options for unselected tags.

          // START: Pre-select environment options which have the newly selected tags
          const newSelectedTags = [];
          const unselectedTags = [];

          for (const t of selectedTags) {
            if (!prev.includes(t)) {
              newSelectedTags.push(t);
            }
          }

          for (const t of prev) {
            if (!selectedTags.includes(t)) {
              unselectedTags.push(t);
            }
          }

          const newSelectedTagsMap = {};

          for (const t of newSelectedTags) {
            newSelectedTagsMap[t] = true;
          }

          if (newSelectedTags.length || unselectedTags.length) {
            setSelectedEnvironments(prevEnvs => {
              const newEnvsToBeSelected = [];
              const envsToBeUnselected = {};

              for (const e of environments) {
                if (!e.tags.find(t => finalSelectedTags.includes(t))) {
                  // We unselect the previously selected environments which are no longer
                  // linked to the newly updated selected tags.
                  envsToBeUnselected[e.id] = true;
                }

                if (disabledEnvValues.find(id => e.id === id)) {
                  envsToBeUnselected[e.id] = true;
                }

                if (!envsToBeUnselected[e.id] && e.tags.find(t => newSelectedTagsMap[t])) {
                  // If this environment's tags are in the newly selected tags
                  // add the environment to the selection.
                  newEnvsToBeSelected.push(e);
                }
              }

              const selected = [...prevEnvs, ...newEnvsToBeSelected]
                .reduce((acc, curr) => (acc.find(e => e.id === curr.id) ? acc : [...acc, curr]), []) // Remove duplicates
                .filter(env => !envsToBeUnselected[env.id]);

              return selected;
            });
          }
          // END: Pre-select environment options which have the newly selected tags

          setSelectedEnvironmentTags(finalSelectedTags);
        } else {
          setSelectedEnvironmentTags(['Global']);
          setSelectedEnvironments(environments);
          setDisabledEnvironmentOptionValues([]);
        }
      }
    },
    [selectedEnvironmentTags, setSelectedEnvironmentTags, environments, setSelectedEnvironments],
  );

  // Handle Global tag being selected. We pre-selected all environments.
  useEffect(() => {
    if (selectedEnvironmentTags.includes('Global')) {
      setSelectedEnvironmentsRef.current(environments.map(e => e));
    }
    if (fromAccount) {
      setSelectedEnvironmentsRef.current(environments.filter(e => e.id === fromAccount));
    }
  }, [selectedEnvironmentTags, environments, fromAccount]);

  const updateResourceOverview = useCallback(() => {
    dispatch({
      type: UPDATE_REGIONS,
    });
  }, [dispatch]);

  const updateResourceEvaluation = useCallback(() => {
    dispatch({
      type: UPDATE_RESOURCE_LIST,
    });
  }, [dispatch]);

  const updateResources = useCallback(() => {
    dispatch({
      type: UPDATE_ALL_RESOURCES_PAGE,
    });
  }, [dispatch]);

  const updateResourcesCriticalTab = useCallback(() => {
    dispatch({
      type: UPDATE_CRITICAL_RESOURCES_PAGE,
    });
  }, [dispatch]);
  const updateResourcesKeyInsightTab = useCallback(() => {
    dispatch({
      type: UPDATE_KEY_INSIGHTS_PAGE,
    });
  }, [dispatch]);
  const handleEnvironmentsChange = useCallback(
    selectedEnvIds => {
      if (selectedEnvIds.length !== 0) {
        setSelectedEnvironments(environments.filter(e => selectedEnvIds.includes(e.id)));
      }
      updateResourceOverview();
      updateResourceEvaluation();
      updateResources();
      updateResourcesCriticalTab();
      updateResourcesKeyInsightTab();
    },

    [
      environments,
      setSelectedEnvironments,
      updateResourceEvaluation,
      updateResourceOverview,
      updateResources,
      updateResourcesCriticalTab,
      updateResourcesKeyInsightTab,
    ],
  );
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}
      className="HeaderContent__Container">
      {location.pathname !== '/scan-history/scan-report' &&
        location.pathname !== '/rules' &&
        location.pathname !== '/scan-history/scanner' &&
        location.pathname !== '/environments/connect' &&
        location.pathname !== '/onboarding' &&
        location.pathname !== '/select_compliance' &&
        location.pathname !== '/rbac' &&
        location.pathname !== '/remediation' &&
        location.pathname !== '/risk/risk-table/remediation' &&
        location.pathname !== '/notifications/jira' &&
        !fromScanToEvent && (
          <>
            <ScopeDropdown
              options={environmentTagOptions}
              value={selectedEnvironmentTags}
              onChange={handleEnvironmentTagsChange}
            />
            {/* <Select
              placeholder={!selectedEnvironmentTags.length ? 'Global' : 'Scope'}
              options={environmentTagOptions}
              value={selectedEnvironmentTags}
              onChange={handleEnvironmentTagsChange}
              transparentBg
            /> */}
            <EnvironmentDropdown
              allOptions={environmentOptions}
              disabledOptions={disabledEnvironmentOptionValues}
              value={selectedEnvironments.map(e => e.id)}
              onChange={handleEnvironmentsChange}
              singleSelect
            />

            {/* <SelectGrouped
              placeholder={!selectedEnvironments.length ? 'All Environments' : 'Environments'}
              selectAllTitle="All Environments"
              allSelectedTitle="All Environments"
              optionGroups={environmentOptions}
              disabledOptionValues={disabledEnvironmentOptionValues}
              value={selectedEnvironments.map(e => e.id)}
              enableSelectAll
              transparentBg
              onChange={handleEnvironmentsChange}
            /> */}
            {(location.pathname.includes('identity') ||
              location.pathname === '/risk' ||
              location.pathname === '/dashboard' ||
              location.pathname === '/inventory/overview/new' ||
              location.pathname === '/inventory/key-insight/new' ||
              location.pathname === '/events' ||
              location.pathname === '/remediation-report' ||
              location.pathname === '/compliance' ||
              location.pathname === '/inventory/key-insight' ||
              location.pathname === '/inventory/overview' ||
              location.pathname === '/inventory/resources-table' ||
              location.pathname === '/inventory/resources' ||
              location.pathname === '/compliance/breakdown' ||
              location.pathname === '/identity' ||
              location.pathname === '/data-security/overview' ||
              location.pathname === '/data-security/findings' ||
              location.pathname === '/scan-history' ||
              location.pathname === '/data-security/insights') && (
              <>
                <TimeFilterDropdown
                  setValue={updateTimeFilter}
                  value={timeFilter}
                  options={timeFilterOptions}
                  singleSelect
                />
                {/* <TimeRangeSelector /> */}
              </>
            )}
            {(location.pathname === '/events/all' ||
              location.pathname === '/events/suppressed' ||
              location.pathname === '/events/overview') && (
              <TimeFilterDropdown
                setValue={option => setRangeValue(option)}
                value={rangeValue}
                options={timeFilterOptions}
                singleSelect
              />
            )}
          </>
        )}
    </div>
  );
}

export default HeaderContent;
