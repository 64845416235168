import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import AppContext from './components/falconReact/context/Context';
import toggleStylesheet from './components/falconReact/helpers/toggleStylesheet';
import { getItemFromStore, setItemToStore, themeColors } from './components/falconReact/helpers/utils';
import PubSubProvider from '@root/hooks/usePubSub/Provider';
import ErrorBoundaryMessage from '@components/v2/global/ErrorBoundaryMessage';
// import { queryClient, QueryClientProvider } from './ReactQueryConfig';

const Main = props => {
  const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', false));
  const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', false));
  const [isDark, setIsDark] = useState(getItemFromStore('isDark', false));
  const [isTopNav, setIsTopNav] = useState(getItemFromStore('isTopNav', false));
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore('isNavbarVerticalCollapsed', false),
  );
  const [currency, setCurrency] = useState('$');
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState(getItemFromStore('navbarStyle', 'card'));
  const toggleModal = () => setIsOpenSidePanel(prevIsOpenSidePanel => !prevIsOpenSidePanel);

  const value = useMemo(() => {
    return {
      isRTL,
      isDark,
      isFluid,
      setIsRTL,
      isTopNav,
      currency,
      setIsDark,
      setIsFluid,
      toggleModal,
      setIsTopNav,
      navbarStyle,
      setCurrency,
      showBurgerMenu,
      setNavbarStyle,
      isOpenSidePanel,
      setShowBurgerMenu,
      setIsOpenSidePanel,
      isNavbarVerticalCollapsed,
      setIsNavbarVerticalCollapsed,
    };
  }, [
    currency,
    isDark,
    isFluid,
    isNavbarVerticalCollapsed,
    isOpenSidePanel,
    isRTL,
    isTopNav,
    navbarStyle,
    showBurgerMenu,
  ]);

  const setStylesheetMode = useCallback(
    mode => {
      setIsLoaded(false);
      setItemToStore(mode, value[mode]);
      toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
    },
    [isRTL, isDark, value],
  );

  useEffect(() => {
    setStylesheetMode('isFluid');
  }, [isFluid, setStylesheetMode]);

  useEffect(() => {
    setStylesheetMode('isRTL');
  }, [isRTL, setStylesheetMode]);

  useEffect(() => {
    setStylesheetMode('isDark');
  }, [isDark, setStylesheetMode]);

  useEffect(() => {
    setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
  }, [isNavbarVerticalCollapsed]);

  useEffect(() => {
    setItemToStore('isTopNav', isTopNav);
  }, [isTopNav]);

  useEffect(() => {
    setItemToStore('navbarStyle', navbarStyle);
  }, [navbarStyle]);

  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light,
        }}
      />
    );
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
      <AppContext.Provider value={value}>
        <PubSubProvider>{props.children}</PubSubProvider>
      </AppContext.Provider>
    </ErrorBoundary>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
