import {
  DISENGAGE_REMEDIATION_SOCKET_ACC,
  GENERATE_REMEDIATION_PAGE_FOR_SCAN,
  REMEDIATION_SOCKET_CLOSE,
  REMEDIATION_SOCKET_OPEN,
  REMEDIATION_SOCKET_RECEIVE,
  REMEDIATION_SOCKET_SEND,
  UPDATE_REMEDIATION_EXPAND_DETAILS,
} from '@constants/actionTypes';
import _ from 'lodash';

import ReconnectingWebSocket from 'reconnecting-websocket';
// Socket open
// Socket send
// Socket update
// Socket close

const defaultState = {
  array: [],
  socket: {},
  engagedAccounts: [],
};

function waitForSocketConnection(socket, callback) {
  setTimeout(function () {
    if (socket.readyState === 1) {
      //console.log("Connection is made");
      if (callback != null) {
        callback();
      }
    } else {
      //console.log("wait for connection...");
      waitForSocketConnection(socket, callback);
    }
  }, 5); // wait 5 millisecond for the connection...
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GENERATE_REMEDIATION_PAGE_FOR_SCAN:
      return {
        ...state,
        array: [...state.array, { ...action.payload }],
      };
    // debugger;
    case UPDATE_REMEDIATION_EXPAND_DETAILS: {
      const { scanID, awsConfig } = action.payload;
      // console.log(ruleName,"RuleName Reducer")
      return {
        ...state,
        array: state.array.map(remediationEntry => {
          // Adds aws configuration based on the scan ID
          if (remediationEntry['scanID'] === scanID) {
            return {
              ...remediationEntry,
              resource: {
                ...remediationEntry.resource,
                configuration: awsConfig,
              },
              // Adds instructions to the particular rule with rule name
              // rules: remediationEntry.rules.map(element =>
              //   element.rule_name === ruleName ? { ...element, instructions } : element,
              // ),
            };
          } else return remediationEntry;
        }),
      };
    }

    case REMEDIATION_SOCKET_OPEN: {
      let socketObj = new ReconnectingWebSocket(import.meta.env.VITE_WEBSOCKET_REMEDIATION);
      return {
        ...state,
        socket: socketObj,
        engagedAccounts: [...state.engagedAccounts, action.payload],
      };
    }

    case REMEDIATION_SOCKET_SEND: {
      let socketInstance = state.socket;
      waitForSocketConnection(socketInstance, function () {
        //console.log("message sent!!!");
        socketInstance.send(JSON.stringify({ data: action.payload }));
      });
      // if (!socketInstance && socketInstance["readyState"] === 1) {
      //
      // }
      return {
        ...state,
        socket: socketInstance,
      };
    }
    case REMEDIATION_SOCKET_RECEIVE:
      return {
        ...state,
        array: state.array.map(element => {
          if (element.scanID === action.payload['scanID']) {
            return {
              ...element,
              rules: element.rules.map(rule => {
                if (rule.rule_name === action.payload['result']['rule_name']) {
                  return { ...rule, result: action.payload['result'] };
                } else {
                  return rule;
                }
              }),
            };
          } else return element;
        }),
      };

    case DISENGAGE_REMEDIATION_SOCKET_ACC:
      return {
        ...state,
        engagedAccounts: state.engagedAccounts.filter(element => element !== action.payload),
      };

    case REMEDIATION_SOCKET_CLOSE:
      if (!_.isEmpty(state.socket)) state.socket.close();
      return {
        ...state,
        socket: {},
      };
    case 'Action_name':
      return defaultState;
    default:
      return state;
  }
};

// import {
// 	REMEDIATION_STORE_RESOURCE_INFO,
// 	REMEDIATION_STORE_RULES,
// 	REMEDIATION_ADD_AWS_CONFIG_RESOURCE,
// 	REMEDIATION_ADD_INSTRUCTIONS_RULES,
// 	REMEDIATION_STORE_REMEDIATION_SCAN,
// 	REMEDIATION_ADD_LAST_DATA,
// 	REMEDIATION_REMOVE_LAST_DATA,
// 	REMEDIATION_UPDATE_LAST_DATA,
// } from "../constants/actionTypes";

// case REMEDIATION_STORE_RESOURCE_INFO:
// 			return {
// 				...state,
// 				resource: action.payload["resourceFromParent"],
// 				scanID: action.payload["scanIDFromParent"],
// 			};
// 		case REMEDIATION_STORE_RULES:
// 			return {
// 				...state,
// 				rules: action.payload,
// 			};
// 		case REMEDIATION_ADD_AWS_CONFIG_RESOURCE:
// 			let newResource = { ...state.resource, awsConfig: action.payload };
// 			return {
// 				...state,
// 				resource: newResource,
// 			};
// 		case REMEDIATION_ADD_INSTRUCTIONS_RULES:
// 			let { rule_name, ...stepsData } = action.payload;
// 			let pos = state.rules.findIndex((element) => element["rule_name"] === rule_name);
// 			let newRule = { ...state.rules[pos], ...stepsData };
// 			let newRules = state.rules.map((rule) => (rule["rule_name"] === rule_name ? newRule : rule));
// 			return {
// 				...state,
// 				rules: newRules,
// 			};
// 		case REMEDIATION_STORE_REMEDIATION_SCAN:
// 			return {
// 				...state,
// 				report: action.payload,
// 			};
// 		case REMEDIATION_ADD_LAST_DATA:
// 			return {
// 				...state,
// 				scanned: [...state.scanned, action.payload]
// 			}
// 		case REMEDIATION_REMOVE_LAST_DATA:
// 			return {
// 				...state,
// 				scanned: state.scanned.filter(e => e['resource_id'] !== action.payload["resource_id"] && e["rule_name"] !== action.payload["rule_name"])
// 			}
// 		case REMEDIATION_UPDATE_LAST_DATA:
// 			const scanned = state.scanned;
// 			const lastScannedIndex = state.scanned.findIndex(e => e['resource_id'] == action.payload["resource_id"] && e["rule_name"] == action.payload["rule_name"])
// 			if(lastScannedIndex >= 0) {
// 				// const oldIndex = scanned.indexOf(lastScanned[0])
// 				const temp = scanned[lastScannedIndex]
// 				temp["status"] = action.payload["status"]
// 				scanned[lastScannedIndex] = temp
// 				return {
// 					...state,
// 					scanned: [...scanned]
// 				}
// 			} else
// 			return {
// 				...state,
// 				scanned: state.scanned.filter(e => e['resource_id'] !== action.payload["resource_id"] && e["rule_name"] !== action.payload["rule_name"])
// 			}
