import {
  APP_LOAD,
  CHANGE_SIDEBAR_WIDTH,
  ERROR_API_CALL,
  ERROR_API_RES,
  FETCH_RISK_DATE_COMPLETE,
  FETCH_RULES_DATE_COMPLETE,
} from '../constants/actionTypes';
import BreadCrumbUtil from './_breadCrumbUtil';

const defaultState = {
  appName: 'CyberQ',
  breadCrumbStack: [],
  token: null,
  viewChangeCounter: 0,
  dashboardData: null,
  isFetchingDashboardData: false,
  isFetchingRulesData: false,
  isFetchingRiskData: false,
  errorMsg: null,
  errorCode: null,
  applicationState: {
    menuLock: false,
  },
  sidebarWidth: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_WIDTH:
      return { ...state, sidebarWidth: action.payload };
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        breadCrumbStack: [...BreadCrumbUtil(action.payload.location.pathname, state.breadCrumbStack)],
      };
    case APP_LOAD:
      return {
        ...state,
        isFetchingDashboardData: true,
        isFetchingRulesData: true,
        isFetchingRiskData: true,
      };
    // case FETCH_DASHBOARD_DATA:
    // 	return { ...state, dashboardData: action.payload, isFetchingDashboardData: false };
    case FETCH_RULES_DATE_COMPLETE:
      return { ...state, isFetchingRulesData: false };
    case FETCH_RISK_DATE_COMPLETE:
      return { ...state, isFetchingRiskData: false };
    case ERROR_API_CALL:
      return { ...state, errorMsg: action.payload, errorCode: 404 };
    case ERROR_API_RES:
      return { ...state, errorMsg: action.payload, errorCode: 500 };
    default:
      return state;
  }
};
