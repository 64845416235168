import React from 'react';
import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => <MuiTooltip {...props} classes={{ popper: className }} />)(
  ({ theme, ...props }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: props?.color || '#fafafa',
      color: props?.fontcolor || '#121212',
      boxShadow: theme.shadows[2],
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Manrope',
      padding: props?.padding || '4px 8px',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#efefef',
    },
  }),
);

function Tooltip({ title, children, ...props }) {
  if (!title) {
    return children;
  }
  return (
    <LightTooltip title={title} arrow {...props}>
      {children}
    </LightTooltip>
  );
}

export default Tooltip;
