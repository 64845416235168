import axios from 'axios';
const API_ROOT_LOCAL = import.meta.env.VITE_AXIOS_API_URL_ROOT; //dev
const API_BASE_ROOT = import.meta.env.VITE_AXIOS_BASE_URL_ROOT;
export const axiosDelete = async (url, isBase = false, deleteID) => {
  try {
    const tenantID = localStorage.getItem('tenantID');
    const token = localStorage.getItem('_t');
    const config = {
      headers: {
        authorization: 'Token ' + token,
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    };
    return await axios.delete(
      `${isBase ? API_BASE_ROOT : API_ROOT_LOCAL}/${url}/${tenantID}/${deleteID ? deleteID : null}`,
      config,
    );
  } catch (error) {
    console.error('HTTP POST request failed', error['message']);
  }
};
