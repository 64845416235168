import { Box, Grid, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';

function EnvironmentSkleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton isRegionFilter={true} />
      <CloudProviderBarSkeleton />

      <ContentBox paddingMode="content" marginBottom="15px">
        <Box
          className="Borders RoundedCorners"
          marginLeft="15px"
          marginRight="15px"
          marginBottom="15px"
          marginTop="15px">
          <Skeleton height="40px" style={{ transform: 'none' }} />
        </Box>
        <Grid>
          <Box
            className="Borders RoundedCorners"
            marginLeft="15px"
            marginRight="15px"
            marginBottom="15px"
            marginTop="15px">
            <Skeleton height="310px" style={{ transform: 'none' }} />
          </Box>
          <Box
            className="Borders RoundedCorners"
            marginLeft="15px"
            marginRight="15px"
            marginBottom="15px"
            marginTop="15px">
            <Skeleton height="310px" style={{ transform: 'none' }} />
          </Box>
        </Grid>
      </ContentBox>
    </PageContent>
  );
}

export default EnvironmentSkleton;
