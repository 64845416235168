import _ from 'lodash';

export const SetScanInfo = data => {
  let totalRegionsArr = [];
  let complianceJSONObjArr = [];
  for (const compliance of data.scaned_compliance.scanned_compliance) {
    let regionsArray = Object.keys(data[compliance].regions);
    totalRegionsArr.push(...regionsArray);
    let reportObj = data[compliance];
    reportObj.compliance = compliance;
    complianceJSONObjArr.push(reportObj);
  }
  const scanInfo = {
    csp_module: 'aws',
    environmentID: data.account_id,
    latestScanID: data.latest_scan_it,
    scan_id: data.scan_id,
    name: data.assessment_name,
    resources: {
      complaint: data.tabs_data.compliant,
      nonComplaint: data.tabs_data['non-compliant'],
      total: data.tabs_data['non-compliant'] + data.tabs_data.compliant,
      nonEvaluated: data.tabs_data.non_evaluated,
    },
    // resource_with_no_risk: data.resource_with_no_risk,
    // total_resources: data.total_resources,
    controls_info: !_.isEmpty(data.tabs_data.controls_info) ? data.tabs_data.controls_info : null,
    region: [...new Set(totalRegionsArr)],
    last_scan: data.scan_start,
    next_scheduled_scan: data.next_schedule_date,
    compliance: data.scaned_compliance.scanned_compliance,
    scan_repeats: data.scan_type,
    reportObjArr: complianceJSONObjArr,
    isRemediable: data.isRemediatable,
  };
  return scanInfo;
};
export const SetTableData = data => {
  const { tabs_data } = data;
  // let counterStart = tabs_data.compliant_non_compliant.length;
  // let nonEvaluated = [];
  // if (!_.isEmpty(tabs_data.non_evaluated_resources))
  // 	nonEvaluated = tabs_data.non_evaluated_resources.map((item) => {
  // 		counterStart = counterStart + 1;
  // 		let element = { ...item, sr_no: counterStart };
  // 		return element;
  // 	});
  let resource = {
    table: [...tabs_data.compliant_non_compliant],
    pagination: {
      page: 1,
      data: tabs_data.compliant_non_compliant.slice(0, 10),
      sizePerPage: 10,
    },
    expanded: [],
    nonExpandable: [],
  };
  let resourceType = {
    table: [...tabs_data.compliant_non_compliant_with_service].map((el, i) => {
      return { ...el, id: i };
    }),
    pagination: {
      page: 1,
      data: tabs_data.compliant_non_compliant_with_service.slice(0, 10),
      sizePerPage: 10,
    },
    expanded: [],
    nonExpandable: [],
  };
  let resourceControl = {
    table: [...tabs_data.resource_with_control],
    pagination: {
      page: 1,
      data: tabs_data.resource_with_control.slice(0, 10),
      sizePerPage: 10,
    },
    expanded: [],
    nonExpandable: [],
    expandedState: {},
  };
  return { resource, resourceType, resourceControl };
};
export const UpdateResource = (bufferObject, payloadObj) => {
  let updatedResources = { ...bufferObject };
  const { command, payload } = payloadObj;
  if (command === 'openExpansion') {
    const { sr_no, res } = payload;
    let pos = updatedResources.table.findIndex(e => e.sr_no === sr_no);
    if (res['data']['error'] === 1) {
      updatedResources.table[pos].jsonData = `Not Available`;
      updatedResources.expanded.push(sr_no);
    } else {
      if (!_.isEmpty(res.data.complete_data)) {
        updatedResources.table[pos].jsonData = res.data.complete_data;
        updatedResources.expanded.push(sr_no);
      }
    }
  } else if (command === 'pagination') {
    const { page, sizePerPage } = payload;
    const currentIndex = (page - 1) * sizePerPage;
    updatedResources.pagination = {
      page,
      data: updatedResources.table.slice(currentIndex, currentIndex + sizePerPage),
      sizePerPage,
    };
  } else if (command === 'disableExpansion') {
    const { sr_no } = payload;
    updatedResources.nonExpandable.push(sr_no);
  } else if (command === 'closeExpansion') {
    const { sr_no } = payload;
    updatedResources.expanded = updatedResources.expanded.filter(x => x !== sr_no);
  }
  return { updatedResources };
};
export const UpdateResourceType = (bufferObject, payloadObj) => {
  let updatedResourceType = { ...bufferObject };
  const { command, payload } = payloadObj;
  if (command === 'openExpansion') {
    const { sr_no, res } = payload;
    let pos = updatedResourceType.table.findIndex(e => e.sr_no === sr_no);
    updatedResourceType.table[pos].fetchedResources = res.data.reponse;
    updatedResourceType.expanded.push(sr_no);
  } else if (command === 'pagination') {
    const { page, sizePerPage } = payload;
    const currentIndex = (page - 1) * sizePerPage;
    updatedResourceType.pagination = {
      page,
      data: updatedResourceType.table.slice(currentIndex, currentIndex + sizePerPage),
      sizePerPage,
    };
  } else if (command === 'closeExpansion') {
    const { sr_no } = payload;
    updatedResourceType.expanded = updatedResourceType.expanded.filter(x => x !== sr_no);
  }
  return { updatedResourceType };
};
export const UpdateControls = (bufferObject, payloadObj) => {
  let updatedControls = { ...bufferObject };
  const { command, payload } = payloadObj;
  if (command === 'openExpansion') {
    const { sr_no } = payload;
    let pos = updatedControls.table.findIndex(e => e.sr_no === sr_no);
    // updatedControls.table[pos].fetchedResources = res.data.response_data;
    updatedControls.table[pos].fetchedRules = [
      {
        rule_id: 'CQ-Rule-1',
        rule_description: 'Ensure something in AWS',
        resources: {
          passed: 12,
          failed: 8,
        },
        severity: 'HIGH',
        evaluation: 'non-compliant',
      },
      {
        rule_id: 'CQ-Rule-2',
        rule_description: 'Ensure something else in AWS',
        resources: {
          passed: 11,
          failed: 0,
        },
        severity: 'MEDIUM',
        evaluation: 'compliant',
      },
      {
        rule_id: 'CQ-Rule-3',
        rule_description: 'Ensure something else in AWS',
        resources: {
          passed: 11,
          failed: 24,
        },
        severity: 'MEDIUM',
        evaluation: 'non-compliant',
      },
      {
        rule_id: 'CQ-Rule-4',
        rule_description: 'Ensure something else in AWS',
        resources: {
          passed: 21,
          failed: 0,
        },
        severity: 'LOW',
        evaluation: 'compliant',
      },
      {
        rule_id: 'CQ-Rule-5',
        rule_description: 'Ensure something else in AWS',
        resources: {
          passed: 40,
          failed: 1,
        },
        severity: 'MEDIUM',
        evaluation: 'non-compliant',
      },
    ];
    updatedControls.expanded.push(sr_no);
  } else if (command === 'pagination') {
    const { page, sizePerPage } = payload;
    const currentIndex = (page - 1) * sizePerPage;
    updatedControls.pagination = {
      page,
      data: updatedControls.table.slice(currentIndex, currentIndex + sizePerPage),
      sizePerPage,
    };
  } else if (command === 'disableExpansion') {
    const { sr_no } = payload;
    updatedControls.nonExpandable.push(sr_no);
  } else if (command === 'closeExpansion') {
    const { sr_no } = payload;
    updatedControls.expanded = updatedControls.expanded.filter(x => x !== sr_no);
  } else if (command === 'expandedControlRuleState') {
    updatedControls.expandedState = payload;
  } else if (command === 'clearExpandedState') {
    updatedControls.expandedState = {};
  }
  return { updatedControls };
};
