import './TopNavbar.scss';
import ProfileDropdown from './ProfileDropdown';
import HeaderContent from '@root/components/v2/global/HeaderContent';
import NotificationsPanel from './NotificationsPanel';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { kFormatter } from '@root/utilities/common';
import { fetchNotificationlistData } from './NotificationsPanel/action';
import usePubSub from '@root/hooks/usePubSub';

const TopNavbarNew = () => {
  const [showNotificationsPanel, setShowNotificationsPanel] = useState(false);
  const notificationCount = useSelector(state => state.notification.notification_list.unread_count);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notificationFromSocket, setNotificationFromSocket] = useState();
  const [todayNotificationsList, setTodayNotificationsList] = useState([]);
  useEffect(() => {
    (async () => {
      if (!showNotificationsPanel) {
        setLoading(true);
        await fetchNotificationlistData(dispatch, 1, false);
        setLoading(false);
      }
    })();
  }, [showNotificationsPanel, dispatch]);

  const sidebarWidth = useSelector(state => state.common.sidebarWidth);
  usePubSub(
    'notification',
    useCallback(data => {
      setNotificationFromSocket(data);
    }, []),
  );
  useEffect(() => {
    if (notificationFromSocket) {
      setTodayNotificationsList(prev => {
        const isDuplicate = prev.find(notification => notification.id === notificationFromSocket.id);

        if (!isDuplicate) {
          return [notificationFromSocket, ...prev];
        }
        // console.log(new Set([...prev]), 'knjnnj');
        return [...new Set(prev)];
      });
    }
  }, [notificationFromSocket]);
  // console.log(todayNotificationsList, 'todayNotificationsList');
  return (
    <div className="TopNavbar__Container" style={{ maxWidth: `calc(100vw - ${sidebarWidth}px)` }}>
      <div className="TopNavbar__ScopeFilters">
        <HeaderContent />
      </div>
      <div style={{ flexGrow: '1' }} />
      <div className="TopNavbar__UtilButtons">
        {/* refresh button */}
        <Box className="UtilButton__Container" onClick={() => location.reload()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <circle cx="21" cy="21" r="20.5" fill="#EDF2F7" stroke="#CBD5E0" />
            <path
              d="M28.4688 14.7188V19.5C28.4688 20.0977 27.9707 20.5625 27.4062 20.5625H22.625C22.0273 20.5625 21.5625 20.0977 21.5625 19.5C21.5625 18.9355 22.0273 18.4375 22.625 18.4375H24.7168C23.7207 17.1426 22.1602 16.3457 20.4668 16.3457C17.5449 16.3457 15.1875 18.7031 15.1875 21.625C15.1875 24.5801 17.5449 26.9375 20.4668 26.9375C21.6289 26.9375 22.7246 26.5723 23.6543 25.875C24.1191 25.543 24.7832 25.6426 25.1484 26.1074C25.5137 26.5723 25.4141 27.2363 24.9492 27.6016C23.6543 28.5645 22.0938 29.0625 20.4668 29.0625C16.3828 29.0625 13.0625 25.7422 13.0625 21.625C13.0625 17.541 16.3828 14.2207 20.4668 14.2207C22.791 14.2207 24.916 15.2832 26.3438 17.0762V14.7188C26.3438 14.1543 26.8086 13.6562 27.4062 13.6562C27.9707 13.6562 28.4688 14.1543 28.4688 14.7188Z"
              fill="#484D56"
            />
          </svg>
        </Box>

        {/* download button */}
        {/* <div className="UtilButton__Container" style={{ cursor: 'not-allowed' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <circle cx="21" cy="21" r="20.5" fill="#EDF2F7" stroke="#CBD5E0" />
            <path
              d="M28.9375 24.8125H24.4883L22.9941 26.3398C22.5957 26.7383 22.0645 26.9375 21.5 26.9375C20.9023 26.9375 20.3711 26.7383 19.9727 26.3398L18.4785 24.8125H14.0625C13.4648 24.8125 13 25.3105 13 25.875V29.0625C13 29.6602 13.4648 30.125 14.0625 30.125H28.9375C29.502 30.125 30 29.6602 30 29.0625V25.875C30 25.3105 29.502 24.8125 28.9375 24.8125ZM27.3438 28.2656C26.8789 28.2656 26.5469 27.9336 26.5469 27.4688C26.5469 27.0371 26.8789 26.6719 27.3438 26.6719C27.7754 26.6719 28.1406 27.0371 28.1406 27.4688C28.1406 27.9336 27.7754 28.2656 27.3438 28.2656ZM20.7363 25.5762C20.9355 25.7754 21.2012 25.875 21.5 25.875C21.7656 25.875 22.0312 25.7754 22.2305 25.5762L26.4805 21.3262C26.9121 20.9277 26.9121 20.2305 26.4805 19.832C26.082 19.4004 25.3848 19.4004 24.9863 19.832L22.5625 22.2559V14.1875C22.5625 13.623 22.0645 13.125 21.5 13.125C20.9023 13.125 20.4375 13.623 20.4375 14.1875V22.2559L17.9805 19.832C17.582 19.4004 16.8848 19.4004 16.4863 19.832C16.0547 20.2305 16.0547 20.9277 16.4863 21.3262L20.7363 25.5762Z"
              fill="#484D56"
            />
          </svg>
        </div> */}

        {/* question mark */}
        {/* <div className="UtilButton__Container" style={{ cursor: 'not-allowed' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <circle cx="21" cy="21" r="20.5" fill="#EDF2F7" stroke="#CBD5E0" />
            <path
              d="M20.0097 24.4396C20.0097 23.9781 20.0449 23.5453 20.1155 23.1414C20.1924 22.7376 20.3334 22.3529 20.5386 21.9875C20.7117 21.6413 20.9136 21.3624 21.1444 21.1509C21.3816 20.9329 21.638 20.7213 21.9137 20.5162C22.1958 20.3046 22.4907 20.0354 22.7984 19.7084C23.0549 19.4263 23.2247 19.141 23.3081 18.8526C23.3978 18.5641 23.4427 18.2531 23.4427 17.9198C23.4427 17.6762 23.4139 17.4486 23.3562 17.237C23.2985 17.0255 23.2023 16.8427 23.0677 16.6889C22.8625 16.4324 22.6029 16.2529 22.2887 16.1504C21.9746 16.0414 21.6477 15.9869 21.3079 15.9869C21.0002 15.9869 20.7053 16.0286 20.4232 16.1119C20.1475 16.1952 19.9167 16.3235 19.7308 16.4966C19.5321 16.6632 19.3846 16.8716 19.2885 17.1216C19.1987 17.3652 19.157 17.6313 19.1634 17.9198H17.0479C17.0991 17.3171 17.253 16.753 17.5094 16.2273C17.7659 15.7016 18.1121 15.2753 18.548 14.9483C18.9198 14.6534 19.3429 14.4355 19.8174 14.2944C20.2918 14.147 20.7662 14.0732 21.2406 14.0732C21.9201 14.0732 22.5612 14.179 23.1638 14.3906C23.7729 14.6021 24.2793 14.9451 24.6832 15.4195C24.9781 15.7529 25.1993 16.1343 25.3467 16.5639C25.5006 16.987 25.5775 17.4261 25.5775 17.8813C25.5775 18.4903 25.4461 19.0545 25.1833 19.5738C24.9268 20.0931 24.5967 20.5675 24.1928 20.997C23.9812 21.2214 23.7665 21.4265 23.5485 21.6124C23.3305 21.7919 23.1286 21.9682 22.9427 22.1413C22.7567 22.3144 22.6029 22.5036 22.4811 22.7087C22.308 23.0036 22.2086 23.2697 22.183 23.5069C22.1573 23.7377 22.1445 24.0486 22.1445 24.4396H20.0097ZM20.0193 28.1996V25.8725H22.1445V28.1996H20.0193Z"
              fill="#484D56"
            />
          </svg>
        </div> */}

        {/* notif button */}
        <Box className="UtilButton__Container" onClick={() => setShowNotificationsPanel(prev => !prev)}>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <circle cx="21" cy="21" r="20.5" fill="#EDF2F7" stroke="#CBD5E0" />
            <path
              d="M19.0849 24.9998V25.7998C19.0849 26.4363 19.3256 27.0468 19.754 27.4969C20.1825 27.9469 20.7636 28.1998 21.3695 28.1998C21.9754 28.1998 22.5565 27.9469 22.985 27.4969C23.4134 27.0468 23.6541 26.4363 23.6541 25.7998V24.9998M19.8466 15.3998C19.8466 14.9755 20.007 14.5685 20.2927 14.2684C20.5783 13.9684 20.9657 13.7998 21.3697 13.7998C21.7736 13.7998 22.161 13.9684 22.4466 14.2684C22.7323 14.5685 22.8927 14.9755 22.8927 15.3998C23.7673 15.8342 24.5128 16.5105 25.0494 17.356C25.5861 18.2016 25.8935 19.1846 25.9389 20.1998V22.5998C25.9962 23.0971 26.1639 23.5734 26.4284 23.9903C26.693 24.4071 27.047 24.7529 27.462 24.9998H15.2773C15.6923 24.7529 16.0464 24.4071 16.3109 23.9903C16.5754 23.5734 16.7431 23.0971 16.8004 22.5998V20.1998C16.8458 19.1846 17.1532 18.2016 17.6899 17.356C18.2265 16.5105 18.972 15.8342 19.8466 15.3998Z"
              stroke="#484D56"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <circle cx="26" cy="18" r="3.69231" fill="#E92C2C" stroke="white" stroke-width="1.38462" /> 
          </svg> */}
          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_980_2)">
              <path
                d="M32.3125 16.5C32.3125 7.767 25.233 0.6875 16.5 0.6875C7.767 0.6875 0.6875 7.767 0.6875 16.5C0.6875 25.233 7.767 32.3125 16.5 32.3125C25.233 32.3125 32.3125 25.233 32.3125 16.5Z"
                fill="#EDF2F7"
              />
              <path
                d="M32.3125 16.5C32.3125 7.767 25.233 0.6875 16.5 0.6875C7.767 0.6875 0.6875 7.767 0.6875 16.5C0.6875 25.233 7.767 32.3125 16.5 32.3125C25.233 32.3125 32.3125 25.233 32.3125 16.5Z"
                stroke="#CBD5E0"
              />
              <path
                d="M11.3932 9.54518C11.5087 9.40724 11.5656 9.22949 11.5516 9.05008C11.5376 8.87069 11.4538 8.70393 11.3182 8.58562C11.1826 8.4673 11.006 8.40687 10.8264 8.41729C10.6467 8.42772 10.4783 8.50815 10.3573 8.64134L9.17299 9.99801C8.50143 10.7676 8.12179 11.7488 8.10049 12.77L8.04824 15.2625C8.04448 15.4448 8.11329 15.6211 8.23956 15.7527C8.36583 15.8844 8.5392 15.9604 8.72154 15.9641C8.90388 15.9678 9.08024 15.8991 9.21184 15.7729C9.34344 15.6465 9.41948 15.4731 9.42324 15.2908L9.47458 12.7993C9.48928 12.1006 9.74919 11.4293 10.2088 10.9028L11.3932 9.54518Z"
                fill="#484D56"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.2173 14.3914C11.2788 13.4107 11.7118 12.4903 12.4281 11.8176C13.1444 11.1449 14.0902 10.7705 15.0728 10.7706H15.5833V10.0831C15.5833 9.83994 15.68 9.60678 15.8519 9.43488C16.0237 9.26296 16.2569 9.16638 16.5 9.16638C16.7431 9.16638 16.9763 9.26296 17.1482 9.43488C17.3201 9.60678 17.4167 9.83994 17.4167 10.0831V10.7706H17.9273C18.9099 10.7705 19.8557 11.1449 20.572 11.8176C21.2882 12.4903 21.7212 13.4107 21.7828 14.3914L21.9853 17.6309C22.0626 18.866 22.4773 20.0564 23.1843 21.0721C23.3305 21.2821 23.4197 21.5263 23.4435 21.7809C23.4672 22.0356 23.4247 22.2921 23.3199 22.5255C23.2153 22.7588 23.0519 22.9612 22.8458 23.1127C22.6397 23.2643 22.398 23.3598 22.144 23.3903L19.0208 23.7643V24.7497C19.0208 25.4183 18.7553 26.0595 18.2826 26.5322C17.8097 27.0049 17.1686 27.2706 16.5 27.2706C15.8315 27.2706 15.1903 27.0049 14.7175 26.5322C14.2448 26.0595 13.9792 25.4183 13.9792 24.7497V23.7643L10.8561 23.3894C10.6023 23.3589 10.3607 23.2632 10.1547 23.1118C9.94878 22.9602 9.78553 22.7581 9.68081 22.5248C9.5761 22.2916 9.53349 22.0353 9.55713 21.7808C9.58077 21.5261 9.66984 21.2821 9.8157 21.0721C10.5227 20.0564 10.9375 18.866 11.0147 17.6309L11.2173 14.3914ZM15.3542 24.7497C15.3542 25.0537 15.475 25.345 15.6897 25.56C15.9047 25.7749 16.1962 25.8956 16.5 25.8956C16.8039 25.8956 17.0954 25.7749 17.3103 25.56C17.5251 25.345 17.6458 25.0537 17.6458 24.7497V24.0622H15.3542V24.7497Z"
                fill="#484D56"
              />
              <path
                d="M21.6728 8.57514C21.5355 8.69503 21.4515 8.86453 21.4391 9.0464C21.4267 9.22827 21.4871 9.40761 21.6068 9.54499L22.7911 10.9016C23.2507 11.4285 23.5102 12.1002 23.5245 12.7991L23.5768 15.2897C23.5786 15.3801 23.5982 15.469 23.6345 15.5518C23.6708 15.6344 23.723 15.7092 23.7881 15.7717C23.8533 15.8342 23.9302 15.8833 24.0143 15.9162C24.0985 15.9489 24.1881 15.9648 24.2785 15.9631C24.3688 15.9611 24.4578 15.9415 24.5405 15.9053C24.6232 15.869 24.698 15.8168 24.7604 15.7516C24.823 15.6864 24.8721 15.6097 24.9049 15.5255C24.9376 15.4414 24.9536 15.3516 24.9518 15.2613L24.8995 12.7698C24.8782 11.7486 24.4986 10.7674 23.827 9.99782L22.6426 8.64114C22.5227 8.50382 22.3533 8.41973 22.1714 8.40735C21.9895 8.39496 21.8102 8.45533 21.6728 8.57514Z"
                fill="#484D56"
              />
            </g>
            <defs>
              <clipPath id="clip0_980_2">
                <rect width="33" height="33" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {notificationCount > 0 && <div className="NavbarNotifications__Counter">{kFormatter(notificationCount)}</div>}
        </Box>
      </div>

      <div className="TopNavbar__Profile">
        <ProfileDropdown />
      </div>
      {showNotificationsPanel && (
        <NotificationsPanel
          firstLoading={loading}
          isOpen={showNotificationsPanel}
          notificationFromSocket={notificationFromSocket}
          setTodayNotificationsList={setTodayNotificationsList}
          todayNotificationsList={todayNotificationsList}
          onClose={() => {
            setShowNotificationsPanel(false);
            dispatch({
              type: 'RESET_BELL_PAGE',
            });
          }}
        />
      )}
    </div>
  );
};

export default TopNavbarNew;
