import { SET_TENANTS } from '@constants/actionTypes';

const defaultState = {
  tenants: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_TENANTS: {
      return {
        ...state,
        tenants: action.payload,
      };
    }

    default:
      return state;
  }
};
