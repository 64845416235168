import axios from 'axios';
const API_ROOT_LOCAL = import.meta.env.VITE_AXIOS_API_URL_ROOT; //dev
const API_BASE_ROOT = import.meta.env.VITE_AXIOS_BASE_URL_ROOT;
// const NOTIFACTION_URL = `https://73803b04-480f-462e-9e9c-1bb3fd61ec98.mock.pstmn.io`
export const axiosGet = async (url, isBase = false, body) => {
  try {
    const tenantID = localStorage.getItem('tenantID');
    const token = localStorage.getItem('_t');
    const config = {
      headers: {
        authorization: 'Token ' + token,
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      ...(body && { data: body }),
    };
    if (url === 'user_subtenant_list') {
      return await axios.get(`${isBase ? API_BASE_ROOT : API_ROOT_LOCAL}/${url}`, config);
    }
    // if (url === "master_events"){
    // 	return await axios.get(`${isBase ? API_MOCK_ROOT : API_ROOT_LOCAL}/${url}/${tenantID}`, config);
    // }
    // if (url === 'notification_bellicon_list') {
    // 	return await axios.get(`${NOTIFACTION_URL}/${url}/${tenantID}`, config);
    // }
    return await axios.get(`${isBase ? API_BASE_ROOT : API_ROOT_LOCAL}/${url}/${tenantID}`, config);
  } catch (error) {
    console.error('HTTP GET request failed', error['message']);
  }
};
