import React, { useState, useEffect, useRef } from 'react';
import CheckBox from '@root/components/v2/ui-elements/CheckBox';
import { Box } from '@mui/material';
import './style.scss';
import _ from 'lodash';
import infoIcon from '@assets/svg/New_Severity_Icons/No.svg';
import lowIcon from '@assets/svg/New_Severity_Icons/Low.svg';
import mediumIcon from '@assets/svg/New_Severity_Icons/Medium.svg';
import highIcon from '@assets/svg/New_Severity_Icons/High.svg';
import criticalIcon from '@assets/svg/New_Severity_Icons/Critical.svg';
// import LowIcon from '@root/components/svg/lowIcon';
// import MediumIcon from '@root/components/svg/mediumIcon';
// import HighIcon from '@root/components/svg/high';
// import CriticalIcon from '@root/components/svg/criticalIcon';

const CustomDropdownOption = ({ option, index, length, onClick, selected, options, value }) => {
  const [open, setOpen] = useState(false);
  const [delayedOpen, setDelayedOpen] = useState(true);
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [selectedSubClildren, setSelectedSubClildren] = useState([]);
  const firstRender = useRef(true);

  useEffect(() => {
    setSelectedSubClildren([]);
  }, [selectedChildren]);

  useEffect(() => {
    if (!_.isEmpty(selectedChildren) && !selected) {
      onClick(option);
      if (firstRender.current) {
        firstRender.current = false;
      }
    }
    if (_.isEmpty(selectedChildren) && !firstRender.current) {
      onClick(option);
    }
  }, [selectedChildren, onClick, option, selected]);

  useEffect(() => {
    if (!selected && option.children) {
      setOpen(false);
      setSelectedChildren([]);
    }
  }, [selected, option.children]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setDelayedOpen(open);
      }, 200);
    } else {
      setDelayedOpen(open);
    }
  }, [open]);

  return (
    <>
      <Box
        className="CustomDropdown__Option"
        style={{
          borderTop: index === 0 ? 'none' : '1px solid var(--boder-line-light, #cbd5e0)',
        }}
        onClick={() => {
          if (option.value === 'Global') {
            if (!value.includes('Global')) {
              onClick(options.map(opt => opt.value)); // Select all
            }
          } else {
            // Handle individual selections
            if (value.includes('Global')) {
              onClick([option.value]);
            } else {
              let updatedValue = value.includes(option.value)
                ? value.filter(op => op !== option.value)
                : [...value, option.value];
              const allSelected = updatedValue.length === options.length - 1;
              onClick(allSelected ? [...updatedValue, 'Global'] : updatedValue);
            }
          }
          // if (value.some(op => op === option.value)) {
          //   onClick(value.filter(op => op !== option.value));
          // } else {
          //   onClick([...value, option.value]);
          // }
        }}>
        <div style={{ marginLeft: '2px' }}>{option.label}</div>
        <div style={{ flexGrow: '1' }} />

        <CheckBox checked={selected} />
        {option.children && (
          <Box
            className="CustomDropdown__OptionsArrow"
            style={{
              borderBottomRightRadius: index === length - 1 && !open ? '5px' : '0',
              borderTopRightRadius: index === 0 ? '5px' : '0',
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              style={{ transition: 'all 200ms', transform: open ? 'rotate(180deg)' : '' }}>
              <path
                d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                fill="#484D56"
              />
            </svg>
          </Box>
        )}
      </Box>
      {option.children && (
        <Box
          className="CustomDropdown__ChildOptions"
          style={{
            // height: open ? `${option.children.length * 43}px` : '0px',
            display: open ? 'block' : 'none',
            borderTop: delayedOpen ? '1px solid var(--boder-line-light, #cbd5e0)' : 'none',
          }}>
          {option.children ? (
            option.children.map((child, index) => {
              //custom locic for vulnerability
              if (option.value === 'Vulnerability') {
                return (
                  <>
                    <Box
                      className="CustomDropdown__Option"
                      style={{
                        borderTop: index === 0 ? 'none' : '1px solid var(--boder-line-light, #cbd5e0)',
                      }}
                      onClick={() => {
                        if (selectedChildren.some(op => op === child.name)) {
                          setSelectedChildren([]);
                        } else {
                          setSelectedChildren([child.name]);
                        }
                      }}>
                      <div style={{ marginLeft: '10px' }}>{child.name}</div>

                      <div style={{ flexGrow: '1' }} />
                      <CheckBox
                        checked={selectedChildren.some(op => op === child.name)}
                        style={{ paddingRight: '30px' }}
                      />
                      <Box
                        className="CustomDropdown__OptionsArrow"
                        style={{
                          borderBottomRightRadius: index === option.children.length - 1 && !open ? '5px' : '0',
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          style={{ transition: 'all 200ms', transform: open ? 'rotate(180deg)' : '' }}>
                          <path
                            d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                            fill="#484D56"
                          />
                        </svg>
                      </Box>
                    </Box>
                    {child.children && (
                      <Box
                        className="CustomDropdown__ChildOptions"
                        style={{
                          height: selectedChildren[0] === child.name ? `${child.children.length * 43}px` : '0px',
                          // display: selectedChildren[0] === child.name ? 'block' : 'none',
                          borderTop:
                            selectedChildren[0] === child.name ? '1px solid var(--boder-line-light, #cbd5e0)' : 'none',
                        }}>
                        {child.children &&
                          child.children.map(subchild => (
                            <Box
                              className="CustomDropdown__ChildOption"
                              onClick={() => {
                                if (selectedSubClildren.some(op => op === subchild)) {
                                  setSelectedSubClildren(prev => prev.filter(op => op !== subchild));
                                } else {
                                  setSelectedSubClildren(prev => [...prev, subchild]);
                                }
                              }}
                              style={{ height: '42px' }}>
                              <div style={{ paddingRight: '7px' }}>
                                {subchild === 'Exploit' ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none">
                                    <circle
                                      cx="9.47368"
                                      cy="9.47368"
                                      r="9.1579"
                                      fill="#E2E8F0"
                                      stroke="#ED5656"
                                      stroke-width="0.631579"
                                    />
                                    <path
                                      d="M14.2094 9.72198L14.5271 9.15054L13.3409 8.44699L11.2873 8.50618L13.6028 7.03153L13.6075 4.42312L12.9695 4.42188L12.9655 6.66328L10.973 7.93217C11.0291 7.76521 11.0599 7.58614 11.0599 7.39975C11.0599 6.50052 10.3516 5.76894 9.48088 5.76894C8.61019 5.76894 7.90184 6.50052 7.90184 7.39975C7.90184 7.58997 7.93385 7.77255 7.99207 7.94243L5.98359 6.66326L5.97953 4.42188L5.34149 4.42314L5.34621 7.03156L7.6617 8.5062L5.60808 8.44701L4.42188 9.15056L4.73964 9.722L5.76996 9.11089L7.82084 9.17L4.81409 10.9205L4.82237 14.5271L5.46041 14.5255L5.45299 11.305L7.10018 10.3459C6.98044 10.652 6.91436 10.9861 6.91436 11.3359C6.91436 12.7974 8.06569 13.9865 9.48084 13.9865C10.896 13.9865 12.0473 12.7974 12.0473 11.3359C12.0473 10.9691 11.9748 10.6195 11.8438 10.3015L13.496 11.3014L13.4886 14.5256L14.1266 14.5271L14.1349 10.9242L11.231 9.16703L13.1791 9.11087L14.2094 9.72198Z"
                                      fill="#484D56"
                                    />
                                  </svg>
                                ) : subchild === 'Publicly Exposed' ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none">
                                    <circle
                                      cx="9.47368"
                                      cy="9.47368"
                                      r="9.1579"
                                      fill="#E2E8F0"
                                      stroke="#ED5656"
                                      stroke-width="0.631579"
                                    />
                                    <path
                                      d="M10.9097 11.6556C10.8699 11.6075 10.8105 11.5798 10.7479 11.5798H8.20107C8.13856 11.5798 8.07915 11.6075 8.03926 11.6556C7.99918 11.7037 7.98272 11.7671 7.99403 11.8285C8.29667 13.4679 8.87788 14.5271 9.47451 14.5271C10.0711 14.5271 10.6523 13.4679 10.955 11.8285C10.9663 11.7671 10.9498 11.7037 10.9097 11.6556Z"
                                      fill="#484D56"
                                    />
                                    <path
                                      d="M14.287 7.93667C14.2592 7.84949 14.1782 7.79028 14.0866 7.79028H11.7159C11.6565 7.79028 11.5997 7.81537 11.5598 7.85956C11.5198 7.90377 11.5004 7.96276 11.5064 8.02197C11.5551 8.50553 11.5798 8.99443 11.5798 9.47449C11.5798 9.95455 11.5551 10.4434 11.5064 10.927C11.5004 10.9862 11.5198 11.0452 11.5598 11.0894C11.5997 11.1336 11.6565 11.1587 11.7159 11.1587H14.0866C14.1783 11.1587 14.2593 11.0995 14.287 11.0123C14.4463 10.5134 14.5271 9.99609 14.5271 9.47451C14.5271 8.95292 14.4463 8.43566 14.287 7.93667Z"
                                      fill="#484D56"
                                    />
                                    <path
                                      d="M11.3947 7.19368C11.4118 7.29503 11.4996 7.36926 11.6024 7.36926H13.7274C13.8016 7.36926 13.8705 7.32998 13.9085 7.26625C13.9464 7.2023 13.9478 7.12316 13.9122 7.05799C13.2802 5.89987 12.1945 5.01769 10.933 4.63776C10.8518 4.61267 10.7623 4.64042 10.7083 4.70725C10.6546 4.77428 10.6468 4.867 10.6885 4.94182C10.9953 5.49157 11.2395 6.27015 11.3947 7.19368Z"
                                      fill="#484D56"
                                    />
                                    <path
                                      d="M10.8703 7.7903H8.07874C7.97121 7.7903 7.88095 7.8713 7.86945 7.97822C7.81703 8.46424 7.7903 8.96772 7.7903 9.47453C7.7903 9.98133 7.81703 10.4848 7.86945 10.9708C7.88095 11.0778 7.97121 11.1588 8.07874 11.1588H10.8703C10.9778 11.1588 11.0681 11.0778 11.0796 10.9708C11.132 10.4848 11.1587 9.98133 11.1587 9.47453C11.1587 8.96772 11.132 8.46424 11.0796 7.97822C11.0681 7.8713 10.9778 7.7903 10.8703 7.7903Z"
                                      fill="#484D56"
                                    />
                                    <path
                                      d="M5.22163 7.36924H7.34664C7.44943 7.36924 7.53722 7.29503 7.55429 7.19366C7.70952 6.27013 7.95376 5.49155 8.26049 4.9418C8.30224 4.86696 8.29442 4.77424 8.24076 4.70723C8.18689 4.64062 8.09786 4.61245 8.01605 4.63774C6.75453 5.01769 5.66879 5.89987 5.0368 7.05799C5.00123 7.12316 5.00267 7.20232 5.04051 7.26625C5.07854 7.32998 5.1474 7.36924 5.22163 7.36924Z"
                                      fill="#484D56"
                                    />
                                    <path
                                      d="M7.38919 11.0894C7.42928 11.0452 7.4486 10.9862 7.44264 10.927C7.39391 10.4435 7.36924 9.95457 7.36924 9.47451C7.36924 8.99445 7.39391 8.50555 7.44264 8.02199C7.4486 7.96278 7.42928 7.90377 7.38919 7.85958C7.3493 7.81537 7.29256 7.7903 7.23315 7.7903H4.86246C4.77076 7.7903 4.68976 7.84951 4.66201 7.93669C4.50268 8.43566 4.42188 8.95292 4.42188 9.47451C4.42188 9.99609 4.50268 10.5134 4.66201 11.0123C4.68976 11.0995 4.77076 11.1587 4.86246 11.1587H7.23315C7.29256 11.1587 7.3493 11.1336 7.38919 11.0894Z"
                                      fill="#484D56"
                                    />
                                    <path
                                      d="M7.55427 11.7554C7.5372 11.654 7.44941 11.5798 7.34662 11.5798H5.22163C5.14742 11.5798 5.07854 11.6191 5.04051 11.6828C5.00267 11.7467 5.00123 11.8259 5.0368 11.891C5.66879 13.0491 6.75451 13.9313 8.01603 14.3113C8.03597 14.3175 8.05634 14.3203 8.07668 14.3203C8.13939 14.3203 8.20004 14.2922 8.24076 14.2418C8.29442 14.1748 8.30224 14.082 8.26049 14.0072C7.95374 13.4574 7.7095 12.6789 7.55427 11.7554Z"
                                      fill="#484D56"
                                    />
                                    <path
                                      d="M13.7274 11.5798H11.6024C11.4996 11.5798 11.4118 11.654 11.3947 11.7554C11.2395 12.6789 10.9953 13.4575 10.6885 14.0072C10.6468 14.0821 10.6546 14.1748 10.7083 14.2418C10.749 14.2922 10.8096 14.3203 10.8723 14.3203C10.8925 14.3203 10.9131 14.3174 10.933 14.3113C12.1945 13.9313 13.2802 13.0491 13.9122 11.891C13.9478 11.8259 13.9464 11.7467 13.9085 11.6828C13.8705 11.619 13.8016 11.5798 13.7274 11.5798Z"
                                      fill="#484D56"
                                    />
                                    <path
                                      d="M8.03926 7.29339C8.07915 7.34151 8.13856 7.36926 8.20107 7.36926H10.7479C10.8105 7.36926 10.8699 7.34151 10.9097 7.29339C10.9498 7.24527 10.9663 7.18195 10.955 7.12049C10.6523 5.48109 10.0711 4.42188 9.47451 4.42188C8.87788 4.42188 8.29667 5.48109 7.99405 7.12047C7.98274 7.18195 7.99918 7.24527 8.03926 7.29339Z"
                                      fill="#484D56"
                                    />
                                  </svg>
                                ) : subchild === 'Info' ? (
                                  <img src={infoIcon} />
                                ) : subchild === 'Low' ? (
                                  // <LowIcon />
                                  <img src={lowIcon} />
                                ) : subchild === 'Medium' ? (
                                  // <MediumIcon />
                                  <img src={mediumIcon} style={{ width: '88px' }} />
                                ) : subchild === 'High' ? (
                                  // <HighIcon />
                                  <img src={highIcon} />
                                ) : subchild === 'Critical' ? (
                                  // <CriticalIcon />
                                  <img src={criticalIcon} />
                                ) : null}
                              </div>
                              <div>{subchild}</div>
                              <div style={{ flexGrow: '1' }} />
                              <CheckBox checked={selectedSubClildren.some(op => op === subchild)} />
                            </Box>
                          ))}
                      </Box>
                    )}
                  </>
                );
              }

              //default case
              return (
                <Box
                  className="CustomDropdown__ChildOption"
                  onClick={() => {
                    if (selectedChildren.some(op => op === child)) {
                      setSelectedChildren(prev => prev.filter(op => op !== child));
                    } else {
                      setSelectedChildren(prev => [...prev, child]);
                    }
                  }}>
                  <div>{child}</div>
                  <div style={{ flexGrow: '1' }} />
                  <CheckBox checked={selectedChildren.some(op => op === child)} />
                </Box>
              );
            })
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
};

export default CustomDropdownOption;
