window.global ||= window;
import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
const superagent = superagentPromise(_superagent, window.global.Promise);
import environments from './hooks/environments';
import axios from 'axios';
import _ from 'lodash';

const API_BASE_ROOT = import.meta.env.VITE_AXIOS_BASE_URL_ROOT;
// const API_ROOT_LOCAL = `http://127.0.0.1:8000/api`;
// const API_ROOT_LOCAL = `https://y819qrqotb.execute-api.ap-south-1.amazonaws.com/v1/api`; //dev
const API_ROOT_LOCAL = import.meta.env.VITE_AGENT_API_URL_ROOT; //dev
// const MOCK_API_ROOT_LOCAL = import.meta.env.VITE_APP_AXIOS_BASE_URL_MOCK_ROOT;
//dev
// const API_ROOT_LOCAL = 'http://13.232.179.137:8000/api';  //QA
//const API_ROOT_LIVE = 'http://35.198.206.186:8000/api';
let tenantID = window.localStorage.getItem('tenantID');
const API_ROOT = API_ROOT_LOCAL;
//const encode = encodeURIComponent;
const responseBody = res => res.body;

// let token = null;
let token = window.localStorage.getItem('_t');
const rToken = window.localStorage.getItem('_r');
const tokenPlugin = req => {
  if (token) {
    req.set(`authorization`, `Token ${token}`);
  }
};
window.addEventListener('storage', event => {
  if (event.key === '_t') {
    token = event.newValue; // Update the token in all tabs when changed in any one tab
  }
});
// https://ee132e51-0d16-4285-97b3-9e83446cb717.mock.pstmn.io
// for cloud events web socket => ws://127.0.0.1:8000/ws/cloud_events_update/' + tenant_id + "/
const handleError = err => {
  if (err?.status === 403 || err?.status === 401) {
    const parsedError = JSON.parse(err.response.text);

    const tokenExpired =
      parsedError?.user?.detail === 'Invalid authentication. Could not decode token.' ||
      parsedError?.detail === 'Invalid authentication. Could not decode token.';
    if (tokenExpired) {
      (async function () {
        const config = {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        };

        const res = await axios.post(`${API_BASE_ROOT}/refreshtoken`, { data: { refresh_token: rToken } }, config);
        if (res.data.access_token && !_.isEmpty(res.data?.access_token)) {
          token = res.data?.access_token;
          environments.setToken(token);
          window.localStorage.setItem('_t', token);
        } else if (res.data.error === 1) {
          window.localStorage.removeItem('_t');
          window.localStorage.removeItem('_r');
          window.localStorage.removeItem('tenantID');
          window.localStorage.removeItem('tenantName');
          window.open('/login');
        }
      })();
    }
    console.error('403 Error:', err.response ? err.response.text : 'No response body');
  }
  return Promise.reject(err);
};
const cloud_events = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};

const rules = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};

const compliance_pack = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};

const vulnerabilities = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const risk = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const overview = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const inventory_resources_card = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const key_insights = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const edit_enviroment = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};

const dashboard_new = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const inventory = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const environment_monitoring = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const reports = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const policy = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const malware = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const data_security = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const jira_notifications = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};
const kubernetes = {
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}/v1/kubernetes${url}/${tenantID}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(handleError),
};

const data_classification = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
};

const requests = {
  del: url => superagent.del(`${API_ROOT}${url}/${tenantID}`).use(tokenPlugin).then(responseBody).catch(handleError),
  get: url => superagent.get(`${API_ROOT}${url}/${tenantID}`).use(tokenPlugin).then(responseBody).catch(handleError),
  withoutTenantIDGet: url => superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody).catch(handleError),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
  withoutTenantIDPut: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}/${tenantID}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
  withoutTenantIDPost: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody).catch(handleError),
  postWithQueryParams: (url, body, query) =>
    superagent
      .post(`${API_ROOT}${url}/${tenantID}?${query}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(handleError),
};

// const API_ROOT_2 = import.meta.env.VITE_API_OCR_ROOT;
// const requests2 = {
//   del: url => superagent.del(`${API_ROOT_2}${url}/${tenantID}`).then(responseBody),
//   get: url => superagent.get(`${API_ROOT_2}${url}/${tenantID}`).then(responseBody),
//   withoutTenantIDGet: url => superagent.get(`${API_ROOT_2}${url}`).then(responseBody),
//   put: (url, body) => superagent.put(`${API_ROOT_2}${url}/${tenantID}`, body).then(responseBody),
//   withoutTenantIDPut: (url, body) => superagent.put(`${API_ROOT_2}${url}`, body).then(responseBody),
//   post: (url, body) => superagent.post(`${API_ROOT_2}${url}/${tenantID}`, body).then(responseBody),
//   withoutTenantIDPost: (url, body) => superagent.post(`${API_ROOT_2}${url}`, body).then(responseBody),
// };

const Auth = {
  current: token => requests.withoutTenantIDGet(`/user/${token}`),
  getLoginCode: (email, password) => requests.withoutTenantIDPost(`/login`, { user: { email, password } }),
  login: (email, password, verifycode) =>
    requests.withoutTenantIDPost(`/login`, { user: { email, password, verifycode } }),
  refreshToken: refresh_token => requests.withoutTenantIDPost(`/refreshtoken`, { data: { refresh_token } }),
  chanagePassword: (username, old_password, new_password, c_password) =>
    requests.withoutTenantIDPost(`/changepassword`, {
      data: { username, old_password, new_password, c_password },
    }),
  forgotPassword: email => requests.withoutTenantIDPost(`/forgotpassword`, { data: { email } }),
  setPassword: (query, password, c_password) =>
    requests.withoutTenantIDPut(`/forgotpassword`, { data: { query, password, c_password } }),
  logout: data => requests.withoutTenantIDPost(`/logout`, { data }),
  // clientLoginToken: (email, password) =>
  // 	requests.post(`/login`, { user: { email, password } }),
  // clientLogin: (email, password, verifycode) =>
  // 	requests.post(`/login`, { user: { email, password, verifycode } }),
  // save: (user) => requests.put(`/user`, { user }),
  registerGcp: ({ provider, orderId, hasToken, signupInfo }) =>
    requests.withoutTenantIDPost(`/gcp/signup`, { provider, orderId, hasToken, signupInfo }),
  registerAws: ({ orgToken, signupInfo }) => requests.withoutTenantIDPost(`/aws/signup`, { orgToken, signupInfo }),
};

const Misc = {
  get_regions: ({ environmentTags, timeFilter, environmentIds }) =>
    requests.post(`/cloudsecurity/cspm/list_regions`, {
      data: {
        filters: {
          scope: environmentTags,
          assessment_time_filter: timeFilter,
          environment_id: environmentIds,
        },
      },
    }),
};
const Kubernetes = {
  get_cluster_list: data => kubernetes.post('/list-clusters', data),
  get_cluster_top_row_data: data => kubernetes.post('/dashboard/top-row-cards', data),
  get_recent_findings_data: data => kubernetes.post('/dashboard/findings', data),
  get_identities_data: data => kubernetes.post('/dashboard/identities', data),
  get_identity_overview: data => kubernetes.post('/identity/overview', data),
  get_identity_risk_data: data => kubernetes.post('/identity/risk-breakup', data),
  get_identity_type_data: data => kubernetes.post('/identity/type-breakup', data),
  get_kubernetes_identity_table: data => kubernetes.post('/identity/resource-table', data),
};

const Cspa = {
  newassessment: (action = ``) =>
    requests.post(`/cloudsecurity/cspm/new_assessment`, {
      data: { action },
    }),
  assessmentdetails: (action = ``) =>
    requests.post(`/cloudsecurity/cspm/assessment_details`, {
      data: { action },
    }),
  check_assessment: (assesment_name = ``) =>
    requests.post(`/cloudsecurity/cspm/checkassesment`, {
      data: { assesment_name },
    }),
  schedule_scan: (data = ``) => requests.post(`/cloudsecurity/cspm/schedule_scan`, { data: data }),
  accounts: (action = ``) => requests.post(`/cloudsecurity/cspm/accounts`, { data: { action } }),
  completed_assessment: (code = ``) =>
    requests.post(`/cloudsecurity/cspm/completed_assessment`, {
      data: { code },
    }),
  get_scan_history: data => requests.post(`/cloudsecurity/cspm/scanhistory1.1/scan-history`, { data }),
  scan_present: data => requests.post(`/cloudsecurity/cspm/scanhistory1.1/scan-present`, { data }),
  get_assessment_time_filter: data =>
    requests.post(`/cloudsecurity/cspm/scanhistory1.1/assessment_time_filter`, { data }),
  get_latest_scan_data: data => requests.post(`/cloudsecurity/cspm/scanhistory1.1/latest-scan`, { data }),
  scan_repot: data => requests.post(`/cloudsecurity/cspm/scanresult`, { data: data }),
  generateTemplate: data => requests.post(`/cloudsecurity/cspm/generatetemplate`, { data: data }),
  getcloudandcompliancelist: data => requests.post(`/cloudsecurity/cspm/compliancelist`, { data: data }),
  connect_account: data => requests.post(`/cloudsecurity/cspm/connectaccount`, { data: data }),
  list_account: data => requests.post(`/cloudsecurity/cspm/listaccount`, { data: data }),
  list_environments: data => requests.post(`/cloudsecurity/cspm/listenvironments`, { data: data }),
  update_account: data => requests.post(`/cloudsecurity/cspm/updateaccount`, { data: data }),
  delete_account: data => requests.post(`/cloudsecurity/cspm/deleteaccount`, { data: data }),
  get_date_account_creation: data => requests.post(`/cloudsecurity/cspm/getaccountdate`, { data: data }),
  generate_external_id: data => requests.post(`/cloudsecurity/cspm/getexternalid`, { data: data }),
  generate_teraform: data => requests.post(`/cloudsecurity/cspm/teraform`, { data: data }),
  unique_name: data => requests.post(`/cloudsecurity/cspm/verifyenv`, { data: data }),
  fetch_risk_remediation: data => requests.post(`/cloudsecurity/cspm/risk1.2/risk_remediation`, { data: data }),
  compliance_report: data => requests.post(`/cloudsecurity/cspm/allcompliance`, { data: data }),
  compliance_dashboard: data => requests.post(`/cloudsecurity/cspm/allnewcompliance`, { data: data }),
  compliance_breakdown: data => requests.post(`/cloudsecurity/cspm/individualcompliance`, { data: data }),
  scan_history_report: data => requests.post(`/cloudsecurity/cspm/scanhistory`, { data: data }),
  // API for Scan Reports
  history_report_by_id: data => requests.post(`/cloudsecurity/cspm/historybyid`, { data: data }),
  export_env_report_by_id: data => requests.post(`/cloudsecurity/cspm/env-report-export`, { data: data }),
  scan_report_resource_aws_config: data => requests.post(`/cloudsecurity/cspm/resourcedetail`, { data: data }),
  scan_report_resource_type_expand: data => requests.post(`/cloudsecurity/cspm/historybyresource`, { data: data }),
  scan_report_controls_expand: data => requests.post(`/cloudsecurity/cspm/historybycontrol`, { data: data }),
  // Remediation APIS
  remediation_steps_data: data => requests.post(`/cloudsecurity/cspm/stepsbyrule`, { data: data }),
  remediation_scan: data => requests.post(`/cloudsecurity/cspm/remediatenow`, { data: data }),
  remediation_scan_result: data => requests.post(`/cloudsecurity/cspm/remediationreport`, { data: data }),
  // API for Risk
  risk_report_table_data: data => requests.post(`/cloudsecurity/cspm/detailbyrisk`, { data: data }),
  risk_report_expand_data: data => requests.post(`/cloudsecurity/cspm/expandriskdata`, { data: data }),
  // Rules
  rules_table: data => requests.post(`/cloudsecurity/cspm/ruledata`, { data }),
  service_rules: data => requests.post(`/cloudsecurity/cspm/listrules`, { data }),
  // Configure-rules
  configure_whitelist: data => requests.post(`/cloudsecurity/cspm/manualrule`, { data }),
  configure_whitelist_submit: data => requests.post(`cloudsecurity/cspm/addmanualruledata`, { data }),
  exceptions: data => requests.post(`/cloudsecurity/cspm/ruleexceptions`, { data }),
  update_exceptions: data => requests.post(`/cloudsecurity/cspm/updateexceptions`, { data }),
  list_exceptions: data => requests.post(`/cloudsecurity/cspm/listexceptions`, { ...data }),
  revoke_exceptions: data => requests.post(`/cloudsecurity/cspm/revokeexception`, { data }),
  exceptions_risk: data => requests.post(`/cloudsecurity/cspm/exceptiondetails`, { data }),
  fetch_exceptions_by_resource: data => requests.post(`/cloudsecurity/cspm/exception-by-resource-table`, { data }),
  // Services
  services_table: data => requests.post(`/cloudsecurity/cspm/listservices`, { data }),
};
const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlugClient = client => Object.assign({}, client, { slug: undefined });
const Clients = {
  all: page => requests.get(`/clients?${limit(10, page)}`),
  del: slug => requests.del(`/clients/${slug}`),
  chunk: page => requests.get(`/clients?${limit(10, page)}`),
  get: slug => requests.get(`/clients/${slug}`),
  update: client => requests.put(`/clients/${client.slug}`, { client: omitSlugClient(client) }),
  create: client => requests.post(`/clients`, { client }),
};
const Phishing = {
  get_sp: () => requests.get(`/peopleawareness/sendingprofile`),
  get_template: () => requests.get(`/peopleawareness/templates`),
  get_landing: () => requests.get(`/peopleawareness/landingpages`),
  get_usergroups: data => requests.get(`/peopleawareness/usersandgroups`, { data: { data } }),
  get_clients: () => requests.get(`/getClients`),
  create_sp: (formdata, action, currentuser) =>
    requests.post(`/peopleawareness/sendingprofile`, {
      data: { formdata, action, currentuser },
    }),
  create_group: (currentuser, formadata, action) =>
    requests.post(`/peopleawareness/usersandgroups`, {
      data: { currentuser, formadata, action },
    }),
  create_campaign: (formdata, action, channel, currentuser) =>
    requests.post(`/peopleawareness/campaigns`, {
      data: { formdata, action, channel, currentuser },
    }),
  phised_users: data => requests.post(`/peopleawareness/phishedusers`, { data: { data } }),
  download_report: (formdata, action, channel) =>
    requests.post(`/peopleawareness/reports`, {
      data: { formdata, action, channel },
    }),
  create_rp: (formdata, action) =>
    requests.post(`/peopleawareness/reportingprofile`, {
      data: { formdata, action },
    }),
  last_15_campaigns: (data, currentuser) =>
    requests.post(`/peopleawareness/last15campaigndata`, {
      data: { data, currentuser },
    }),
  phished_userwise: data => requests.post(`/peopleawareness/phisheddatauserswise`, { data: { data } }),
  phished_groupwise: data => requests.post(`/peopleawareness/phisheddatagroupswise`, { data: { data } }),
  phished_user_maindashboard: data =>
    requests.post(`/peopleawareness/phisheddatamaindashboard`, {
      data: { data },
    }),
  phished_risk_posture_group_maindashboard: data =>
    requests.post(`/peopleawareness/phishedriskposturedatamaindashboard`, {
      data: { data },
    }),
  phished_users_last12months: data =>
    requests.post(`/peopleawareness/last12monthsphishedusers`, {
      data: { data },
    }),
  phished_groups_last12months: data =>
    requests.post(`/peopleawareness/last12monthsphishgroups`, {
      data: { data },
    }),
  userwise_last12months: (data, email) =>
    requests.post(`/peopleawareness/last12monthsuserwise`, {
      data: { data, email },
    }),
  groupwise_last12months: (data, name) =>
    requests.post(`/peopleawareness/last12monthsgroupwise`, {
      data: { data, name },
    }),
  template_data: (formdata, action, currentuser) =>
    requests.post(`/peopleawareness/templates`, {
      data: { formdata, action, currentuser },
    }),
  // update: client =>
  //   requests.put(`/clients/${client.slug}`, { client: omitSlugClient(client) }),
  // create: client =>
  //   requests.post('/clients', { client })
};
const ClientDashboard = {
  get_help: () => requests.post('/gitbook/signed-url'),
  get_dashboard: org => requests.get(`/dashboard?org=` + org),
  create_organization: formadata => requests.post(`/registration`, { data: formadata }),
  activate_user: formadata => requests.post(`/activate`, { data: formadata }),
  // chanage_password: (formadata) =>
  // 	requests.post(`/changepassword`, { data: formadata }),
  client_login: formadata => requests.post(`/login`, { data: formadata }),
  partner_login: api_key => requests.post(`/partner/login`, { data: { api_key: api_key } }),
};

const OCR = {
  get_policy_meta: () => requests.post(`/policy-meta`),
  get_policy_meta_for_single_rule: (ruleId, environmentId, scope_environment_id, resource_id, resource_type, regions) =>
    requests.post(`/policy-meta`, {
      Master_Rule_Id: ruleId,
      environment_id: environmentId,
      scope_environment_id: [scope_environment_id],
      resource_id: resource_id,
      resource_type: resource_type,
      regions: regions,
    }),
  get_resources: ({ environmentId, resourceType, scanId }) =>
    requests.post(`/fetch-resource`, { environmentId, resourceType, scanId }),
  create_policy: payload => requests.post(`/save-policy`, payload),
  update_policy: ({ id, payload, updatedBy }) => requests.post(`/update-policy`, { id, username: updatedBy, payload }),
  fetch_policy_by_policy_id: policyId =>
    requests.post('/fetch-policy', {
      data: {
        policy_id: policyId,

        date_filter: '',
        scope: ['Global'],
        all_resource: {
          resource_type: [],
          service: [],
          service_category: [],
        },
        cloud_provider: '',
        scope_environment_id: [],
        properties_filter: {
          rule_id: [],
          region: [],
          severity: [],
          match: 'any',
        },
        search_keyword: '',
        page_no: 1,
        row_per_page: 1000,
      },
    }),
  revoke_policy: (id, payload) =>
    requests.post(`/delete-policy`, {
      id,
      username: payload.created_username,
      user_email: payload.created_user_email,
    }),
  trigger_remediation: payload => requests.post('/remediate', payload),
  get_remediation: id => requests.post('/remediation-reports', { remediation_id: id }),
  fetch_remediations: payload => requests.post('/remediation-reports', payload),
  fetch_remediations_by_ids: remediation_ids =>
    requests.post('/remediation-reports', {
      data: {
        all_resource: {
          resource_type: [],
          service: [],
          service_category: [],
        },
        cloud_provider: localStorage.getItem('cp_for_remediation'),
        date_filter: '24 hours',
        environments: [],
        event_type: 'Remediation',
        remediation_id: remediation_ids,
        page_no: 1,
        properties_filter: {
          match: 'Any',
          rule_id: [],
          severity: [],
          status: [],
          trigger: [],
        },
        row_per_page: 10,
        scope: ['Global'],
        search_keyword: '',
        unique_id: '121',
      },
    }),
  fetch_policy_names: () => requests.post('/get-policy-name', {}),
};

const Environment = {
  fetch_environments_and_cloud_providers: () => requests.get('/cloudsecurity/cspm/fetchglobalfilter'),
};

const Environment_Monitoring = {
  get_monitoring_error_message: data =>
    environment_monitoring.post(`/cloudsecurity/cspm/monitoring-error-msg`, { data }),
};

const Identity = {
  get_compliance_control_info: () => requests.post(`/compliance-control-info`),
  get_compliance_control_info_for_account: (environmentIds, scanId, identityScanId) =>
    requests.post(`/compliance-control-info-per-account`, {
      account_id: environmentIds,
      scan_id: scanId,
      identity_scan_id: identityScanId,
    }),
  get_identity_iam_info: (environmentIds, scanId, identity_scan_id) =>
    requests.post(`/identity_iam_info`, {
      account_id: environmentIds,
      scan_id: scanId,
      identity_scan_id: identity_scan_id,
    }),
  get_identity_intelligence_identities: (environmentIds, scanId, identity_scan_id) =>
    requests.post(`/top_row_data`, { account_id: environmentIds, scan_id: scanId, identity_scan_id: identity_scan_id }),
  /**
   *
   * @param {*} environmentIds [123123, 234234, 345345]
   * @param {*} fromDate yyyy-mm-dd
   * @returns
   */
  get_identity_trend_overview: (environmentIds, period, timezone, scanId) =>
    requests.post('/dashboard_trend_info', {
      account_id: environmentIds,
      period,
      timezone,
      scan_id: scanId,
    }),
  get_identity_severity_breakup: (environmentIds, identity_scan_id, scanId) =>
    requests.post('/identity_severity_breakup', {
      environments: environmentIds,
      identity_scan_id: identity_scan_id,
      scan_id: scanId,
    }),

  get_identities_top_row_data: ({ environmentIds, type, scanId, identity_scan_id }) =>
    requests.post('/identities_top_row', {
      account_id: environmentIds,
      type,

      identity_scan_id: identity_scan_id,
      scan_id: scanId,
    }),
  get_identity_meta_data: ({ id, type, scan_id }) =>
    requests.post('/identity-metadata', {
      type,
      id,
      scan_id,
    }),
  /**
   *
   * @param {*} environmentIds
   * @param {*} type policies|users|roles|groups
   * @param {*} page
   * @param {*} page_size
   * @returns
   */
  get_identities_table_data: ({
    environmentIds,
    latestScanIds,
    filter,
    type,
    shield_severity,
    page,
    page_size,
    filter_operator,
    regex,
    scanId,
  }) =>
    requests.post('/identities_table_data_v2', {
      filters: { accountId: environmentIds, ...filter },
      identity_scan_id: latestScanIds,
      type,
      page,
      page_size,
      filter_operator,
      regex,
      scan_id: scanId,
      ...(shield_severity && { shield_severity: shield_severity }),
    }),

  get_identities_action_data: (id, scan_id, type) =>
    requests.post('/identities_action', {
      id,
      scan_id,
      type,
    }),

  get_identity_providers: (environmentIds, identity_scan_id, scanId, currentPage, pageSize) =>
    requests.post('/identity_providers', {
      environments: environmentIds,
      identity_scan_id: identity_scan_id,
      scan_id: scanId,
      page_no: currentPage,
      row_per_page: pageSize,
    }),

  get_optimal_policy: (type, cloud_provider) =>
    requests.post('/remediation_step', {
      type,
      cloud_provider,
    }),

  get_assessment_times: (environmentIds, dateFilter) => {
    return requests.post('/assessment_times', {
      environments: environmentIds,
      date_filter: dateFilter,
    });
  },
  get_vulnerable_assessment_times: data => {
    return requests.post('/cloudsecurity/cspm/vulnerable_assessment_times', data);
  },
  get_identity_anamoly_threats: (
    selectedEnvironmentTags,
    environmentIds,
    cloudProvider,
    selectedAssessmentTime,
    timeFilter,
  ) => {
    return requests.post(`/identity_anomaly_threat`, {
      scope: selectedEnvironmentTags,
      environments: environmentIds,
      cloud_provider: cloudProvider,
      assessment_time_filter: selectedAssessmentTime,
      date_filter: timeFilter,
    });
  },
  get_identity_attack_path: (
    selectedEnvironmentTags,
    environmentIds,
    cloudProvider,
    selectedAssessmentTime,
    timeFilter,
    scan_id,
    identity_scan_id,
  ) => {
    return requests.post(`/identity_attack_path`, {
      scope: selectedEnvironmentTags,
      environments: environmentIds,
      cloud_provider: cloudProvider,
      assessment_time_filter: selectedAssessmentTime,
      date_filter: timeFilter,
      scan_id: scan_id,
      identity_scan_id: identity_scan_id,
    });
  },
  get_identity_top_risk_identities: (
    selectedEnvironmentTags,
    environmentIds,
    cloudProvider,
    selectedAssessmentTime,
    timeFilter,
    scan_id,
    identity_scan_id,
  ) => {
    return requests.post(`/risk_identity_shield_priority`, {
      scope: selectedEnvironmentTags,
      environments: environmentIds,
      cloud_provider: cloudProvider,
      assessment_time_filter: selectedAssessmentTime,
      date_filter: timeFilter,
      scan_id: scan_id,
      identity_scan_id: identity_scan_id,
    });
  },
};

const Visualization = {
  /**
   *
   * @param {*} param0
   * @param {*} param0.accountIds string[]
   * @param {*} param0.nodeId string
   * @param {*} param0.page number starting from 1 (optional)
   * @param {*} param0.limit number (optional)
   * @returns
   */
  get_nodes: ({ accountIds, nodeId, filter, page, limit, search_key, show_category }) =>
    requests.post('/visualizer', {
      accountId: accountIds ? accountIds : [],
      id: nodeId !== undefined && nodeId !== null ? [nodeId] : [],
      // groupBy: true,
      filter: filter,
      search_key: search_key,
      show_category: show_category,
      // regions: ['global'],
      ...(page !== undefined && { offset: (page - 1) * limit }),
      ...(limit !== undefined && page !== undefined && { limit }),
    }),
};
const Visualization_Filter = {
  filter: data => requests.post(`/visualizer/filter`, data),
  get_query: () => requests.get(`/visualizer/queries/pre-defined`),
  get_node_data: data => requests.post(`/visualizer/queries/pre-defined`, data),
};

const AttackPathAnalysis = {
  get_data: ({ environmentIds, scanIds, regions, searchText, severity = null, currentPage, pageSize }) =>
    requests.post(`/cloudsecurity/cspm/main_dashboard1.1/active_attack_path`, {
      environments: environmentIds,
      scan_id: scanIds,
      properties_filter: {
        region: regions,
        match: 'all',
        resource_id: [],
        resource_type: [],
        severity: severity ? [severity] : [],
      },
      search_keyword: searchText,
      sort: '',
      // date_filter: timeFilter,
      date_filter: '',
      rows_per_page: pageSize,
      page_number: currentPage,
      export: false,
    }),

  get_side_overlay_data: ({
    account_id,
    scan_id,
    severity,
    rule_id,
    rule_name,
    resource_id,
    resource_type,
    region,
    attack_path_payload,
  }) =>
    requests.post(`/cloudsecurity/cspm/main_dashboard1.1/attack_path_side_overlay`, {
      account_id,
      scan_id,
      severity,
      rule_id,
      rule_name,
      resource_id,
      resource_type,
      region,
      attack_path_payload,
    }),

  get_attack_path_graph_data: ({ account_id, resource_id, region, scan_id, rule_id }) =>
    requests.post(`/visualizer/attack-path/graph`, {
      desc: rule_id,
      region,
      id: resource_id,
      account_id: account_id,
      scan_id,
    }),
};

const Integration = {
  slack: webhook_url => requests.post(`/alerts/config-channels`, { data: { webhook_url } }),
  channel_integration: data => requests.post(`/alerts/config-channels`, data),
  channelDetails: () => requests.post(`/alerts/config-channels`),
  updateChannelDetails: data => requests.post(`/alerts/config-channels`, data),
  slack_enable_disable: data => requests.post(`/alerts/config-channels`, { data }),
};
const notification_setting = {
  fetch_notifications: data => requests.post(`/alerts/fetch-notifications`, { data }),
};
const notification_logs = {
  fetch_notifications_logs: data => requests.post(`/alerts/get-notification-log`, data),
};
const Users = {
  get_users_list: ({ filters, currentPage, pageSize }) =>
    requests.postWithQueryParams(
      `/users_list`,
      { data: { filters: filters } },
      `page=${currentPage}&page_size=${pageSize}`,
    ),
  get_groups_list: ({ filters, currentPage, pageSize }) =>
    requests.postWithQueryParams(
      `/groups`,
      { data: { filters: filters } },
      `page=${currentPage}&page_size=${pageSize}`,
    ),
};
const Compliance = {
  get_average_score: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/average_score`, { data }),
  get_non_compliant_rules_by_severity: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/non_compliant_rules_by_severity`, { data }),
  get_resource_compliance: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/resource_compliance`, { data }),
  get_rule_compliance: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/rule_compliance`, { data }),
  get_resources_count: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/resources_count`, { data }),
  get_non_compliant_resources_graph: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/non_compliant_resources_graph`, { data }),
  get_resource_trend_graph: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/resources_trend_graph`, { data }),
  get_remediation_exception_graph: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/remediation_exception_graph`, { data }),
  get_over_time_graph: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/over_time_graph`, { data }),
  get_last_update: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/last_update`, { data }),
  get_service_category: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/service_category`, { data }),
  get_export: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/export`, { data }),
  get_compliance_by_framework: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/compliance_by_framework`, { data }),
  get_compliance_family_filter: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/compliance_family_filter`, { data }),
  get_control_test: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/control_test`, { data }),
  get_environment: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/environment`, { data }),
  get_table_data_filter: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/table_data_filter`, { data }),
  get_table_data: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/table_data`, { data }),
  get_expansion_data: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/expansion_data`, { data }),
  get_control_evaluation_count: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/control_evaluation_count`, { data }),
  get_export_table_data: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/export_data`, { data }),
  get_export_compliance_data: data => requests.post(`/cloudsecurity/cspm/compliance_v1.1/export`, { data }),
  get_assessment_time_filter: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/assessment_time_filter`, { data }),
};

const CloudEvents = {
  get_critical_threat_events: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/critical_threat_events`, data),
  get_more_critical_vulnerability: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/more_critical_vulnerability`, data),
  get_anomalous_cloudtrail: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/anomalous_cloudtrail`, data),
  get_anomalous_mitre: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/anomalous_mitre`, data),
  get_threat_by_severity: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/threats`, data),
  get_top_ten_failed_category: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/top_ten_failed_category`, data),
  get_top_ten_category: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/top_ten_category`, data),
  get_events: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/events`, data),
  get_top_ten_drift_events: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/top_ten_drift_events`, data),
  get_delete_events_overtime: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/top_ten_deleted_events_overtime`, data),
  get_events_config: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/events_config`, data),
  get_all_events_default_time_range_config: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/all_events_default_time_range`, data),
  get_list_events: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/list_events`, data),
  get_justified_events: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/get_justified_events`, data),
  get_list_events_threats: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/list_events_threats`, data),
  get_events_over_time: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/events_over_time`, data),
  get_remediation_over_time: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/remediation_over_time`, data),
  get_drift_events: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/drift_events`, data),
  get_events_type: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/events_type`, data),
  get_details_list_threats: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/events/details`, data),
  get_activity_list_threats: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.2/get_activity_details`, data),
  get_event_filter_property_keys: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/property_keys`, data),
  get_event_filter_property_values: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/property_values`, data),
  get_event_filter_service_category: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/event_filter_service_category`, data),
  get_event_filter_service_category_policies: data =>
    cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/event_filter_service_category_policies`, { data }),
  get_last_update: data => cloud_events.post(`/cloudsecurity/cspm/cloud_events_1.1/last_update`, data),
};

const Rules = {
  get_service_rule_mapping: data => rules.post(`/cloudsecurity/cspm/rules1.1/service_rule_mapping`, { data }),
  get_last_updated: data => rules.post(`/cloudsecurity/cspm/rules1.1/last_updated`, { data }),
  get_rules_table: data => rules.post(`/cloudsecurity/cspm/rules1.1/rules_table`, { data }),
  get_rules_table_expansion: data => rules.post(`/cloudsecurity/cspm/rules1.1/rules_table_expansion`, { data }),
  get_filter_property_values: data => rules.post(`/cloudsecurity/cspm/rules1.1/filter_property_values`, { data }),
  get_rule_type: data => rules.post(`/cloudsecurity/cspm/rules1.1/ruletype`, { data }),
  rule_drill_down: data => rules.post(`/cloudsecurity/cspm/rules1.1/rule_drill_down`, data),
  rulesbyseverity: data => rules.post(`/cloudsecurity/cspm/rules1.1/rulesbyseverity`, data),
  rulescoverage: data => rules.post(`/cloudsecurity/cspm/rules1.1/rulescoverage`, data),
};

const Vulnerabilites = {
  get_vulnerability_data: data => vulnerabilities.post(`/cloudsecurity/cspm/vulnerability-data`, data),
  get_filter_data: data => vulnerabilities.post(`/cloudsecurity/cspm/vulnerability-filter-suggestion`, data),
  get_resource_data: data => vulnerabilities.post(`/cloudsecurity/cspm/resource-data`, data),
  get_export_data: data => vulnerabilities.post(`/cloudsecurity/cspm/vulnerability-export`, data),
};

const CompliancePacks = {
  get_selected_compliances: data => compliance_pack.post(`/cloudsecurity/cspm/get_selected_compliance_packs`, { data }),
};
const Risk = {
  risk_overtime_cards_data: data => risk.post(`/cloudsecurity/cspm/risk1.2/risk_overtime_cards`, data),
  risk_overtime_main_data: data => risk.post(`/cloudsecurity/cspm/risk1.2/risk_overtime_main`, data),
  security_score: data => risk.post(`/cloudsecurity/cspm/security-score`, { data }),
  risk_resource_counts: data => risk.post(`/cloudsecurity/cspm/risk-resource-counts`, { data }),
  security_score_graph: data => risk.post(`/cloudsecurity/cspm/security-score-graph`, { data }),
  risk_alerts_severity: data => risk.post(`/cloudsecurity/cspm/risk-alerts-severity`, { data }),
  risk_category_tdata: data => risk.post(`/cloudsecurity/cspm/risk1.2/risk_category_tdata`, data),
  risk_expansion: data => risk.post(`/cloudsecurity/cspm/risk1.2/risk_expansion`, data),
  last_update: data => risk.post(`/cloudsecurity/cspm/risk1.2/last_update`, data),
  risk_category_keys: data => risk.post(`/cloudsecurity/cspm/risk1.2/risk_category_keys`, { data }),
  risk_category_keys_values: data => risk.post(`/cloudsecurity/cspm/risk1.2/risk_category_keys_values`, data),
  export_risk_category_tdata: data => risk.post(`/cloudsecurity/cspm/risk1.2/export_risk_category_tdata`, { data }),
};

const Dashboard_New = {
  get_security_score: data => dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/security_score`, { data }),
  get_risk_trend: data => dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/risk_trend`, { data }),
  get_compliance: data => dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/compliance`, { data }),
  get_threats: data => dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/threats`, { data }),
  get_vulnerabilities: data => dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/vulnerabilities`, { data }),
  get_compliance_posture: data =>
    dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/compliance_posture`, { data }),
  get_internet_exposed_resources: data =>
    dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/internet_exposed_resources`, { data }),
  get_top_3_risk_recommendations: data =>
    dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/top_3_risk_recommendations`, { data }),
  get_inventory_card: ({ scope, environments, cloud_provider, regions, assessment_time_filter, date_filter }) =>
    dashboard_new.post('/cloudsecurity/cspm/main_dashboard1.1/inventorycard', {
      data: {
        scope,
        environments,
        cloud_provider,
        regions,
        assessment_time_filter,
        date_filter,
      },
    }),
  get_toptriggered_data: ({
    scope,
    environments,
    rule_type,
    detection_type,
    cloud_provider,
    assessment_time_filter,
    date_filter,
  }) =>
    dashboard_new.post('/cloudsecurity/cspm/main_dashboard1.1/triggeredentitiescard', {
      scope,
      environments,
      rule_type,
      detection_type,
      cloud_provider,
      assessment_time_filter,
      date_filter,
    }),
  get_threats_graph_data: ({ scope, environments, cloud_provider, date_filter }) =>
    dashboard_new.post('/cloudsecurity/cspm/main_dashboard1.1/threatanomalygraph', {
      data: {
        scope,
        environments,
        cloud_provider,
        date_filter,
      },
    }),
  get_identity_security_posture: data => dashboard_new.post(`/identity_dashboard_cards`, data),
  get_regions: data => dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/regions`, { data }),
  get_resource_trend: data => dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/resource_trend`, { data }),
  get_last_updated: data => dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/last_updated`, { data }),
  get_cq_shield_priority: ({ scope, environments, cloud_provider, date_filter, timezone, assessment_time_filter }) =>
    dashboard_new.post('/cloudsecurity/cspm/main_dashboard1.2/cq-shield-priority', {
      data: { scope, environments, cloud_provider, date_filter, timezone, assessment_time_filter },
    }),
  get_open_threat_anamoly: data => dashboard_new.post('/cloudsecurity/cspm/cloud_events_1.2/threats', data),
  get_prioritization_funnel: data => dashboard_new.post(`/cloudsecurity/cspm/main_dashboard1.1/pfunnel`, data),
};
const Inventory_Resources = {
  inventory_resources_list: data => inventory.post(`/cloudsecurity/cspm/inventory_resources_list`, data),
  get_internet_exposed_path: resourceId => requests.post(`/cloudsecurity/cspm/internet-expose-path`, { resourceId }),
  get_assessment_time_filter: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/assessment_time_filter`, { data }),
};
// remediation
const Reports = {
  get_reports_property_values: data => reports.post(`/remediation_property_values`, { data }),
  get_table_data: data => reports.post(`/remediation-reports`, { data }),
  remediation_config: data => reports.post(`/remediation-config`, { data }),
};
const Policy = {
  get_policy_service_category: data => policy.post(`/event-filter-service-category-policies`, { data }),
  get_policy_property_values: data => policy.post(`/fetch-policy-values`, { data }),
  get_policy_property_keys: data => policy.post(`/fetch-policy-keys`, { data }),
  get_table_data: data => policy.post(`/fetch-policy`, { data }),
};
const Inventory_Overview = {
  cloud_provider: data => overview.post(`/cloudsecurity/cspm/inventory/cloud_provider`, { data }),
  top_service_category: data => overview.post(`/cloudsecurity/cspm/inventory/top_service_category`, { data }),
  resource_trend: data => overview.post(`/cloudsecurity/cspm/inventory/resource_trend`, { data }),
  health_score_breakdown: data => overview.post(`/cloudsecurity/cspm/inventory/health_score_breakdown`, { data }),
  resource_compliance: data => overview.post(`/cloudsecurity/cspm/inventory/resource_compliance`, { data }),
  identity_risk: data => overview.post(`/cloudsecurity/cspm/inventory/identity_risk`, { data }),
  internet_exposed_resources: data =>
    overview.post(`/cloudsecurity/cspm/inventory/internet_exposed_resources`, { data }),
  alerts_by_severity: data => overview.post(`/cloudsecurity/cspm/inventory/alerts_by_severity`, data),
  resource_compliance_trends: data =>
    overview.post(`/cloudsecurity/cspm/inventory/resource_compliance_trends`, { data }),
  top_non_complianct_resources: data =>
    overview.post(`/cloudsecurity/cspm/inventory/top_non_complianct_resources`, { data }),
  last_updated: data => overview.post(`/cloudsecurity/cspm/inventory/last_updated`, { data }),
  // get_assessment_time_filter: data =>
  //   requests.post(`/cloudsecurity/cspm/compliance_v1.1/assessment_time_filter`, { data }),
};
const Inventory_Resources_Card = {
  all_resource_page: data => inventory_resources_card.post(`/cloudsecurity/cspm/all-resource-page`, { data }),
};
const Key_Insights = {
  serverless_functions: data => key_insights.post(`/cloudsecurity/cspm/inventory/serverless_functions`, data),
  cloud_storage: data => key_insights.post(`/cloudsecurity/cspm/inventory/cloud_storage`, data),
  get_assessment_time_filters: data =>
    requests.post(`/cloudsecurity/cspm/compliance_v1.1/assessment_time_filter`, { data }),
};
const Edit_Enviroment = {
  get_environment_data: data => edit_enviroment.post(`/cloudsecurity/cspm/environment-data`, { data }),
  get_environment_update_command: data =>
    edit_enviroment.post(`/cloudsecurity/cspm/environment-update-command`, { data }),
  generate_command: data => edit_enviroment.post(`/cloudsecurity/cspm/generate-command`, { data }),
  update_environment: data => edit_enviroment.post(`/cloudsecurity/cspm/update-environment`, { data }),
  check_subscribed_modules: data => edit_enviroment.post(`/cloudsecurity/cspm/check_subscribed_modules`, data),
  update_eks_existing_resources: data => edit_enviroment.post(`/cloudsecurity/cspm/eks_existing_resources`, { data }),
  get_eks_existing_resources: account_id =>
    edit_enviroment.post(`/cloudsecurity/cspm/eks_existing_resources`, { data: { account_id: account_id } }),
};

const Malware = {
  get_last_update: data => malware.post(`/cloudsecurity/cspm/malware/last-update`, { data }),
  get_kpi_data: data => malware.post(`/cloudsecurity/cspm/malware/kpi-data`, { data }),
  get_problem_file_data: data => malware.post(`/cloudsecurity/cspm/malware/problem-files`, { data }),
  get_scan_info: data => malware.post(`/cloudsecurity/cspm/malware/scan-info`, { data }),
  get_bucket_protection_status: data => malware.post(`/cloudsecurity/cspm/malware/bucket-protection-status`, { data }),
  get_active_bucket: data => malware.post(`/cloudsecurity/cspm/malware/active-buckets`, { data }),
  get_regions_filter_data: data => malware.post(`/cloudsecurity/cspm/malware/regions-filter-data`, { data }),
  get_finding_table_data: data => malware.post(`/cloudsecurity/cspm/malware/finding-table-data`, { data }),
  get_bucket_protection_table_data: data =>
    malware.post(`/cloudsecurity/cspm/malware/bucket-protection-table-data`, { data }),
  get_finding_property_filter: data => malware.post(`/cloudsecurity/cspm/malware/finding-property-filter`, { data }),
  get_bucket_protection_property_filter: data =>
    malware.post(`/cloudsecurity/cspm/malware/bucket-protection-property-filter`, { data }),
  get_secrets_overview: data => malware.post(`/cloudsecurity/cspm/malware/secrets-overview`, { data }),
  get_data_classfication: data => malware.post(`/cloudsecurity/cspm/malware/finding/data-classification`, { data }),
};

const DataSecurity = {
  get_pii_files: data => data_security.post(`/cloudsecurity/cspm/malware/pii-files`, data),
  get_live_secrets: data => data_security.post(`/cloudsecurity/cspm/malware/live-secrets`, { data }),
  get_top_active_actor: data => data_security.post('/cloudsecurity/cspm/malware/top-active-actors', { data }),
  get_asset_overview: data => data_security.post('/cloudsecurity/cspm/malware/asset-overview', { data }),
  get_alert_data: data => data_security.post('/cloudsecurity/cspm/malware/alert_api', data),
  get_data_distribution: data => data_security.post('/cloudsecurity/cspm/malware/data-distribution', { data }),
  get_sensitive_data: data => data_security.post('/cloudsecurity/cspm/malware/sensitive-data', { data }),
  get_attack_path_distribution_data: data =>
    data_security.post('/cloudsecurity/cspm/malware/attack-path-distribution', data),
  get_top_five_attack_path: data => data_security.post('/cloudsecurity/cspm/malware/top-five-attack-path', data),
  get_data_security_table_data: data => data_security.post('/cloudsecurity/cspm/malware/resource-details', data),
  get_data_security_table_filter_data: data =>
    data_security.post('/cloudsecurity/cspm/malware/resource-details-property-filter', data),
};

const Jira_Notification = {
  get_last_update: data => jira_notifications.post(`/cloudsecurity/cspm/jira/last-update`, { data }),
  get_table_data_jira: data => jira_notifications.post(`/jira/dashboard_data`, data),
  get_org_id: data => jira_notifications.post(`/jira/org_id`, data),
  get_project_statuses: data => jira_notifications.post(`/jira/project-statuses`, data),
  get_project_info: data => jira_notifications.post(`/jira/project_info`, data),
  dashboard_ticket_update_info: data => jira_notifications.post(`/jira/dashboard_ticket_update_info`, data),
  get_filter_issues: data => jira_notifications.post(`/jira/filter-issues`, data),
};
const Data_Classification = {
  get_side_overlay_details: data =>
    data_classification.post('/cloudsecurity/cspm/inventory/side_overlay/details', data),
  get_side_overlay_alerts: data => data_classification.post('/cloudsecurity/cspm/inventory/side_overlay/alerts', data),
  get_side_overlay_misconfigurations: data =>
    data_classification.post('/cloudsecurity/cspm/inventory/side_overlay/misconfigurations', data),
  get_side_overlay_risks: data => data_classification.post('/cloudsecurity/cspm/inventory/side_overlay/risks', data),
  get_side_overlay_content: data =>
    data_classification.post('/cloudsecurity/cspm/inventory/side_overlay/contents', data),
  get_side_overlay_events: data => data_classification.post('/cloudsecurity/cspm/inventory/side_overlay/events', data),
  get_side_overlay_entity_type: data =>
    data_classification.post('/cloudsecurity/cspm/inventory/side_overlay/entity_type', data),
  get_side_overlay_active_identities: data =>
    data_classification.post('/cloudsecurity/cspm/inventory/side_overlay/active_identities', data),
  get_inventory_resources_list_side_overlay: data =>
    data_classification.post('/cloudsecurity/cspm/inventory_resources_list_side_overlay', data),
  get_resource_configuration: data => data_classification.post('/cloudsecurity/cspm/resource_configuration', data),
  get_secrets: data => data_classification.post('/cloudsecurity/cspm/inventory/side_overlay/secrets', data),
};

const Account = {
  get_groups: () => requests.post('/user_group_info'),
  validate_login_credentials: ({ apiUrl, email, password }) =>
    requests.post('/validate-credentials', {
      apiUrl,
      email,
      password,
    }),
  /**
   *
   * @param {*} param.accountId The environment id
   * @returns
   */
  get_shield_report_settings: ({ accountId }) =>
    requests.post('/save-report-info', {
      accountId,
    }),
  /**
   *
   * @param {*} param Object param
   * @param {*} param._ls localStorage content as object
   * @param {*} param.apiUrl The API base url
   * @param {*} param.baseUrl The base url of the dashboard
   * @param {*} param.accountId The environment id
   * @param {*} param.orgInfo Ignore for now
   * @param {*} param.timeRange 7d, 30d, 90d ,365d
   * @param {*} param.loginInfo { "email": LOGIN_EMAIL, "password": LOGIN_PASSWORD }
   * @param {*} param.mainInfo { "to": [EMAIL_ADDRESSES], "cc": [], "bcc": [] }
   * @param {*} param.customInfo { envName, primaryTag, provider }
   * @param {*} param.createdBy { name: string; email: string }
   * @returns
   */
  save_shield_report_settings: ({
    _ls,
    apiUrl,
    baseUrl,
    accountId,
    orgInfo,
    timeRange,
    loginInfo,
    mailInfo,
    customInfo,
    createdBy,
    isEnabled,
  }) =>
    requests.post('/save-report-info', {
      _ls,
      apiUrl,
      baseUrl,
      accountId,
      orgInfo,
      timeRange,
      loginInfo,
      mailInfo,
      customInfo,
      createdBy,
      isEnable: isEnabled,
    }),

  /**
   *
   * @param {*} param Object param
   * @param {*} param._ls localStorage content as object
   * @param {*} param.apiUrl The API base url
   * @param {*} param.baseUrl The base url of the dashboard
   * @param {*} param.accountId The environment id
   * @param {*} param.orgInfo Ignore for now
   * @param {*} param.timeRange 7d, 30d, 90d, 365d
   * @param {*} param.loginInfo { "email": LOGIN_EMAIL, "password": LOGIN_PASSWORD }
   * @param {*} param.mainInfo { "to": [EMAIL_ADDRESSES], "cc": [], "bcc": [] }
   * @param {*} param.customInfo null
   * @returns
   */
  update_shield_report_settings: ({
    apiUrl,
    baseUrl,
    accountId,
    orgInfo,
    timeRange,
    mailInfo,
    isEnabled,
    customInfo,
  }) =>
    requests.post('/save-report-info', {
      accountId,
      updateInfo: {
        apiUrl,
        baseUrl,
        orgInfo,
        timeRange,
        mailInfo,
        isEnable: isEnabled,
        customInfo,
      },
    }),

  // Only call this once settings are saved using the above endpoints.
  send_report_now: ({ accountId, timeRange }) => requests.post('/download-instant-report', { accountId, timeRange }),
};

export default {
  Misc,
  Inventory_Resources,
  Jira_Notification,
  Data_Classification,
  Account,
  Malware,
  DataSecurity,
  Reports,
  Policy,
  Edit_Enviroment,
  Inventory_Overview,
  Users,
  Inventory_Resources_Card,
  Kubernetes,
  Key_Insights,
  Dashboard_New,
  CompliancePacks,
  Vulnerabilites,
  Risk,
  CloudEvents,
  Compliance,
  Integration,
  notification_logs,
  notification_setting,
  Auth,
  Clients,
  Cspa,
  Phishing,
  ClientDashboard,

  OCR,
  Rules,
  Environment,
  Environment_Monitoring,
  Identity,
  Visualization,
  Visualization_Filter,
  AttackPathAnalysis,
  API_ROOT,

  setToken: _token => {
    token = _token;
  },
  setTenantID: id => {
    tenantID = id;
  },
};
