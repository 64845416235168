import { openDB } from 'idb';

const DB_NAME = 'ApiCache';
const STORE_NAME = 'responses';

const sleep = (duration = 100) => new Promise(resolve => setTimeout(resolve, duration));

export class DatabaseConnection {
  static instance: DatabaseConnection | null = null;
  database: any = null;

  constructor() {
    if (DatabaseConnection.instance) {
      return DatabaseConnection.instance;
    }

    this.init();
    DatabaseConnection.instance = this;
  }

  async init() {
    const connection = await openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME);
        }
      },
    });

    this.database = connection;
  }

  async getConnection() {
    while (this.database === null) {
      await sleep(50);
    }

    return this.database;
  }
}
