import _ from 'lodash';
import {
  ALL_RULES_FETCH,
  ALL_SERVICES_FETCH,
  CLEAR_FULL_SCAN_REQUEST_BODY,
  CLEAR_LIVE_SCAN_REQUEST_BODY,
  CLOUD_ACCOUNTS_FETCH,
  FULL_SCAN_RESPONSE_SAVE,
  LIVE_SCAN_RESPONSE_SAVE,
  RULES_FOR_SERVICE_FETCH,
  SET_FULL_SCAN_REQUEST_BODY,
  SET_LIVE_SCAN_REQUEST_BODY,
  LIVE_SCAN_SOCKET_CLOSE,
  LIVE_SCAN_SOCKET_OPEN,
  LIVE_SCAN_SOCKET_RECEIVE,
  LIVE_SCAN_SOCKET_SEND,
  DISENGAGE_LIVE_SCAN_SOCKET_ACC,
  LIVE_SCAN_RULE_FETCH_START,
  LIVE_SCAN_RULE_FETCH_END,
  CLEAR_LIVE_SCAN_RESPONSE,
  LIVE_SCAN_EXPANSION_UPDATE,
  LIVE_SCAN_REMEDIATION_UPDATE,
  LIVE_SCAN_SCANNED_ACCOUNT_UPDATE,
  SET_LIVE_SCAN_LATEST_CONFIG,
  CLEAR_LIVE_SCAN_LATEST_CONFIG,
  LIVE_SCAN_LAST_PAGE_UPDATE,
  LIVE_SCAN_FILTER_REMOVE,
  LIVE_SCAN_FILTER_KEY_CHANGE,
  LIVE_SCAN_FILTER_RESET,
  LIVE_SCAN_FILTER_APPLY,
} from '@constants/actionTypes';
import { ComplianceGenerator, ReverseComplianceGenerator } from '@components/utilities/ComplainceFamilyFormatter';
import ReconnectingWebSocket from 'reconnecting-websocket';

function waitForSocketConnection(socket, callback) {
  setTimeout(function () {
    if (socket.readyState === 1) {
      //console.log("Connection is made");
      if (callback != null) {
        callback();
      }
    } else {
      //console.log("wait for connection...");
      waitForSocketConnection(socket, callback);
    }
  }, 5); // wait 5 millisecond for the connection...
}

const defaultState = {
  cloudAccounts: 'loading',
  fullScanRequest: { selected_compliance: ['awaf'], scheduleType: 'now', csp_module: 'AWS' },
  liveScanRequest: {
    account: null,
    rule: null,
    service: null,
  },
  liveScanLatestConfig: null,

  fullScanResponse: {},
  liveScanResponse: {},
  liveScanFilters: {
    filterKeys: [
      {
        field: 'resource_name',
        text: 'Resource Name',
      },
      {
        field: 'resource_id',
        text: 'Resource ID',
      },
      {
        field: 'evaluation',
        text: 'Evaluation',
      },

      {
        field: 'region',
        text: 'Region',
      },
      {
        field: 'severity',
        text: 'Severity',
      },
      {
        field: 'resource_type',
        text: 'Resource Type',
      },
      // {
      // 	field: "tag",
      // 	text: "Tags",
      // },
    ],
    appliedFilters: [],
    suggestions: [],
  },
  liveScanFilteredResponse: {},

  // list of available rules and services
  rules: [],
  services: {},
  fetchingRules: false,
  socket: {},
  activeAccounts: [],
  liveScanExpansionConfig: 'loading',
  liveScanRemediationData: {},
  lastScannedAccount: null,
  liveScanlastPage: null,
};
// TODO: Cloud accounts should be in user redux

const _reAdjustFilters = (d, f) => {
  let resData = [...d];
  f.map(itm => {
    let temp = resData.filter(el => el[itm['field']] === ReverseComplianceGenerator(itm['q']));
    resData = [...temp];
  });
  return resData;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLOUD_ACCOUNTS_FETCH:
      return {
        ...state,
        cloudAccounts: action.payload,
      };
    //#region Actions for Full Scan
    case SET_FULL_SCAN_REQUEST_BODY:
      return {
        ...state,
        fullScanRequest: { ...state.fullScanRequest, ...action.payload },
      };
    case CLEAR_FULL_SCAN_REQUEST_BODY:
      return {
        ...state,
        fullScanRequest: { selected_compliance: ['awaf'], scheduleType: 'now', csp_module: 'AWS' },
        fullScanResponse: {},
      };
    case FULL_SCAN_RESPONSE_SAVE:
      return {
        ...state,
        fullScanResponse: action.payload,
      };
    //#endregion

    //#region Actions for Live Scan
    case SET_LIVE_SCAN_REQUEST_BODY:
      return {
        ...state,
        liveScanRequest: { ...state.liveScanRequest, ...action.payload },
      };
    case CLEAR_LIVE_SCAN_REQUEST_BODY:
      return {
        ...state,
        liveScanRequest: {},
      };
    case CLEAR_LIVE_SCAN_RESPONSE:
      return {
        ...state,
        liveScanlastPage: null,
        liveScanResponse: {},
        liveScanFilters: defaultState.liveScanFilters,
        liveScanFilteredResponse: {},
        liveScanRequest: defaultState.liveScanRequest,
      };
    case SET_LIVE_SCAN_LATEST_CONFIG:
      return {
        ...state,
        liveScanLatestConfig: { ...action.payload },
      };
    case CLEAR_LIVE_SCAN_LATEST_CONFIG:
      return {
        ...state,
        liveScanLatestConfig: null,
      };
    case LIVE_SCAN_RESPONSE_SAVE:
      return {
        ...state,
        liveScanResponse: action.payload,
      };
    case LIVE_SCAN_SCANNED_ACCOUNT_UPDATE:
      return {
        ...state,
        lastScannedAccount: action.payload,
      };
    case LIVE_SCAN_LAST_PAGE_UPDATE:
      return {
        ...state,
        liveScanlastPage: action.payload,
      };
    case LIVE_SCAN_FILTER_KEY_CHANGE:
      // Code for suggestions inside the filter
      // eslint-disable-next-line no-case-declarations
      let key = action.payload;
      return {
        ...state,
        liveScanFilters: {
          ...state['liveScanFilters'],
          suggestions: [...new Set([state.liveScanFilteredResponse.map(el => el[key])].flat())].map(item => {
            return item === '-' ? '-' : ComplianceGenerator(item); // If there is a compliance family, it will format it . If not it will return the default value
          }),
        },
      };

    case LIVE_SCAN_FILTER_APPLY: {
      let newFilter = action.payload;
      if (newFilter.q === '-') {
        newFilter.q = '-';
      }
      if (newFilter.field === 'region') {
        newFilter.q = newFilter['q'][0];
      }

      let result = [];
      let newAppliedFilters = [...state['liveScanFilters'].appliedFilters];
      if (
        state['liveScanFilters'].appliedFilters.filter(
          el => el.field === newFilter.field && el.q === ReverseComplianceGenerator(newFilter.q),
        ).length === 0
      ) {
        newAppliedFilters = [...newAppliedFilters, newFilter];
        result = state.liveScanFilteredResponse.filter(el => {
          const fieldInQuestion = el[newFilter['field']];

          if (Array.isArray(fieldInQuestion)) {
            return fieldInQuestion.includes(ReverseComplianceGenerator(newFilter['q']));
          } else {
            return fieldInQuestion === ReverseComplianceGenerator(newFilter['q']);
          }
        });
      }
      return {
        ...state,
        liveScanFilteredResponse: [...result],
        liveScanFilters: {
          ...state['liveScanFilters'],
          appliedFilters: [...newAppliedFilters],
        },
      };
    }
    case LIVE_SCAN_FILTER_REMOVE: {
      let index = action.payload;
      const newFilters = [...state['liveScanFilters'].appliedFilters];
      if (newFilters.length) newFilters.splice(index, 1);
      const result = _reAdjustFilters([...state.liveScanResponse.data], [...newFilters]);

      return {
        ...state,
        liveScanFilters: {
          ...state['liveScanFilters'],
          appliedFilters: [...newFilters],
        },
        liveScanFilteredResponse: [...result],
      };
    }
    case LIVE_SCAN_FILTER_RESET:
      return {
        ...state,
        liveScanFilters: defaultState.liveScanFilters,
        liveScanFilteredResponse: state.liveScanResponse.data,
      };
    //#endregion

    case ALL_RULES_FETCH:
      return {
        ...state,
        rules: [...action.payload],
      };
    case ALL_SERVICES_FETCH:
      return {
        ...state,
        services: action.payload,
      };
    case RULES_FOR_SERVICE_FETCH: {
      // const service = new Object()
      // service[action.payload.service] = action.payload.rules
      let services = state.services;
      services[action.payload.service] = action.payload.rules;
      return {
        ...state,
        services: { ...services },
      };
    }
    case LIVE_SCAN_RULE_FETCH_START:
      return {
        ...state,
        fetchingRules: true,
      };

    case LIVE_SCAN_RULE_FETCH_END:
      return {
        ...state,
        fetchingRules: false,
      };

    case LIVE_SCAN_EXPANSION_UPDATE:
      return {
        ...state,
        liveScanExpansionConfig: action.payload,
      };

    case LIVE_SCAN_SOCKET_OPEN: {
      let socketObj = new ReconnectingWebSocket(import.meta.env.VITE_WEBSOCKET_REMEDIATION);
      return {
        ...state,
        socket: socketObj,
        activeAccounts: [...state.activeAccounts, action.payload],
      };
    }
    case LIVE_SCAN_SOCKET_SEND: {
      let socketInstance = state.socket;
      waitForSocketConnection(socketInstance, function () {
        //console.log("message sent!!!");
        socketInstance.send(JSON.stringify({ data: action.payload }));
      });
      return {
        ...state,
        socket: socketInstance,
      };
    }
    case LIVE_SCAN_SOCKET_RECEIVE:
      return {
        ...state,
        liveScanResponse: action.payload,
        liveScanFilteredResponse: action.payload.data,
      };

    case DISENGAGE_LIVE_SCAN_SOCKET_ACC:
      return {
        ...state,
        activeAccounts: state.activeAccounts.filter(element => element !== action.payload),
      };

    case LIVE_SCAN_SOCKET_CLOSE: {
      if (!_.isEmpty(state.socket)) state.socket.close();
      return {
        ...state,
        socket: {},
      };
    }
    case LIVE_SCAN_REMEDIATION_UPDATE:
      return {
        ...state,
        liveScanRemediationData: action.payload,
      };
    default:
      return state;
  }
};
