export function getDomainFromEmailAddress(email) {
  if (!email) return null;

  return email.substring(email.lastIndexOf('@') + 1);
}

export function validateEmailAddress(email) {
  if (!email) return false;
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email);
}
