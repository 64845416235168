import React, { useState } from 'react';
import './style.scss';
import { Box } from '@mui/material';

type EventsDropdownProps = {
  eventType: string;
  setEventType: React.Dispatch<React.SetStateAction<string>>;
  options: string[];
};

// const options = ['All', 'Drift', 'Threats', 'Remediation'];

const EventsDropdown = ({ eventType, setEventType, options }: EventsDropdownProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="eventChartDropdown__container">
      <Box className="ecd__value" onClick={() => setOpen(prev => !prev)}>
        <div className="SingleLine">{eventType}</div>
        <div style={{ flexGrow: '1' }} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          style={{ transform: !open ? 'rotate(180deg)' : '' }}>
          <path
            d="M9.58711 3.76592L5.98011 0.382145C5.43697 -0.127382 4.5596 -0.127382 4.01646 0.382144L0.409459 3.76592C-0.467918 4.589 0.15878 6 1.39825 6L8.61224 6C9.85171 6 10.4645 4.589 9.58711 3.76592Z"
            fill="#484D56"
          />
        </svg>
      </Box>

      <div className="ecd__options" style={{ display: open ? 'flex' : 'none' }}>
        {options?.map((option, i) => (
          <Box
            onClick={() => {
              setEventType(option);
              setOpen(false);
            }}
            className="ecd__option SingleLine"
            style={{
              borderBottom: i === options.length - 1 ? 'none' : '',
              background: eventType === option ? '#EDF2F7' : '#FFF',

              borderTopRightRadius: i === 0 ? '4px' : '',
              borderTopLeftRadius: i === 0 ? '4px' : '',

              borderBottomLeftRadius: i === options.length - 1 ? '4px' : '',
              borderBottomRightRadius: i === options.length - 1 ? '4px' : '',
            }}>
            <Box className="SingleLine">{option}</Box>
          </Box>
        ))}
      </div>
    </div>
  );
};

export default EventsDropdown;
