import React, { useState, useEffect, useMemo } from 'react';
import CheckBox from '@root/components/v2/ui-elements/CheckBox';
import { Box } from '@mui/material';
import './EnvironmentDropdown.scss';

const EnvironmentDropdownOption = ({
  option,
  index,
  length,
  handleOptionClick,
  handleGroupClick,
  disabledOptions,
  value,
}) => {
  const [open, setOpen] = useState(true);
  const [delayedOpen, setDelayedOpen] = useState(true);
  // const [selectedSubClildren, setSelectedSubClildren] = useState([]);

  // useEffect(() => {
  //   setSelectedSubClildren([]);
  // }, [selectedChildren]);

  // useEffect(() => {
  //   if (!_.isEmpty(selectedChildren) && !selected) {
  //     onClick(option);
  //     if (firstRender.current) {
  //       firstRender.current = false;
  //     }
  //   }
  //   if (_.isEmpty(selectedChildren) && !firstRender.current) {
  //     onClick(option);
  //   }
  // }, [selectedChildren]);

  // useEffect(() => {
  //   if (!selected && option.options) {
  //     setOpen(false);
  //     setSelectedChildren([]);
  //   }
  // }, [selected]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setDelayedOpen(open);
      }, 200);
    } else {
      setDelayedOpen(open);
    }
  }, [open]);

  const allChildrenSelected = useMemo(() => {
    if (option.options) {
      let temp = true;

      option.options.forEach(child => {
        if (!value.includes(child.value)) temp = false;
      });

      return temp;
    }
  }, [option.options, value]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Box className="EnvironmentDropdown__Option" onClick={() => handleGroupClick(option)}>
          <CheckBox checked={allChildrenSelected} style={{ padding: '0px' }} />
          <div>
            <img
              src={option.icon}
              style={{ width: option.label === 'AWS' ? '25px' : '20px', marginRight: '7px', marginLeft: '5px' }}
            />
          </div>
          <div style={{ marginLeft: '2px' }}>({option.options?.length})</div>
          <div style={{ flexGrow: '1' }} />
        </Box>
        {option.options && (
          <Box
            className="EnvironmentDropdown__OptionsArrow"
            style={{
              borderBottomRightRadius: index === length - 1 && !open ? '5px' : '0',
              borderTop: '1px solid var(--boder-line-light, #cbd5e0)',
            }}
            onClick={() => setOpen(prev => !prev)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              style={{ transition: 'all 200ms', transform: open ? 'rotate(180deg)' : '' }}>
              <path
                d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                fill="#484D56"
              />
            </svg>
          </Box>
        )}
      </div>
      {option.options && (
        <Box
          className="EnvironmentDropdown__ChildOptions"
          style={{
            // height: open ? `${option.options.length * 43}px` : '0px',
            display: open ? 'block' : 'none',
            borderTop: delayedOpen ? '1px solid var(--boder-line-light, #cbd5e0)' : 'none',
          }}>
          {option.options &&
            option.options.map(child => {
              //default case
              return (
                <Box
                  className="EnvironmentDropdown__ChildOption"
                  onClick={disabledOptions.includes(child.value) ? null : () => handleOptionClick(child)}>
                  <div>{child.label}</div>
                  <div style={{ flexGrow: '1' }} />
                  <CheckBox checked={value.includes(child.value)} style={{ padding: '0px' }} />
                </Box>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default EnvironmentDropdownOption;
