import {
  NOTIFICATION_BANNER_ELEMENT_CREATE,
  NOTIFICATION_BANNER_ELEMENT_UPDATE,
  NOTIFICATION_BANNER_ELEMENT_REMOVE,
} from '@constants/actionTypes';

export const createNotificationBanner = (dispatch, props) => {
  dispatch({
    type: NOTIFICATION_BANNER_ELEMENT_CREATE,
    payload: props,
  });
};

export const updateNotificationBanner = (dispatch, props) => {
  dispatch({
    type: NOTIFICATION_BANNER_ELEMENT_UPDATE,
    payload: props,
  });
};

export const removeNotificationBanner = (dispatch, id) => {
  dispatch({
    type: NOTIFICATION_BANNER_ELEMENT_REMOVE,
    payload: id,
  });
};
