import { CopyTextIcon } from '@root/assets/svg/SVGComponents';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

type FieldProps = {
  name: string;
  value: string;
  showCopy?: boolean;
  disabled?: boolean;
};

const Fields = ({ name, value, showCopy, disabled }: FieldProps) => {
  const handleCopy = useCallback(async () => {
    if (!value) return;
    toast.success('Copied to clipboard');
    await navigator.clipboard.writeText(value);
  }, [value]);

  return (
    <div className="ProfileFields__Container">
      <div className="ProfileFields__Name">{name}</div>
      <div className="ProfileFields__InputContainer">
        <input
          type="text"
          value={value}
          style={{ pointerEvents: disabled ? 'none' : 'all', color: disabled ? '#A3A6AB' : '#1A202C' }}
        />
        {showCopy ? <CopyTextIcon onClick={handleCopy} style={{ cursor: 'pointer' }} /> : null}
      </div>
    </div>
  );
};

export default Fields;
