import { ADD_ONGOING_REMEDIATION, REMOVE_ONGOING_REMEDIATIONS } from '@constants/actionTypes';

const defaultState = {
  remediations: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_ONGOING_REMEDIATION: {
      return {
        ...state,
        remediations: [...state.remediations, action.payload],
      };
    }
    case REMOVE_ONGOING_REMEDIATIONS: {
      const remediationIds = action.payload;
      return {
        ...state,
        remediations: state.remediations.filter(item => !remediationIds.find(() => item.remediation_id)),
      };
    }
    default:
      return state;
  }
};
