import _ from 'lodash';
import {
  CLEAR_GROUPS_MODAL_DATA,
  CLEAR_RBAC_GROUPS_DATA,
  CLEAR_RBAC_USERS_DATA,
  CLEAR_USERS_MODAL_DATA,
  FETCH_RBAC_CLOUD_ACCOUNT,
  FETCH_RBAC_GROUPS_DATA,
  FETCH_RBAC_USERS_DATA,
  SET_GROUPS_MODAL_DATA,
  SET_USERS_MODAL_DATA,
} from '../constants/actionTypes';

const defaultState = {
  users: {
    status: 0,
    tableData: [],
    modalData: {},
    count: 1,
  },
  groups: {
    status: 0,
    tableData: [],
    modalData: {},
    count: 1,
  },
  cloudAccounts: 'loading',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_RBAC_GROUPS_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          groups: {
            ...state.groups,
            status: action.payload['status'],
            tableData: action.payload['data'],
            count: action.payload['data']['count'],
          },
        };
      }
      break;
    case CLEAR_RBAC_GROUPS_DATA:
      return {
        ...state,
        groups: {
          ...state.groups,
          status: 0,
          tableData: [],
          modalData: {},
        },
      };
    case SET_GROUPS_MODAL_DATA:
      return {
        ...state,
        groups: {
          ...state.groups,
          modalData: action.payload,
        },
      };
    case CLEAR_GROUPS_MODAL_DATA:
      return {
        ...state,
        groups: {
          ...state.groups,
          modalData: {},
        },
      };
    case FETCH_RBAC_USERS_DATA:
      if (!_.isEmpty(action?.payload)) {
        return {
          ...state,
          users: {
            status: action?.payload?.status ?? true,
            tableData: action?.payload?.data,
            count: action?.payload?.data?.count ?? 0,
          },
        };
      }
      break;
    case CLEAR_RBAC_USERS_DATA:
      return {
        ...state,
        users: {
          ...state.users,
          status: 0,
          tableData: [],
          modalData: {},
        },
      };
    case SET_USERS_MODAL_DATA:
      return {
        ...state,
        users: {
          ...state.users,
          modalData: action.payload,
        },
      };
    case CLEAR_USERS_MODAL_DATA:
      return {
        ...state,
        users: {
          ...state.users,
          modalData: {},
        },
      };
    case FETCH_RBAC_CLOUD_ACCOUNT:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          cloudAccounts: action.payload,
        };
      }
      break;
    default:
      return state;
  }
};
