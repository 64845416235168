import _ from 'lodash';
import { FETCH_DASHBOARD_DATA } from '../constants/actionTypes';

const defaultState = {
  status: 'loading',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA:
      if (!_.isEmpty(action.payload)) {
        return { ...state, status: action.payload['status'], ...action.payload.data.data.data };
      }
      break;
    default:
      return state;
  }
};
