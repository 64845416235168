import { useEnvironments, useSelectedEnvironments } from '@root/hooks/environments';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const CloudProviderContext = createContext();

export const useCloudProvider = () => useContext(CloudProviderContext);

export const CloudProvider = ({ children }) => {
  const [cloudProvider, setCloudProvider] = useState('all'); // Default cloud provider is 'all'
  const { selectedEnvironments } = useSelectedEnvironments();
  const allEnvironments = useEnvironments();

  useEffect(() => {
    if (selectedEnvironments.length === allEnvironments.length) {
      setCloudProvider('all');
    } else if (
      selectedEnvironments.some(envData => envData.cloudProvider === 'gcp') &&
      selectedEnvironments.some(envData => envData.cloudProvider === 'aws')
    ) {
      setCloudProvider('all');
    } else if (selectedEnvironments.some(envData => envData.cloudProvider === 'gcp')) {
      setCloudProvider('gcp');
    } else if (selectedEnvironments.some(envData => envData.cloudProvider === 'aws')) {
      setCloudProvider('aws');
    }
  }, [selectedEnvironments, allEnvironments]);

  const handleChangeCloudProvider = useCallback(newCloudProvider => {
    setCloudProvider(newCloudProvider);
  }, []);

  const value = useMemo(() => {
    return { cloudProvider, handleChangeCloudProvider, setCloudProvider };
  }, [cloudProvider, handleChangeCloudProvider]);

  return <CloudProviderContext.Provider value={value}>{children}</CloudProviderContext.Provider>;
};
