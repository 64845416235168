// Component used for dynamic column widths.

// HOW IT WORKS
// The Table__CellContent element is initially rendered with "absolute" position.
// This allows the cell content to be rendered with no width limit.
// The width of Table__CellContent is then saved in state using setColumnWidth.
// Once the above is done, we change the positioning to "relative".
// In table.js, the width of the column is updated using the measured width of
// Table__CellContent.

import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import './dynamic-width-cell.scss';
import copyIcon from '@assets/img/Copy Icon.png';
import { toast } from 'react-toastify';
import { Box } from '@mui/system';

// Character limit is measured by referencing an element containing [CHARACTER_LIMIT] aka 50 "A" characters.
const CHARACTER_LIMIT = 50;
const characters = Array.from(new Array(CHARACTER_LIMIT))
  .map(() => 'A')
  .join('');

function DynamicWidthCell({
  columnIndex,
  previousWidth,  // If the width was previously measured, we won't measure it again.
  setColumnWidth,
  copyData,
  useCharacterLimit,
  disableAbsolutePositioning,
  params,
  field,
  children,
}) {
  const cellContentRef = useRef();
  const characterLimitRef = useRef();
  const [ownWidth, setOwnWidth] = useState(null);

  const copyText = useMemo(() => {
    if (Array.isArray(copyData?.ignoreValues) && copyData?.ignoreValues?.includes(params?.row[copyData?.key]))
      return '';

    return params?.row[copyData?.key] ?? '';
  }, [copyData?.ignoreValues, copyData?.key, params?.row]);

  const handleCopyText = useCallback(
    async e => {
      e.stopPropagation();

      if (!copyText) return;

      toast.info(`Copied ${field} to clipboard!`);
      await navigator.clipboard.writeText(copyText);
    },
    [copyText, field],
  );

  useLayoutEffect(() => {
    if (previousWidth) return;

    if (!cellContentRef.current && (!useCharacterLimit || !characterLimitRef.current)) return;

    setOwnWidth(cellContentRef.current.scrollWidth);

    setColumnWidth({
      columnIndex,
      width:
        (useCharacterLimit
          ? Math.min(cellContentRef.current.scrollWidth, characterLimitRef.current.scrollWidth)
          : cellContentRef.current.scrollWidth) + 1, // The +1 is to handle scrollWidth returning rounded value which sometimes leads to ellipsis in text columns.
      shouldReplace: useCharacterLimit,
    });
  }, [columnIndex, previousWidth, setColumnWidth, useCharacterLimit]);

  const contentStyle = useMemo(() => {
    return { ...((previousWidth || ownWidth || disableAbsolutePositioning) && { position: 'relative' }) };
  }, [disableAbsolutePositioning, previousWidth, ownWidth]);

  return (
    <>
      <div className="Table__CellContainer">
        <div className="Table__CellContent" style={contentStyle} ref={cellContentRef}>
          {children}
        </div>

        {useCharacterLimit ? (
          <div className="Table__CharacterLimitReference" ref={characterLimitRef}>
            {characters}
          </div>
        ) : null}
      </div>
      {copyText ? (
        <>
          <Box flexGrow={1} />
          <div className="Table__CopyContainer" onClick={handleCopyText}>
            <img src={copyIcon} />
          </div>
        </>
      ) : null}
    </>
  );
}

export default React.memo(DynamicWidthCell);
