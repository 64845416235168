import _ from 'lodash';
import {
  MANUAL_SCAN_HISTORY_FETCH,
  AUTONOMOUS_SCAN_HISTORY_FETCH,
  MANUAL_SCAN_SOCKET_UPDATE,
  STOP_MANUAL_SCAN_UPDATE,
  REAL_TIME_SCAN_FETCH,
} from '../constants/actionTypes';

const defaultState = {
  manualScanStatus: 'loading',
  manualScanHistory: [],
  triggerManualUpdate: false,
  autonomousScanStatus: 'loading',
  autonomousScanHistory: [],
  realTimeScanHistory: [],
  reactTimeScanStatus: 'loading',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MANUAL_SCAN_HISTORY_FETCH:
      if (!_.isEmpty(action.payload))
        return {
          ...state,
          manualScanHistory: !_.isEmpty(action.payload['data']['data']['history'])
            ? action.payload['data']['data']['history']
            : [],
          manualScanStatus: action.payload['status'],
        };
      break;
    case MANUAL_SCAN_SOCKET_UPDATE: {
      let newEntry = action.payload;
      let updatedScanHistory = state.manualScanHistory.map(entry => {
        if (entry['scan_id'] === newEntry['scan_id']) {
          return newEntry;
        } else {
          return entry;
        }
      });
      // Adding new entry if the it doesn't exist in scan history
      let combinedScanHistory = [...updatedScanHistory];
      if (!updatedScanHistory.includes(newEntry)) {
        combinedScanHistory = [newEntry, ...updatedScanHistory];
      }
      return {
        ...state,
        manualScanHistory: combinedScanHistory,
        triggerManualUpdate: true,
      };
    }
    case STOP_MANUAL_SCAN_UPDATE:
      return {
        ...state,
        triggerManualUpdate: false,
      };
    case AUTONOMOUS_SCAN_HISTORY_FETCH:
      if (!_.isEmpty(action.payload))
        return {
          ...state,
          autonomousScanHistory: !_.isEmpty(action.payload.data.data.history) ? action.payload.data.data.history : [],
          autonomousScanStatus: action.payload.status,
        };
      break;
    case REAL_TIME_SCAN_FETCH:
      {
        if (!_.isEmpty(action.payload))
          return {
            ...state,
            realTimeScanHistory: !_.isEmpty(action.payload.data.data.history) ? action.payload.data.data.history : [],
            reactTimeScanStatus: action.payload.status,
          };
      }
      break;
    default:
      return state;
  }
};
