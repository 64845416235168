import React from 'react';
import { Box } from '@mui/material';
import './style.scss';
import { subMinutes, subHours, subDays } from 'date-fns';
import useTimeFilter from '@root/hooks/SelectTimeFilter';
import { format } from 'date-fns';
const CustomDropdownOption = ({ option, index, selected, setValue, width, setFullOpen, setOpenCustomSelector }) => {
  const { updateTimeRange } = useTimeFilter();

  const calculateTimeRange = value => {
    const now = new Date();
    let from = now;
    if (value.includes('m')) {
      from = subMinutes(now, parseInt(value.slice(0, -1)));
    } else if (value.includes('h')) {
      from = subHours(now, parseInt(value.slice(0, -1)));
    } else if (value.includes('d')) {
      from = subDays(now, parseInt(value.slice(0, -1)));
    } else {
      from = now;
    }

    return { from: format(from, 'yyyy/MM/dd HH:mm:ss'), to: format(now, 'yyyy/MM/dd HH:mm:ss') };
  };

  return (
    <Box
      className={`CustomDropdown__Option ${selected ? 'CustomDropdown__Option--selected' : ''}`}
      style={{
        borderTop: index === 0 ? 'none' : '1px solid var(--boder-line-light, #cbd5e0)',
        width: `calc(${width} - 2px)`,
      }}
      onClick={() => {
        setValue(option.value);
        setOpenCustomSelector(false);
        if (
          location.pathname === '/events/all' ||
          location.pathname === '/events/suppressed' ||
          location.pathname === '/events/overview'
        )
          updateTimeRange(calculateTimeRange(option.value));

        setFullOpen(false);
      }}>
      <div style={{ marginLeft: '2px' }}>{option.label}</div>
      <div style={{ flexGrow: '1' }} />
    </Box>
  );
};

export default CustomDropdownOption;
