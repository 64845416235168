//ttttttttttttttttttttt
import {
  CLEAR_RESOURCE_LIST,
  FETCH_ALERT_SEVERITY,
  FETCH_ALL_RESOURCES_PAGE,
  FETCH_CRITICAL_RESOURCES_PAGE,
  FETCH_EXPOSED_SECRETS,
  FETCH_INVENTORY_DB_INTERNET_EXPOSED_RESOURCES,
  FETCH_INVENTORY_EVALUATION,
  FETCH_INVENTORY_RESOURCE_RISK,
  FETCH_KEY_INSIGHT_BUCKET_WITHOUT_RESOURCE,
  FETCH_KEY_INSIGHT_DEFAULT_ENCRYPTION,
  FETCH_KEY_INSIGHT_RESOURCES,
  FETCH_KEY_INSIGHT_RUNTIMES,
  FETCH_KEY_INSIGHT_TRIGGERS,
  FETCH_KEY_INSIGHT_UNENCRYPT_BUCKETS,
  FETCH_LAST_UPDATE,
  FETCH_LIST_REGIONS,
  FETCH_RESOURCE_COMPLIANCE,
  FETCH_RESOURCE_LIST,
  FETCH_RESOURCE_NON_COMPLIANT,
  FETCH_RESOURCE_OVERTIME,
  FETCH_RESOURCE_RISK,
  FETCH_RESOURCE_TABLE,
  FETCH_SERVICE_CATEGORY_LIST,
  FETCH_SERVICE_LIST,
  FETCH_SERVICE_LIST_PROPERTY_KEYS,
  FETCH_SERVICE_LIST_PROPERTY_VALUES,
  FETCH_SERVICE_LIST_TYPES,
  FETCH_TAG_ENV_LIST,
  FETCH_TAG_REGIONS_LIST,
  FETCH_TAG_RESOURCE_LIST,
  FETCH_TAG_RESOURCE_TYPE_LIST,
  UPDATE_ALERT_SEVERITY,
  UPDATE_ALL_RESOURCES_PAGE,
  UPDATE_CRITICAL_RESOURCES_PAGE,
  UPDATE_KEY_INSIGHTS_PAGE,
  UPDATE_REGIONS,
  UPDATE_RESOURCE_LIST,
  UPDATING_RESOURCE_NON_COMPLIANT,
  UPDATING_RESOURCE_OVERTIME,
} from '../constants/actionTypes';

const defaultState = {
  trigger: false,
  cloud: 'aws',
  resource_compliance_summary: { data: {}, status: 'fetching' },

  // resource_overtime: { data: { sevenDays: {}, twentyFourHours: {} }, status: 'fetching' },
  evaluation_overtime: { data: { sevenDays: {}, twentyFourHours: {} }, status: 'fetching' },
  total_non_compliant: { data: {}, status: 'fetching' },
  internet_exposed_resources: { data: {}, status: 'fetching' },
  alert_severity: { data: {}, status: 'fetching' },
  resource_summary: { data: {}, status: 'fetching' },
  filters: { data: {}, status: 'fetching', currentQuery: {} },
  compliance_summary: { data: {}, status: 'fetching' },
  resource_risk: { data: {}, status: 'fetching' },
  resource_overtime: { data: [], status: 'fetching' },
  non_compliant: { data: [], status: 'fetching' },
  resource_table: { data: [], status: 'fetching' },
  resource_list: { data: [], status: 'fetching', count: 0, errorMessage: '', extraColumns: [] },
  service_list_filter: { data: [], status: 'fetching' },
  service_list: { data: {}, status: 'fetching' },
  service_list_type: { data: [], status: 'fetching' },
  service_list_property_keys: { data: [], status: 'fetching' },
  service_list_property_values: { data: {}, status: 'fetching' },
  key_insight_triggers: { data: [], status: 'fetching', total: 0 },
  key_insight_exposed_secrets: { data: [], status: 'fetching', total: 0 },
  key_insight_bucket_without_resource: { data: [], status: 'fetching', total: 0 },
  key_insight_unencrypt_buckets: { data: [], status: 'fetching', count: 0, errorMessage: '', extraColumns: [] },
  key_insight_default_encryption: { data: [], status: 'fetching', total: 0 },
  inventory_resource_risk: { data: {}, status: 'fetching' },
  inventory_key_insight_evaluation: { data: {}, status: 'fetching' },

  all_resource_data: {
    data: {
      resource_data: [],
      category_data: [],
    },
    status: 'fetching',
  },
  critical_resources_data: {
    data: {
      resource_data: [],
      category_data: [],
    },
    status: 'fetching',
  },
  resources_modal_tag_envs: { data: [], status: 'fetching' },
  resources_modal_tag_regions: { data: [], status: 'fetching' },
  resources_modal_tag_types: { data: [], status: 'fetching' },
  resources_modal_tag_resources: { data: [], status: 'fetching' },

  key_insight_runtimes: { data: [], status: 'fetching', total: 0 },
  key_insight_resources: { data: [], status: 'fetching', total: 0 },
  last_update_time: {},
  list_regions: { data: [], status: 'fetching' },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_RESOURCE_LIST:
      return {
        ...state,
        resource_list: {
          ...state.resource_list,
          status: 'fetching',
        },
      };

    case UPDATE_REGIONS:
      return {
        ...state,
        compliance_summary: {
          ...state.compliance_summary,
          status: 'fetching',
        },
        resource_risk: {
          ...state.resource_risk,
          status: 'fetching',
        },
        resource_overtime: {
          ...state.resource_overtime,
          status: 'fetching',
        },
        non_compliant: {
          ...state.non_compliant,
          status: 'fetching',
        },
        alert_severity: {
          ...state.alert_severity,
          status: 'fetching',
        },
        internet_exposed_resources: {
          ...state.internet_exposed_resources,
          status: 'fetching',
        },
      };

    case FETCH_RESOURCE_COMPLIANCE:
      if (action.payload) {
        return {
          ...state,
          compliance_summary: { data: { ...action.payload.data }, status: 200 },
        };
      }
      break;
    case FETCH_RESOURCE_RISK:
      if (action.payload) {
        return {
          ...state,
          resource_risk: { data: { ...action.payload.data }, status: 200 },
        };
      }
      break;

    case FETCH_RESOURCE_OVERTIME:
      if (action.payload) {
        return {
          ...state,
          resource_overtime: {
            ...state.resource_overtime,
            data: action.payload.data,
            status: 200,
          },
        };
      }
      break;

    case FETCH_INVENTORY_DB_INTERNET_EXPOSED_RESOURCES:
      if (action.payload) {
        return {
          ...state,
          trigger: false,
          internet_exposed_resources: { ...action.payload.data, status: 200 },
        };
      }
      return {
        ...state,
        trigger: false,
        internet_exposed_resources: { ...state.internet_exposed_resources, status: 200 },
      };

    case FETCH_ALERT_SEVERITY:
      if (action.payload) {
        return {
          ...state,
          alert_severity: {
            ...state.alert_severity,
            data: action.payload.data.length > 0 ? action.payload.data[0]?.integer : [],
            total: action.payload.data.length > 0 ? action.payload.data[0]?.total : 0,
            status: 200,
          },
        };
      }
      break;

    case UPDATE_ALERT_SEVERITY:
      return {
        ...state,
        alert_severity: {
          ...state.alert_severity,
          total: 0,
          status: 'fetching',
        },
      };

    case FETCH_RESOURCE_NON_COMPLIANT:
      if (action.payload) {
        return {
          ...state,
          non_compliant: {
            ...state.non_compliant,
            data: action.payload.data,
            status: 200,
          },
        };
      }
      break;
    case UPDATING_RESOURCE_OVERTIME:
      return {
        ...state,
        resource_overtime: {
          ...state.resource_overtime,
          status: 'fetching',
        },
      };

    case UPDATING_RESOURCE_NON_COMPLIANT:
      return {
        ...state,
        non_compliant: {
          ...state.non_compliant,
          status: 'fetching',
        },
      };

    case FETCH_RESOURCE_TABLE:
      if (action.payload) {
        // let sortedRulesData = action.payload.sort(compare);
        console.log(`responseresponse`, action.payload);
        return {
          ...state,
          resource_table: {
            ...state.resource_table,
            data: action.payload.data.data,
          },
        };
      }
      break;

    case FETCH_RESOURCE_LIST:
      return {
        ...state,
        resource_list: {
          ...state.resource_list,
          data: action.payload.inventory_resources_list.map((item, index) => ({ ...item, id: index + 1 })),
          extraColumns: action.payload.table_header_column,
          count: action.payload.num_records,
          status: 200,
        },
      };

    case CLEAR_RESOURCE_LIST:
      return {
        ...state,
        resource_list: {
          ...state.resource_list,
          data: [],
          count: 0,
        },
      };

    case FETCH_SERVICE_CATEGORY_LIST:
      return {
        ...state,
        service_list_filter: {
          ...state.service_list_filter,
          data: action.payload.data,
        },
      };

    case FETCH_SERVICE_LIST:
      return {
        ...state,
        service_list: {
          ...state.service_list,
          data: {
            ...state.service_list.data,
            [action.payload.service_category]: action.payload.service_list,
          },
        },
      };

    case FETCH_SERVICE_LIST_TYPES:
      return {
        ...state,
        service_list_type: {
          ...state.service_list_type,
          data: {
            ...state.service_list_type.data,
            [`${action.payload.service_category}.${action.payload.service_group}`]:
              action.payload.data.resource_type_list,
          },
        },
      };

    case FETCH_SERVICE_LIST_PROPERTY_KEYS:
      return {
        ...state,
        service_list_property_keys: {
          data: action.payload.list_property_keys.map(item => ({
            id: item,
            title: item,
          })),
          status: 200,
        },
      };

    case FETCH_SERVICE_LIST_PROPERTY_VALUES:
      return {
        ...state,
        service_list_property_values: {
          data: {
            ...state.service_list_property_values.data,
            [action.payload.key]: action.payload.data.map(item => ({
              id: item,
              title: item,
            })),
          },
          status: 200,
        },
      };

    case FETCH_EXPOSED_SECRETS:
      return {
        ...state,
        key_insight_exposed_secrets: {
          ...state.key_insight_exposed_secrets,
          data: action.payload.data,
          total: action.payload.data[0].total,
          status: 200,
        },
      };

    case UPDATE_KEY_INSIGHTS_PAGE:
      return {
        ...state,
        key_insight_triggers: {
          ...state.key_insight_triggers,
          status: 'fetching',
        },
        key_insight_runtimes: {
          ...state.key_insight_runtimes,
          status: 'fetching',
        },
        key_insight_resources: {
          ...state.key_insight_resources,
          status: 'fetching',
        },
        inventory_resource_risk: {
          ...state.inventory_resource_risk,
          status: 'fetching',
        },
        inventory_key_insight_evaluation: {
          ...state.inventory_key_insight_evaluation,
          status: 'fetching',
        },
        resource_list: {
          ...state.resource_list,
          status: 'fetching',
        },
      };

    case FETCH_KEY_INSIGHT_TRIGGERS:
      return {
        ...state,
        key_insight_triggers: {
          ...state.key_insight_triggers,
          data: action.payload.data,
          total: action.payload.data[0].total,
          status: 200,
        },
      };
    //  case for other apis
    case FETCH_KEY_INSIGHT_RUNTIMES:
      return {
        ...state,
        key_insight_runtimes: {
          ...state.key_insight_runtimes,
          data: action.payload.data,
          total: action.payload.data[0].total,
          status: 200,
        },
      };
    case FETCH_KEY_INSIGHT_RESOURCES:
      return {
        ...state,
        key_insight_resources: {
          ...state.key_insight_resources,
          data: action.payload.data,
          total: action.payload.data[0].total,
          status: 200,
        },
      };

    case FETCH_INVENTORY_RESOURCE_RISK:
      return {
        ...state,
        inventory_resource_risk: {
          data: action.payload.data,
          status: 200,
        },
      };
    // aws s3
    case FETCH_KEY_INSIGHT_DEFAULT_ENCRYPTION:
      return {
        ...state,
        key_insight_default_encryption: {
          ...state.key_insight_default_encryption,
          data: action.payload.data,
          total: action.payload.data[0].total,
          status: 200,
        },
      };
    case FETCH_KEY_INSIGHT_BUCKET_WITHOUT_RESOURCE:
      return {
        ...state,
        key_insight_bucket_without_resource: {
          ...state.key_insight_bucket_without_resource,
          data: action.payload.data,
          total: action.payload.data[0].total,
          status: 200,
        },
      };
    case FETCH_KEY_INSIGHT_UNENCRYPT_BUCKETS:
      return {
        ...state,
        key_insight_unencrypt_buckets: {
          ...state.key_insight_unencrypt_buckets,
          data: action.payload.inventory_resources_list.map((item, index) => ({ ...item, id: index + 1 })),
          extraColumns: action.payload.table_header_column,
          count: action.payload.num_records,
          status: 200,
        },
      };

    case FETCH_INVENTORY_EVALUATION:
      return {
        ...state,
        inventory_key_insight_evaluation: {
          data: action.payload.data,
          status: 200,
        },
      };

    case UPDATE_ALL_RESOURCES_PAGE:
      return {
        ...state,
        all_resource_data: {
          ...state.all_resource_data,
          status: 'fetching',
        },
      };

    case FETCH_ALL_RESOURCES_PAGE:
      return {
        ...state,
        all_resource_data: {
          data: {
            resource_data: action.payload.data.resource_data,
            category_data: action.payload.data.category_data,
            total_resource_count: action.payload.data.total_resource_count,
            total_critical_count: action.payload.data.total_critical_count,
          },
        },
      };

    case UPDATE_CRITICAL_RESOURCES_PAGE: {
      return {
        ...state,
        critical_resources_data: {
          ...state.critical_resources_data,
          status: 'fetching',
        },
      };
    }

    case FETCH_CRITICAL_RESOURCES_PAGE:
      return {
        ...state,
        critical_resources_data: {
          data: {
            resource_data: action.payload.data.resource_data,
            category_data: action.payload.data.category_data,
            total_resouce_count: action.payload.data.total_resource_count,
          },
        },
      };

    case FETCH_LIST_REGIONS:
      return {
        ...state,
        list_regions: {
          data: action.payload.regions,
        },
      };
    case FETCH_LAST_UPDATE:
      return {
        ...state,
        last_update_time: action.payload,
      };
    case FETCH_TAG_ENV_LIST:
      return {
        ...state,
        resources_modal_tag_envs: {
          ...state.resources_modal_tag_envs,
          data: action.payload.data,
        },
      };
    case FETCH_TAG_RESOURCE_TYPE_LIST:
      return {
        ...state,
        resources_modal_tag_types: {
          ...state.resources_modal_tag_types,
          data: action.payload.data,
        },
      };

    case FETCH_TAG_REGIONS_LIST:
      return {
        ...state,
        resources_modal_tag_regions: {
          ...state.resources_modal_tag_regions,
          data: action.payload.data,
        },
      };

    case FETCH_TAG_RESOURCE_LIST:
      return {
        ...state,
        resources_modal_tag_resources: {
          ...state.resources_modal_tag_resources,
          data: action.payload.data,
        },
      };

    default:
      return state;
  }
};
