import {
  FETCH_RULES_DATA,
  RULES_FILTER_CHANGE,
  FLUSH_RULES_DATA,
  TABLE_SORT_TRIGGERED,
} from '../constants/actionTypes';

const defaultState = {
  rulesData: null,
  currentRulesData: null,
  rulesFilter: {
    risk: null,
    severity: null,
    service: null,
    compliance: null,
  },
  sortTriggered: false,
};

export default (state = defaultState, action) => {
  function compare(a, b) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }
  switch (action.type) {
    case FETCH_RULES_DATA:
      return {
        ...state,
        rulesData: action.payload,
        currentRulesData: action.payload.sort(compare),
      };
    case RULES_FILTER_CHANGE: {
      return {
        ...state,
      };
    }
    case FLUSH_RULES_DATA:
      return {
        ...defaultState,
      };
    case TABLE_SORT_TRIGGERED:
      return {
        ...state,
        sortTriggered: !state.sortTriggered,
      };
    default:
      return state;
  }
};
