import _ from 'lodash';
import { FETCH_USER_POLICY_DATA, FETCH_USER_POLICY_TABLE } from '../constants/actionTypes';

const defaultState = {
  permission: [],
  data: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_USER_POLICY_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          permission: JSON.parse(localStorage.getItem('User Policy') || '[]'),
        };
      }
      break;
    case FETCH_USER_POLICY_TABLE:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          data: JSON.parse(localStorage.getItem('User Policy Table') || '[]'),
        };
      }
      break;
    default:
      return state;
  }
};
