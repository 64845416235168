import { Box, Grid, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';

function RulesSkeleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton isRegionFilter={true} />
      <CloudProviderBarSkeleton />
      <Box display="flex" flexDirection="row" gap="15px" paddingBottom="15px">
        {['Rule Coverage', 'Rule by Severity', 'Rule Drilldown'].map(title => (
          <ContentBox
            key={title}
            title={title}
            paddingMode="content"
            style={{ flexGrow: 1, width: title === 'Rule Drilldown' ? 'calc(50% - 15px)' : 'calc(25% - 15px)' }}>
            {/* Set a fixed width for each ContentBox */}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="406px"
              padding="10px"
              paddingTop="30px">
              <Box width="100%" display="flex" justifyContent="center" marginBottom="20px">
                {title === 'Rule Drilldown' ? (
                  <Skeleton variant="rectangular" height="190px" sx={{ width: '100%', flexGrow: 1 }} />
                ) : (
                  <Skeleton variant="circular" width="160px" height="160px" />
                )}
              </Box>
              <Box flexGrow={1} height="10px" width="100%" />

              <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" marginBottom="10px">
                <Skeleton width="100px" />
                <Skeleton width="100px" />
              </Box>
              <Grid sx={{ width: '100%' }}>
                {Array.from(new Array(title === 'Cloud Provider' ? 3 : 4)).map((i, index) => (
                  <Box
                    key={i}
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={title === 'Cloud Provider' ? '15px' : '10px'}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginTop={index === 0 ? '0px' : title === 'Cloud Provider' ? '20px' : '8px'}>
                      <Skeleton width="80px" />
                      {title === 'Rule Drilldown' && <Skeleton width="80px" />}
                      <Skeleton width="80px" />
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Box>
          </ContentBox>
        ))}
      </Box>

      <ContentBox paddingMode="content" marginBottom="15px">
        <Box
          className="Borders RoundedCorners"
          marginLeft="15px"
          marginRight="15px"
          marginBottom="15px"
          marginTop="15px">
          <Skeleton height="40px" style={{ transform: 'none' }} />
        </Box>

        <Box display="flex" gap="15px" padding="15px">
          {Array.from(new Array(5)).map((title, index) => (
            <ContentBox key={index} containerClassName={'border-box'} height={'114px'} width="100%">
              <Box width="100%" display="flex">
                <Box
                  height="100%"
                  sx={{
                    display: 'flex',
                    gap: '1.5rem',
                    alignItems: 'center',
                    flexGrow: 1,
                  }}>
                  <Box
                    sx={{
                      marginBottom: '.5rem',
                    }}>
                    <Skeleton height="48px" width="48px" variant="circular" style={{ transform: 'none' }} />
                  </Box>
                  <Box width="100%">
                    <Box width="100%" marginBottom="10px">
                      <Skeleton width="100px" />
                    </Box>
                    <Box width="100%" marginBottom="15px">
                      <Skeleton width="100px" />
                    </Box>
                    <Box width="100%">
                      <Skeleton width="100px" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ContentBox>
          ))}
        </Box>
        <Box display="flex" gap="15px" padding="15px" paddingTop="0px">
          {Array.from(new Array(5)).map((title, index) => (
            <ContentBox key={index} containerClassName={'border-box'} height={'114px'} width="100%">
              <Box width="100%" display="flex">
                <Box
                  height="100%"
                  sx={{
                    display: 'flex',
                    gap: '1.5rem',
                    alignItems: 'center',
                    flexGrow: 1,
                  }}>
                  <Box
                    sx={{
                      marginBottom: '.5rem',
                    }}>
                    <Skeleton height="48px" width="48px" variant="circular" style={{ transform: 'none' }} />
                  </Box>
                  <Box width="100%">
                    <Box width="100%" marginBottom="10px">
                      <Skeleton width="100px" />
                    </Box>
                    <Box width="100%" marginBottom="15px">
                      <Skeleton width="100px" />
                    </Box>
                    <Box width="100%">
                      <Skeleton width="100px" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ContentBox>
          ))}
        </Box>
        <Box display="flex" gap="15px" padding="15px" paddingTop="0px">
          {Array.from(new Array(5)).map((title, index) => (
            <ContentBox key={index} containerClassName={'border-box'} height={'114px'} width="100%">
              <Box width="100%" display="flex">
                <Box
                  height="100%"
                  sx={{
                    display: 'flex',
                    gap: '1.5rem',
                    alignItems: 'center',
                    flexGrow: 1,
                  }}>
                  <Box
                    sx={{
                      marginBottom: '.5rem',
                    }}>
                    <Skeleton height="48px" width="48px" variant="circular" style={{ transform: 'none' }} />
                  </Box>
                  <Box width="100%">
                    <Box width="100%" marginBottom="10px">
                      <Skeleton width="100px" />
                    </Box>
                    <Box width="100%" marginBottom="15px">
                      <Skeleton width="100px" />
                    </Box>
                    <Box width="100%">
                      <Skeleton width="100px" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ContentBox>
          ))}
        </Box>
      </ContentBox>
    </PageContent>
  );
}

export default RulesSkeleton;
