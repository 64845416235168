import _ from 'lodash';
import {
  FETCH_RESOURCES_EVALUATION,
  RESET_RESOURCES_EVALUATION,
  LOAD_RESOURCE_EVALUATION_CONFIGURATION_DATA,
} from '../constants/actionTypes';

const defaultState = {
  data: [],
  status: 'fetching',
  configuration: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_RESOURCES_EVALUATION:
      if (!_.isEmpty(action.payload))
        return {
          ...state,
          ...action.payload,
        };
      return {
        status: 200,
        data: [],
        configuration: {},
      };
    case LOAD_RESOURCE_EVALUATION_CONFIGURATION_DATA:
      return {
        ...state,
        configuration: action.payload,
      };
    case RESET_RESOURCES_EVALUATION:
      return defaultState;
    default:
      return state;
  }
};
