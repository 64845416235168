import { UPDATE_DATA } from '@root/constants/actionTypes';

const initialState = {
  data: null,
};

const riskRremediation = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default riskRremediation;
