import { useMemo } from 'react';

function useOCRPermissions() {
  const ocrPermissions = useMemo(() => {
    const userAccessType = JSON.parse(localStorage.getItem('User Policy') || '[]')[0];

    if (!userAccessType) return null;

    const allOCRPermissions = JSON.parse(localStorage.getItem('User Policy Table') || '[]').filter(item =>
      item.menu_items.includes('One Click Remediation'),
    );

    if (!allOCRPermissions) return null;

    return allOCRPermissions.map(item => {
      return {
        name: item.sub_menu_items,
        allowed: userAccessType.toLowerCase().includes('all permissions') || item[userAccessType] === 'True',
      };
    });
  }, []);

  const isCreateAllowed = useMemo(() => {
    if (!ocrPermissions) return false;

    const createPermission = ocrPermissions.find(p => p.name.toLowerCase() === 'create policy');

    if (!createPermission) return false;

    return createPermission.allowed;
  }, [ocrPermissions]);

  const isRevokeAllowed = useMemo(() => {
    if (!ocrPermissions) return false;

    const revokePermission = ocrPermissions.find(p => p.name.toLowerCase() === 'revoke policy');

    if (!revokePermission) return false;

    return revokePermission.allowed;
  }, [ocrPermissions]);

  const isEditAllowed = useMemo(() => {
    if (!ocrPermissions) return false;

    const editPermission = ocrPermissions.find(p => p.name.toLowerCase() === 'edit policy');

    if (!editPermission) return false;

    return editPermission.allowed;
  }, [ocrPermissions]);

  const isRemediateAllowed = useMemo(() => {
    if (!ocrPermissions) return false;

    const permission = ocrPermissions.find(p => p.name.toLowerCase() === 'remediate');

    if (!permission) return false;

    return permission.allowed;
  }, [ocrPermissions]);

  return {
    isCreateAllowed,
    isEditAllowed,
    isRevokeAllowed,
    isRemediateAllowed,
  };
}

export { useOCRPermissions };
