import { LOCK_MENU_ITEMS, UNLOCK_MENU_ITEMS } from '@constants/actionTypes';
const defaultState = {
  isMenuLocked: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOCK_MENU_ITEMS:
      return {
        ...state,
        isMenuLocked: true,
      };
    case UNLOCK_MENU_ITEMS:
      return {
        ...state,
        isMenuLocked: false,
      };
    default:
      return state;
  }
};
