import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './components/falconReact/helpers/initFA';
import App from './App';
import Main from './Main';
import { store } from './store';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles'; // Legacy (deprecated) theme api
import { initializeSentry } from './sentry';

import { LicenseInfo } from '@mui/x-license-pro';
import { CloudProvider } from './components/v2/content/CloudProviderBar/CloudProviderContext';

const MUI_LICENSE_KEY: string = import.meta.env.VITE_MUI_KEY || '';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

// console.log(import.meta.env);
// import { ConnectedRouter } from 'connected-react-router';
// import { createBrowserHistory } from "history";
// const history = createBrowserHistory();
const theme = createTheme({});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: Infinity, // 10 minutes // changed to 30mins for onboarding case
      cacheTime: Infinity, // 15 minutes // changed to 30mins for onboarding case
      keepPreviousData: true,
      retryDelay: 10000,
      refetchOnWindowFocus: false,
    },
  },
});

initializeSentry();

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Provider store={store as any}>
          {/* <ConnectedRouter history={history}> */}
          <QueryClientProvider client={queryClient}>
            <Main>
              <CloudProvider>
                <App />
              </CloudProvider>
            </Main>
          </QueryClientProvider>

          {/* </ConnectedRouter> */}
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('main'),
);
