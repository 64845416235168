import React from 'react';
import { Box, Skeleton } from '@mui/material';

interface PageHeaderSkeletonProps {
  isRegionFilter?: boolean;
}

function PageHeaderSkeleton({ isRegionFilter }: PageHeaderSkeletonProps) {
  return (
    <div>
      <Box marginBottom="15px" display="flex" justifyContent="space-between">
        <Skeleton height="30px" width="150px" style={{ transform: 'none' }} />
        {isRegionFilter ? <Skeleton height="30px" width="150px" style={{ transform: 'none' }} /> : null}
      </Box>
    </div>
  );
}

export default PageHeaderSkeleton;
