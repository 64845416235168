import axios from 'axios';
import { axiosDelete } from './axiosDelete';
import { axiosGet } from './axiosGet';
import { axiosPost } from './axiosPost';
import { axiosPut } from './axiosPut';
const API_BASE_ROOT = import.meta.env.VITE_AXIOS_BASE_URL_ROOT;
export const axiosHelper = {
  axiosFetchTenant: async () => {
    try {
      // const tenantID = localStorage.getItem("tenantID");
      const token = localStorage.getItem('_t');
      const config = {
        headers: {
          authorization: 'Token ' + token,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      };
      return await axios.get(`${API_BASE_ROOT}/user_subtenant_list`, config);
    } catch (error) {
      console.error('HTTP GET request failed', error['message']);
    }
  },
  axiosGet,
  axiosPost,
  axiosDelete,
  axiosPut,
};
