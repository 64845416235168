import { SEND_RESOURCES } from '../constants/actionTypes';

const initialState = {
  resourceIds: [],
  resourceTypes: [],
};

const reducerRulesNew = (state = initialState, action) => {
  switch (action.type) {
    case SEND_RESOURCES: {
      const { resourceIds, resourceTypes } = action.payload;
      return {
        ...state,
        resourceIds: resourceIds,
        resourceTypes: resourceTypes,
      };
    }
    default:
      return state;
  }
};

export default reducerRulesNew;
