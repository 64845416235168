import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (localStorage.getItem('_t') ? <Component {...props} /> : <Redirect to="/login" />)}
  />
);

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (!localStorage.getItem('_t') ? <Component {...props} /> : <Redirect to="/" />)} />
);
