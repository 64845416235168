import { combineReducers } from 'redux';
import environments from './environments';
import user from './user';
import common from './common';
import mainDashboard from './mainDashboard';
import compliance from './compliance';
import risk from './risk';
import rules from './rules';
import new_scan_report from './newScanReport';
// import scan_report from './scan_report';
import remediation from './remediation';
import scan_history from './scan_history';
import websocket from './websocket';
import notification from './notification';
import historyState from './history';
import scanner from './scanner';
import inventory from './inventory';
import resourceEvaluation from './resourceEvaluation';
import resourceDetails from './resourceDetails';
import rbac from './rbac';
import onboarding from './onboarding';
import policyRes from './policyRestriction';
import global from './global';
import oneClick from './oneClick';
import inProgress from './inProgress';
import tenant from './tenant';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import new_scan_report2 from './newScanReport2';
import inventory2 from './inventory2';
import timeFilterReducer from './timeFilterReducer';
import riskRremediation from './remediation_state';
import resourcelistReducer from './resourceList';
import reducerRulesNew from './RulesNew';
import editEnvironmentReducer from './editEnviroment';

const history = createBrowserHistory();

export default combineReducers({
  environments,
  websocket,
  notification,
  mainDashboard,
  risk,
  rules,
  user,
  common,
  compliance,
  new_scan_report,
  // scan_report,
  new_scan_report2,
  scan_history,
  remediation,
  historyState,
  resourceDetails,
  resourceEvaluation,
  scanner,
  onboarding,
  inventory,
  rbac,
  policyRes,
  global,
  oneClick,
  inProgress,
  tenant,
  inventory2,
  timeFilterReducer,
  riskRremediation,
  resourcelistReducer,
  reducerRulesNew,
  editEnvironmentReducer,
  router: connectRouter(history),
});
