import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { SubmitButton } from '@root/components/v2/ui-elements/SubmitButton';
import _ from 'lodash';

const TenantSwitchingNew = props => {
  return (
    <div className="TenatSwitching__Container">
      <div className="TenatSwitching__SearchContainer">
        <FontAwesomeIcon icon={faSearch} className="icon" />
        <input type="text" placeholder="Search Tenant Name" value={props.value} onChange={props.onSearch} />
      </div>
      <div className="TenatSwitching__ContnentContainer CustomScrollbar">
        {_.isEmpty(props.templist) ? (
          <div className="TenatSwitching__EmptyList">No tenants</div>
        ) : (
          props.templist?.map(
            element =>
              element['tenant_name'] != localStorage.getItem('tenantName') && (
                <>
                  {/* <div className="col-1">
                  <input
                    type="radio"
                    checked={element['isChecked']}
                    id={element['id']}
                    name="radio"
                    onChange={() => {
                      props.onTenantSelect(element);
                    }}
                    // label = {element['tenant_name']}
                  />
                </div> */}
                  <Box
                    className={`TenatSwitching__Item ${
                      props.tempTenant.find(tenant => tenant.tenant_name === element.tenant_name)
                        ? 'TenatSwitching__Item--checked'
                        : ''
                    }`}
                    key={element['id']}
                    onClick={() => {
                      props.onTenantSelect(element);
                    }}>
                    {element['tenant_name']}
                  </Box>
                </>
              ),
          )
        )}
      </div>

      <div className="TenatSwitching__Footer">
        <SubmitButton
          onClick={() => {
            props.setTenant();
          }}
          disabled={props.checked}
          text={'Confirm'}
          style={{
            height: '23px',
            padding: '0 13px',
            margin: '10px 50px',
            fontSize: '12px',
            fontWeight: '500',
            borderRadius: '4px',
          }}
        />
      </div>
    </div>
  );
};

export default TenantSwitchingNew;
