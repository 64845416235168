import { Box } from '@mui/material';
import { GreyCheckBox } from '@root/assets/svg/SVGComponents';

type PermissionProps = {
  name: string;
  p1?: boolean;
  p2?: boolean;
};

const Permission = ({ name, p1, p2 }: PermissionProps) => {
  return (
    <div className="ProfilePermissions__Line">
      <div className="ProfilePermissions__Name">{name}</div>
      <div className="ProfilePermissions__P1">{p1 ? <GreyCheckBox /> : <Box height={'14px'} width={'14px'} />}</div>
      <div className="ProfilePermissions__P2">{p2 ? <GreyCheckBox /> : <Box height={'14px'} width={'14px'} />}</div>
    </div>
  );
};

export default Permission;
