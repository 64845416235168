import React, { useMemo } from 'react';
import { default as MUIButton } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const PRIMARY_COLOR = '#194BFB';
const NEUTRAL_COLOR = '#F7FAFC';

const ColorButton = styled(MUIButton)(({ textColor, bgColor, border, className }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    lineHeight: 1,
    backgroundColor: bgColor,
    color: textColor,
    height: 36,
    borderRadius: 4,
    ...((className || '').includes('DisableCapitalization') && { textTransform: 'none' }),
    ...(border && { border }),
    '&:hover': {
      backgroundColor: bgColor,
      opacity: 0.8,
    },
    '&:active': {
      opacity: 1,
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    '&[disabled]': {
      color: '#fff',
      opacity: 0.5,
    },
  };
});

/**
 *
 * @param {*} param object
 * @param {*} param.variant contained|borderless
 * @param {*} param.backgroundColor any color code or 'primary', 'neutral'
 * @param {*} param.textColor The text color. Any color code.
 * @returns
 */
function Button({
  children,
  backgroundColor = 'primary',
  disableCapitalization = false,
  isLoading = false,
  color,
  ...props
}) {
  const _color = useMemo(() => {
    if (backgroundColor === 'primary') return PRIMARY_COLOR;
    if (backgroundColor === 'neutral') return NEUTRAL_COLOR;

    return backgroundColor;
  }, [backgroundColor]);

  const _textColor = useMemo(() => {
    if (color) return color;

    if (backgroundColor === 'primary') return '#fff';
    if (backgroundColor === 'neutral') return '#484D56';

    return '#fff';
  }, [color, backgroundColor]);

  const _border = useMemo(() => {
    if (backgroundColor === 'neutral') {
      return '1px solid #CBD5E0';
    }

    return null;
  }, [backgroundColor]);

  return (
    <ColorButton
      bgColor={_color}
      textColor={_textColor}
      border={_border}
      className={`${props.className || ''} ${disableCapitalization ? 'DisableCapitalization' : ''}`}
      {...props}>
      {children}

      {isLoading ? <CircularProgress size="16px" style={{ position: 'absolute', color: '#fff' }} /> : null}
    </ColorButton>
  );
}

export default Button;
