import ArrowIcon from "@assets/svg/icons/arrow_icon.svg";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import InputForm from "./forms/inputForm";
import SettingsForm from "./forms/settings";
import SetupForm from "./forms/setup";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import agent from "@root/agent.js";
// import FullscreenLoader from "@root/components/FulscreenLoader";
import { Box, Skeleton } from "@mui/material";

// import CloudProviderBarSkeleton from "@root/components/v2/content/CloudProviderBar/skeleton";
import { useOCRPermissions } from "@root/hooks/permissions";
import { validateEmailAddress } from "@root/utilities/validators/email";

import createFormIcon from "@assets/svg/icons/create-form.svg";
import { Menu, MenuItem } from "@mui/material";
// import CloudProviderBar from "@root/components/v2/content/CloudProviderBar";
import ContentBox from "@root/components/v2/content/ContentBox";
import PageContainer from "@root/components/v2/layout/PageContainer";
import PageContent from "@root/components/v2/layout/PageContent";
import PageHeader from "@root/components/v2/layout/PageHeader";
import {
  useEnvironments,
  useSelectedEnvironments,
} from "@root/hooks/environments";
import "./style.scss";
import { useCloudProvider } from "@root/components/v2/content/CloudProviderBar/CloudProviderContext";
const FORM_STAGES = {
  setup: "setup",
  input: "input",
  settings: "settings",
};

const DEMO_DATA = {
  env: ["887634214489", "887634214480"],
  services: ["Lambda", "CloudWatch", "DynamoDB", "EC2"],
  rules: ["Ensue APIs has logging enabled", "Use client side SSL"],
  regions: ["us-east-1", "us-west-1", "us-south-1", "us-north-1"],
  resourceTypes: ["client-side-ssl", "server-side"],
  notification: ["Email", "Slack", "Jira"],
};

const setupInitialState = {
  policyName: null,
  envId: null,
  environment: null,
};

const inputInitialState = {
  service: null,
  resourceType: null,
  resourceTags: [],
  regions: [],

  isRegionAny: false,
  resources: [],
  isResourceAny: false,
  resources_auto: [],
  isResourceAutoAny: false,
  resources_manual: [],
  isResourceManualAny: false,
  rule: null,
  customInput: {},
};

const settingsInitialState = {
  allowManual: true,
  allowAutomated: false,
  notification: false,
  notificationConfig: null,
  notificationDestination: {},
  notificationEmail: "",
  notificationFreq: {
    remediation_initiation: false,
    remediation_execution: false,
  },
};

const AddRemediationPolicy = () => {
  const history = useHistory();
  const routeParams = useParams();

  const [policyMeta, setPolicyMeta] = useState(null);
  // const [environments, setEnvironments] = useState([]);
  const [policyNames, setPolicyNames] = useState([]);

  const [isFetchingResources, setIsFetchingResources] = useState(false);
  const isFetchingResourcesRef = useRef(false);
  isFetchingResourcesRef.current = isFetchingResources;
  const [isCreatingPolicy, setIsCreatingPolicy] = useState(false);

  const [isEditMode] = useState(Boolean(routeParams.policyId));
  const [existingPolicyData, setExistingPolicyData] = useState(null);

  const [resetMenuAnchorEl, setResetMenuAnchorEl] = useState(null);
  const { selectedEnvironments } = useSelectedEnvironments();

  const { isEditAllowed } = useOCRPermissions();

  const [disableNext, setDisableNext] = useState(false);
  const [errorInFetchingResource, setErrorInFetchingResource] = useState(false);

  const userInfo = useSelector((state) => {
    return state.user.info.user;
  });
  const [currentSetup, setCurrentSetup] = React.useState(setupInitialState);
  const [currentInput, setCurrentInput] = React.useState(inputInitialState);
  const [currentSetting, setCurrentSetting] =
    React.useState(settingsInitialState);

  const { cloudProvider } = useCloudProvider();
  const [currentStage, setCurrentStage] = React.useState(FORM_STAGES["setup"]);

  const [canShowSetupErrors, setCanShowSetupErrors] = React.useState(false);

  const [canContinue, setCanContinue] = useState(false);

  const [setupErrorMessages, setSetupErrorMessages] = useState({
    nameError: "",
    environmentIdError: "",
  });

  // Input form states =======================
  const [services, setServices] = useState([]);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [rules, setRules] = useState([]);
  const [resources, setResources] = useState([]);
  const [alreadyExistingPolicy, setAlreadyExistingPolicy] = useState(null);
  const [isFetchingPolicy, setIsFetchingPolicy] = useState(null);
  const allEnvironments = useEnvironments();
  const environments = useMemo(() => {
    if (selectedEnvironments.length && cloudProvider !== "all")
      return selectedEnvironments.filter(
        (environment) => environment.cloudProvider === cloudProvider
      );

    return allEnvironments;
  }, [selectedEnvironments, allEnvironments, cloudProvider]);
  const isPolicyNameAlreadyExisting = useMemo(() => {
    const existingPolicyName = policyNames.find(
      (name) =>
        name.toLowerCase() ===
        (currentSetup.policyName || "").trim().toLowerCase()
    );

    if (
      isEditMode &&
      existingPolicyName &&
      existingPolicyName.toLowerCase() ===
        existingPolicyData.policy_name.toLowerCase()
    ) {
      return false;
    }

    return Boolean(existingPolicyName);
  }, [isEditMode, existingPolicyData, currentSetup.policyName, policyNames]);
  const handleReset = useCallback(
    (resetType) => {
      setResetMenuAnchorEl(null);
      if (resetType === "current-page") {
        if (currentStage === FORM_STAGES.setup) {
          setCurrentSetup(setupInitialState);
        } else if (currentStage === FORM_STAGES.input) {
          setCurrentInput(inputInitialState);
        } else if (currentStage === FORM_STAGES.settings) {
          setCurrentSetting(settingsInitialState);
        }
      } else {
        // Reset all pages
        setCurrentSetup(setupInitialState);
        setCurrentInput(inputInitialState);
        setCurrentSetting(settingsInitialState);
        setCurrentStage(FORM_STAGES.setup);
      }
    },
    [currentStage]
  );

  // useEffect(() => {
  //   setEnvironments(selectedEnvironments);
  // }, [selectedEnvironments]);

  useEffect(() => {
    console.log(currentInput, "testing");
  }, [currentInput]);

  const handleSubmit = useCallback(async () => {
    let payload;
    if (!isEditMode) {
      payload = {
        policy_id: uuidv4().replace(/-/g, ""),
        master_rule_id: currentInput.rule.ruleId,

        // SETUP
        policy_name: currentSetup.policyName,
        environment_id: currentSetup.envId.value,
        environment_name: policyMeta.data.environments.find(
          (item) => item.enviromentId === currentSetup.envId.data.id
        ).enviromentName,
        environment_tags: currentSetup.envId.data.tags,

        // INPUT
        service: currentInput.service.value,
        resource_type: currentInput.resourceType.value,
        region: {
          is_all: currentInput.isRegionAny,
          regions: currentInput.regions,
        },
        rule: currentInput.rule.description,
        resource_tags: null,
        resources: {
          is_all: currentInput.isResourceAny,
          resources: currentInput.resources.length
            ? currentInput.resources.map((r) => ({
                resource_id: r.resource_id,
                resource_name: r.resource_name,
              }))
            : [],
        },
        resources_auto: {
          is_all: currentInput.isResourceAutoAny,
          resources: currentInput.resources_auto.length
            ? currentInput.resources_auto.map((r) => ({
                resource_id: r.resource_id,
                resource_name: r.resource_name,
              }))
            : [],
        },
        resources_manual: {
          is_all: currentInput.isResourceManualAny,
          resources: currentInput.resources_manual.length
            ? currentInput.resources_manual.map((r) => ({
                resource_id: r.resource_id,
                resource_name: r.resource_name,
              }))
            : [],
        },
        custom_input: currentInput.customInput,

        // SETTINGS
        allow_manual: currentSetting.allowManual,
        allow_automated: currentSetting.allowAutomated,
        is_notification_allowed: currentSetting.notification,
        notification_channel: {
          email: {
            enabled: currentSetting.notificationDestination.value === "Email",
            email_address: currentSetting.notificationEmail || "",
          },
          jira: {
            enabled: currentSetting.notificationDestination.value === "Jira",
          },
          slack: {
            enabled: currentSetting.notificationDestination.value === "Slack",
          },
        },
        notification_frequency: [
          {
            type: "remediation_initiation",
            enabled: currentSetting.notificationFreq.remediation_initiation,
          },
          {
            type: "remediation_execution",
            enabled: currentSetting.notificationFreq.remediation_execution,
          },
        ],
        is_rollback_supported: currentInput.rule.ruleData.isRollback,
        created_by: userInfo.email,
        created_by_name: userInfo.name,
        created_at: new Date(),
        // updated_by: null,
        // updated_at: null,
      };
    } else {
      // Edit mode
      payload = {
        // SETUP
        policy_name: currentSetup.policyName,

        // INPUT
        region: {
          is_all: currentInput.isRegionAny,
          regions: currentInput.regions,
        },
        resources: {
          is_all: currentInput.isResourceAny,
          resources: currentInput.resources.length
            ? currentInput.resources.map((r) => ({
                resource_id: r.resource_id,
                resource_name: r.resource_name,
              }))
            : [],
        },
        resources_auto: {
          is_all: currentInput.isResourceAutoAny,
          resources: currentInput.resources_auto.length
            ? currentInput.resources_auto.map((r) => ({
                resource_id: r.resource_id,
                resource_name: r.resource_name,
              }))
            : [],
        },
        resources_manual: {
          is_all: currentInput.isResourceManualAny,
          resources: currentInput.resources_manual.length
            ? currentInput.resources_manual.map((r) => ({
                resource_id: r.resource_id,
                resource_name: r.resource_name,
              }))
            : [],
        },
        custom_input: currentInput.customInput,

        // SETTINGS
        allow_manual: currentSetting.allowManual,
        allow_automated: currentSetting.allowAutomated,
        is_notification_allowed: currentSetting.notification,
        created_by_name: userInfo.name,
        notification_channel: {
          email: {
            enabled: currentSetting.notificationDestination.value === "Email",
            email_address: currentSetting.notificationEmail || "",
          },
          jira: {
            enabled: currentSetting.notificationDestination.value === "Jira",
          },
          slack: {
            enabled: currentSetting.notificationDestination.value === "Slack",
          },
        },
        notification_frequency: [
          {
            type: "remediation_initiation",
            enabled: currentSetting.notificationFreq.remediation_initiation,
          },
          {
            type: "remediation_execution",
            enabled: currentSetting.notificationFreq.remediation_execution,
          },
        ],
      };
    }
    setIsCreatingPolicy(true);

    try {
      if (isEditMode) {
        const response = await agent.OCR.update_policy({
          id: existingPolicyData.id,
          updatedBy: userInfo.email,

          payload,
        });

        if (response.error) {
          throw new Error(response.msg);
        } else {
          toast("Policy updated successfully.", {
            position: "top-right",
            type: "success",
            newestOnTop: true,
          });
        }
      } else {
        const response = await agent.OCR.create_policy(payload);

        if (response.error) {
          throw new Error(response.msg);
        } else {
          toast("Policy created successfully.", {
            position: "top-right",
            type: "success",
            newestOnTop: true,
          });
        }
      }
      history.replace("/remediation-policy");
    } catch (err) {
      toast(
        `Failed to ${isEditMode ? "update" : "create"} policy. ${err.message}`,
        {
          position: "top-right",
          type: "error",
          newestOnTop: true,
        }
      );
    }
    setIsCreatingPolicy(false);
  }, [
    currentSetup,
    currentInput,
    currentSetting,
    userInfo,
    isEditMode,
    existingPolicyData,
    history,
    policyMeta?.data?.environments,
  ]);

  // Check OCR edit permissions
  useEffect(() => {
    if (!isEditAllowed) {
      history.replace("/remediation-policy");
    }
  }, [isEditAllowed, history]);

  // Fetch all policy names
  useEffect(() => {
    (async () => {
      try {
        const response = await agent.OCR.fetch_policy_names();

        if (response.error) {
          throw new Error(response.msg);
        } else {
          setPolicyNames(response.data.policies || []);
        }
      } catch (err) {
        toast(`Failed to fetch policy names. ${err.message}`, {
          position: "top-right",
          type: "error",
          newestOnTop: true,
        });
      }
    })();
  }, []);

  // Validate Policy form
  useEffect(() => {
    let _canContinue = true;

    let _nameError = "";
    let _envIdError = "";

    if (!currentSetup.policyName) {
      _nameError = "Policy name is required.";
      _canContinue = false;
    } else if (currentSetup.policyName.length > 50) {
      _nameError = "Maximum length is 50 characters.";
      _canContinue = false;
    }

    if (!currentSetup.envId) {
      _envIdError = "Environment ID is required.";
      _canContinue = false;
    } else {
      _envIdError = "";
    }

    if (currentSetup.ocrSetupRequired) {
      _canContinue = false;
    }

    setCanContinue(_canContinue);
    setSetupErrorMessages({
      nameError: _nameError,
      environmentIdError: _envIdError,
    });
    return;
  }, [currentSetup, isPolicyNameAlreadyExisting]);

  const formsMap = {
    setup: () => (
      <SetupForm
        isEditMode={isEditMode}
        DEMO_DATA={DEMO_DATA}
        META={policyMeta}
        setDisableNext={setDisableNext}
        environments={environments}
        currentSetup={currentSetup}
        setCurrentSetup={setCurrentSetup}
        setupErrorMessages={setupErrorMessages}
        canShowSetupErrors={canShowSetupErrors}
        isPolicyNameAlreadyExisting={isPolicyNameAlreadyExisting}
      />
    ),
    input: () => (
      <InputForm
        isEditMode={isEditMode}
        DEMO_DATA={DEMO_DATA}
        setDisableNext={setDisableNext}
        META={policyMeta}
        currentSetup={currentSetup}
        currentInput={currentInput}
        setCurrentInput={setCurrentInput}
        {...{
          services,
          setServices,
          resourceTypes,
          setResourceTypes,
          rules,
          setRules,
          resources,
          setResources,
          alreadyExistingPolicy,
          setAlreadyExistingPolicy,
        }}
      />
    ),
    settings: () => (
      <SettingsForm
        isEditMode={isEditMode}
        DEMO_DATA={DEMO_DATA}
        META={policyMeta}
        currentSetup={currentSetup}
        currentSetting={currentSetting}
        setCurrentSetting={setCurrentSetting}
        currentInput={currentInput}
        setCurrentInput={setCurrentInput}
        rules={rules}
        {...{
          resources,
          setResources,
        }}
      />
    ),
    // settings: () => (
    //   <SettingsForm
    //     DEMO_DATA={DEMO_DATA}
    //     currentSetup={currentSetup}
    //     setCurrentSetup={setCurrentSetup}
    //   />
    // ),
  };

  // Fetch policy data for editing
  useEffect(() => {
    if (!routeParams.policyId) return;

    (async () => {
      try {
        setIsFetchingPolicy(true);
        let response = await agent.OCR.fetch_policy_by_policy_id(
          routeParams.policyId
        );

        if (response.error) {
          throw new Error(response.msg);
        }

        response.data = response.data[0];
        setExistingPolicyData(response.data);

        if (response.data) {
          const { policy_name, environment_id, service, resource_type } =
            response.data;

          setCurrentSetup({
            policyName: policy_name,
            envId: {
              value: environment_id,
              label: environment_id,
            },
          });

          const _regionsData = response.data.region;

          setCurrentInput((prev) => ({
            ...prev,
            service: {
              value: service,
              label: service,
            },
            resourceType: {
              label: resource_type,
              value: resource_type,
            },
            resources: response.data.resources.resources,
            resources_auto: response.data.resources_auto.resources,
            resources_manual: response.data.resources_manual.resources,
            isResourceAny: response.data.resources.is_all,
            isResourceAutoAny: response.data.resources_auto.is_all,
            isResourceManualAny: response.data.resources_manual.is_all,
            isRegionAny: _regionsData.is_all,
            regions: _regionsData.regions,
            // isRegionAny: _regionsData.find((region) => region === "global"),
            // regions: _regionsData,
            rule: {
              ruleId: response.data.master_rule_id,
              label: response.data.rule,
              value: response.data.rule,
            },
            customInput: response.data.custom_input || "{}",
          }));
        }
        setIsFetchingPolicy(false);

        const notificationChannelData = JSON.parse(
          response.data.notification_channel
        );

        const notificationDestination = {
          Email: "Email",
          Slack: "Slack",
          Jira: "Jira",
        }[
          notificationChannelData.email.enabled
            ? "Email"
            : notificationChannelData.jira.enabled
            ? "Jira"
            : notificationChannelData.slack.enabled
            ? "Slack"
            : ""
        ];

        const notificationFrequencyData = JSON.parse(
          response.data.notification_frequency || "[]"
        );

        const remediationInitiationNotification =
          notificationFrequencyData.find(
            (item) => item.type === "remediation_initiation"
          );
        const remediationExecutionNotification = notificationFrequencyData.find(
          (item) => item.type === "remediation_execution"
        );

        setCurrentSetting((prev) => ({
          ...prev,
          allowManual: response.data.allow_manual,
          allowAutomated: response.data.allow_automated,
          notification: response.data.is_notification_allowed,
          notificationDestination: notificationDestination
            ? {
                value: notificationDestination,
                label: notificationDestination,
              }
            : {},
          notificationEmail:
            notificationDestination === "Email"
              ? notificationChannelData.email.email_address
              : "",
          notificationFreq: {
            remediation_initiation: remediationInitiationNotification
              ? remediationInitiationNotification.enabled
              : false,
            remediation_execution: remediationExecutionNotification
              ? remediationExecutionNotification.enabled
              : false,
          },
        }));
      } catch (err) {
        toast(`Failed to fetch policy data. ${err.message}`, {
          position: "top-right",
          type: "error",
          newestOnTop: true,
        });
      }
    })();
  }, [routeParams]);

  // Fetch resources list based on Service and Resource Type selection
  useEffect(() => {
    (async () => {
      if (!policyMeta || !currentSetup.envId || !currentInput.resourceType) {
        return;
      }

      if (isEditMode) {
        if (!environments.length) return;
      } else {
        if (!currentSetup.envId.data) return;
      }

      if (isFetchingResourcesRef.current) return;

      setIsFetchingResources(true);

      try {
        const data = await agent.OCR.get_resources({
          environmentId: currentSetup.envId.value,
          resourceType: currentInput.resourceType.value,
          scanId: policyMeta?.data.environments.filter(
            (item) => item.enviromentId === currentSetup.envId?.value.toString()
          )[0]?.scanId,
        });

        if (data.error) {
          setErrorInFetchingResource(true);
          throw new Error(data.msg);
        }
        setErrorInFetchingResource(false);
        setResources(
          data.data.map((item) => ({
            resource_name: item.resource_name,
            resource_id: item.resource_id,
          }))
        );

        if (!isEditMode) {
          setCurrentInput((prev) => ({
            ...prev,
            resources: data.data.map((item) => ({
              resource_name: item.resource_name,
              resource_id: item.resource_id,
            })),
          }));
        }
      } catch (err) {
        setErrorInFetchingResource(true);

        toast(`Failed to fetch resource list. ${err.message}`, {
          position: "top-right",
          type: "error",
          newestOnTop: true,
        });
      }

      setIsFetchingResources(false);
    })();
  }, [
    isEditMode,
    environments,
    policyMeta,
    currentSetup.envId,
    currentInput.resourceType,
  ]);

  // Handle Service being changed
  useEffect(() => {
    if (isEditMode) return;
    if (!currentInput.service) return;
    if (!policyMeta) return;

    const _resourceTypes = policyMeta.data.rulesDetails
      .filter((r) => r.service === currentInput.service.value)
      .map((r) => r.resourceType)
      .reduce((acc, curr) => {
        if (!acc.includes(curr)) return [...acc, curr];
        return acc;
      }, []);
    setResourceTypes(_resourceTypes);

    setCurrentInput((currentConfig) => ({
      ...currentConfig,
      resourceType: null,
      rule: null,
      customInput: {},
      resources: [],
    }));
  }, [currentInput.service, policyMeta, isEditMode]);

  // Handle Resource Type being changed
  useEffect(() => {
    if (!currentInput.service) return;
    if (!currentInput.resourceType) return;
    if (!policyMeta) return;

    const _rules = policyMeta.data.rulesDetails
      .filter((r) => r.resourceType === currentInput.resourceType.value)
      .map((r) => ({
        ruleId: r.rulesId,
        description: r.ruleDescription,
        ruleData: r,
      }))
      .reduce((acc, curr) => {
        // filter out duplicates
        if (!acc.find((r) => r.description === curr.description))
          return [...acc, curr];
        return acc;
      }, []);

    setRules(_rules);

    if (!isEditMode) {
      setCurrentInput((currentConfig) => ({
        ...currentConfig,
        rule: null,
        customInput: {},
        resources: [],
      }));
    }
  }, [isEditMode, currentInput.resourceType, policyMeta, currentInput.service]);

  // Handle Rule being changed
  useEffect(() => {
    if (isEditMode) return;

    setCurrentInput((currentConfig) => ({
      ...currentConfig,
      customInput: {},
    }));
  }, [isEditMode, currentInput.rule]);

  // Fetch environments
  useEffect(() => {
    (async () => {
      try {
        const response = await agent.OCR.get_policy_meta();
        // setEnvironments(response.data.environments);

        if (response.error) {
          throw new Error(response.msg);
        }

        setPolicyMeta(response);
      } catch (err) {
        toast(`Failed to fetch meta. ${err.message}`, {
          position: "top-right",
          type: "error",
          newestOnTop: true,
        });
      }
    })();
  }, []);

  return (
    // !policyMeta ||
    // !policyNames.length ||
    isFetchingPolicy || isCreatingPolicy || isFetchingResources ? (
      <>
        <PageContainer>
          <PageHeader title="Policies" />

          <PageContent>
            {/* <CloudProviderBarSkeleton /> */}

            <ContentBox paddingMode="content" marginBottom="15px" height="80vh">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  marginLeft="15px"
                  width="40px"
                  height="40px"
                  className="Borders RoundedCorners"
                >
                  <Skeleton variant="rectangular" width="40px" height="40px" />
                </Box>

                <Box
                  width="100%"
                  className="Borders RoundedCorners"
                  marginLeft="15px"
                  marginRight="15px"
                  marginBottom="15px"
                  marginTop="15px"
                >
                  <Skeleton height="40px" style={{ transform: "none" }} />
                </Box>
              </Box>
              <Box
                marginLeft="15px"
                marginRight="15px"
                marginBottom="15px"
                display="flex"
              >
                <Box
                  width="85%"
                  marginRight="15px"
                  className="Borders RoundedCorners"
                  height="260px"
                >
                  <Box padding="15px" paddingBottom="0px">
                    <Skeleton height="30px" width="100px" />
                    <Box
                      marginTop="10px"
                      width="100%"
                      className="Borders RoundedCorners"
                    >
                      <Skeleton height="40px" style={{ transform: "none" }} />
                    </Box>
                  </Box>
                  <Box padding="15px" paddingBottom="0px">
                    <Skeleton height="30px" width="100px" />
                    <Box
                      marginTop="10px"
                      width="100%"
                      className="Borders RoundedCorners"
                    >
                      <Skeleton height="40px" style={{ transform: "none" }} />
                    </Box>
                  </Box>
                  <Box
                    marginTop="20px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box marginRight="20px">
                      <Skeleton
                        variant="rectangular"
                        width="80px"
                        height="30px"
                      />
                    </Box>
                    <Skeleton
                      variant="rectangular"
                      width="80px"
                      height="30px"
                    />
                  </Box>
                </Box>
                <Box
                  width="15%"
                  className="Borders RoundedCorners"
                  height="150px"
                >
                  <Box display="flex" flexDirection="column" height="100%">
                    <Box padding="10px">
                      <Skeleton height="30px" width="100px" />
                    </Box>
                    <Box padding="10px">
                      <Skeleton height="30px" width="100px" />
                    </Box>
                    <Box padding="10px">
                      <Skeleton height="30px" width="100px" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ContentBox>
          </PageContent>
        </PageContainer>
      </>
    ) : (
      <PageContainer>
        <PageHeader
          title="Policies"
          breadcrumb
          subtitle={
            !isEditMode
              ? "Create Remediation Policy"
              : "Edit Remediation Policy"
          }
          path="/remediation-policy"
        />
        <PageContent>
          {/* <CloudProviderBar
            cloudProvider={cloudProvider}
            // onChange={handleCloudProviderChange}
          /> */}
          <ContentBox minHeight="70vh">
            <div
              style={{
                color: "#1A202C",
                fontSize: "20px",
                fontFamily: "Manrope",
                fontWeight: 700,
                marginBottom: "1rem",
                paddingBottom: "1rem",
                borderBottom: "1px solid #CBD5E0",

                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              {isEditMode ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <g clip-path="url(#clip0_10349_541374)">
                    <rect width="30" height="30" rx="4" fill="#194BFB" />
                    <path
                      d="M23.2934 11.9058C23.7469 11.4523 24 10.8477 24 10.2044C24 9.56107 23.7504 8.95643 23.2934 8.50295L21.5006 6.71011C21.0436 6.24959 20.4389 6 19.7956 6C19.1523 6 18.5477 6.24959 18.0942 6.70659L6.91177 17.889C6.28604 18.5147 5.95559 19.376 6.00481 20.2584L6.00129 23.1199C6.00129 23.3519 6.09269 23.5769 6.25791 23.7421C6.42314 23.9073 6.64461 23.9987 6.88014 23.9987L9.74165 23.9952C10.624 24.0444 11.4853 23.714 12.111 23.0882C12.4555 22.7437 12.4555 22.1883 12.111 21.8438C11.7665 21.4993 11.2111 21.4993 10.8666 21.8438C10.5924 22.118 10.2092 22.2621 9.8225 22.2375C9.80493 22.2375 9.78383 22.234 9.76626 22.234L7.75898 22.241L7.76249 20.2337C7.76249 20.2162 7.76249 20.1951 7.75898 20.1775C7.73437 19.7908 7.8785 19.4076 8.1527 19.1334L17.472 9.81418L20.1858 12.528L13.1832 19.5307C12.8387 19.8752 12.8387 20.4306 13.1832 20.7751C13.5277 21.1196 14.0831 21.1196 14.4276 20.7751L23.2934 11.9058ZM18.7164 8.56974L19.3386 7.94752C19.4617 7.82448 19.6234 7.75769 19.7991 7.75769C19.9714 7.75769 20.1366 7.82448 20.2596 7.94752L22.0525 9.74036C22.3056 9.99346 22.3056 10.4083 22.0525 10.6614L21.4303 11.2836L18.7164 8.56974Z"
                      fill="white"
                    />
                    <path
                      d="M23.1212 22.241H15.194C14.7089 22.241 14.3151 22.6347 14.3151 23.1199C14.3151 23.605 14.7089 23.9987 15.194 23.9987H23.1212C23.6063 23.9987 24 23.605 24 23.1199C24 22.6347 23.6063 22.241 23.1212 22.241Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_10349_541374">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <img src={createFormIcon} />
              )}
              <span style={{ marginLeft: "10px" }}>
                {isEditMode ? "Edit" : "Create"} Remediation Policy
              </span>
            </div>

            <div style={{ display: "flex" }}>
              <ContentBox width="100%">
                <div id="main">
                  <div id="form">
                    {formsMap[currentStage]()}

                    <div className="form-footer">
                      <div className="CreatePolicyForm__ButtonsContainer">
                        {currentStage == FORM_STAGES.input ||
                        currentStage == FORM_STAGES.settings ? (
                          <Button
                            className="Button--Grey"
                            onClick={() => {
                              if (currentStage == FORM_STAGES.input) {
                                setCurrentStage(FORM_STAGES.setup);
                              } else {
                                setCurrentStage(FORM_STAGES.input);
                              }
                            }}
                          >
                            Previous
                          </Button>
                        ) : null}

                        {!isEditMode ? (
                          <Button
                            bsPrefix=""
                            className="Button--Green"
                            onClick={(e) => {
                              setResetMenuAnchorEl(e.target);
                            }}
                          >
                            Reset
                          </Button>
                        ) : null}

                        <Menu
                          open={Boolean(resetMenuAnchorEl)}
                          anchorEl={resetMenuAnchorEl}
                          onClose={() => setResetMenuAnchorEl(null)}
                          anchorOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                          }}
                          transformOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem onClick={() => handleReset("current-page")}>
                            Reset current page
                          </MenuItem>
                          <MenuItem onClick={() => handleReset("all-pages")}>
                            Reset all pages
                          </MenuItem>
                        </Menu>

                        {currentStage == FORM_STAGES.setup ||
                        currentStage == FORM_STAGES.input ? (
                          <Button
                            className="Button--Blue"
                            onClick={() => {
                              if (currentStage == FORM_STAGES.setup) {
                                setCanShowSetupErrors(true);

                                if (!canContinue) return;
                                setCurrentStage(FORM_STAGES.input);
                              } else {
                                setCurrentStage(FORM_STAGES.settings);
                              }
                            }}
                            disabled={
                              (currentStage === FORM_STAGES.setup &&
                                (isPolicyNameAlreadyExisting ||
                                  setupErrorMessages.nameError ||
                                  setupErrorMessages.environmentIdError ||
                                  !currentSetup.policyName ||
                                  !currentSetup.policyName.trim() ||
                                  !currentSetup.envId)) ||
                              (currentStage === FORM_STAGES.input &&
                                !alreadyExistingPolicy &&
                                (!currentInput.service ||
                                  !currentInput.resourceType ||
                                  (!currentInput.regions.length &&
                                    !currentInput.isRegionAny) ||
                                  !currentInput.rule)) ||
                              // isEditMode ||
                              disableNext ||
                              errorInFetchingResource
                            }
                          >
                            Next
                          </Button>
                        ) : (
                          <Button
                            className="Button--Blue"
                            onClick={handleSubmit}
                            disabled={
                              (!currentSetting.allowManual &&
                                !currentSetting.allowAutomated) ||
                              (currentSetting.notification &&
                                currentSetting.notificationDestination.value ===
                                  "Email" &&
                                !validateEmailAddress(
                                  currentSetting.notificationEmail
                                ))
                              // (currentSetting.notification &&
                              //   !currentSetting.notificationFreq
                              //     .remediation_execution &&
                              //   !currentSetting.notificationFreq
                              //     .remediation_initiation)
                            }
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ContentBox>

              <ContentBox width="370px" height="fit-content" marginLeft="15px">
                <div id="sidebar">
                  <div
                    className="header"
                    style={{ display: "flex", marginBottom: "25px" }}
                  >
                    {currentStage == FORM_STAGES.setup ? (
                      <img
                        src={ArrowIcon}
                        style={{ marginRight: "16px" }}
                      ></img>
                    ) : (
                      <div className="NewPolicy__Sidebar__IconSpacer"></div>
                    )}
                    <div className="section">
                      <span
                        style={{
                          color: "#1A202C",
                          fontSize: "16px",
                          fontFamily: "Manrope",
                          fontWeight: 700,
                        }}
                      >
                        Setup
                      </span>
                      {currentStage !== FORM_STAGES.setup &&
                        currentSetup.policyName !== null &&
                        currentSetup.envId !== null && (
                          <button
                            onClick={() => {
                              setCurrentStage(FORM_STAGES.setup);
                            }}
                            className="btn"
                            style={{ padding: "0 0 0.3125rem 7px" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14"
                              viewBox="0 0 15 14"
                              fill="none"
                            >
                              <path
                                d="M13.4534 4.59342C13.8061 4.24071 14.0029 3.77043 14.0029 3.27008C14.0029 2.76972 13.8088 2.29944 13.4534 1.94674L12.0589 0.552304C11.7035 0.194127 11.2332 0 10.7329 0C10.2325 0 9.76222 0.194127 9.40951 0.54957L0.712087 9.24699C0.225404 9.73368 -0.0316092 10.4035 0.00666927 11.0898L0.00393509 13.3154C0.00393509 13.4959 0.0750237 13.6709 0.20353 13.7994C0.332037 13.9279 0.50429 13.999 0.68748 13.999L2.9131 13.9963C3.59938 14.0345 4.26925 13.7775 4.75594 13.2908C5.02389 13.0229 5.02389 12.5909 4.75594 12.3229C4.48799 12.055 4.05599 12.055 3.78804 12.3229C3.57477 12.5362 3.27675 12.6483 2.97599 12.6292C2.96232 12.6292 2.94591 12.6264 2.93224 12.6264L1.37102 12.6319L1.37376 11.0707C1.37376 11.057 1.37376 11.0406 1.37102 11.0269C1.35189 10.7262 1.46399 10.4282 1.67725 10.2149L8.92556 2.96658L11.0363 5.07737L5.58986 10.5239C5.32191 10.7918 5.32191 11.2238 5.58986 11.4918C5.85781 11.7597 6.28981 11.7597 6.55776 11.4918L13.4534 4.59342ZM9.89346 1.99868L10.3774 1.51473C10.4731 1.41904 10.5989 1.36709 10.7356 1.36709C10.8696 1.36709 10.9981 1.41904 11.0938 1.51473L12.4882 2.90917C12.6851 3.10603 12.6851 3.42866 12.4882 3.62552L12.0042 4.10947L9.89346 1.99868Z"
                                fill="#194BFB"
                              />
                              <path
                                d="M13.3194 12.6319H7.15381C6.7765 12.6319 6.47027 12.9381 6.47027 13.3154C6.47027 13.6928 6.7765 13.999 7.15381 13.999H13.3194C13.6967 13.999 14.0029 13.6928 14.0029 13.3154C14.0029 12.9381 13.6967 12.6319 13.3194 12.6319Z"
                                fill="#194BFB"
                              />
                            </svg>
                          </button>
                        )}

                      {currentStage !== FORM_STAGES.setup ? (
                        <div
                          style={{
                            color: "#1A202C",
                            fontSize: "14px",
                            fontFamily: "Manrope",
                            fontWeight: 600,
                            marginBottom: "5px",
                          }}
                        >
                          Policy Name:
                          <span
                            className="section-value"
                            style={{
                              color: "#767980",
                              fontSize: "14px",
                              fontFamily: "Manrope",
                              paddingLeft: "5px",
                            }}
                          >
                            {currentSetup.policyName}
                          </span>
                        </div>
                      ) : null}

                      {currentStage !== FORM_STAGES.setup
                        ? currentSetup.envId != null && (
                            <div
                              style={{
                                color: "#1A202C",
                                fontSize: "14px",
                                fontFamily: "Manrope",
                                fontWeight: 600,
                                marginBottom: "5px",
                              }}
                            >
                              Environment ID:
                              <span
                                className="section-value"
                                style={{
                                  color: "#767980",
                                  fontSize: "14px",
                                  fontFamily: "Manrope",
                                  paddingLeft: "5px",
                                }}
                              >
                                {currentSetup.envId?.value}
                              </span>
                            </div>
                          )
                        : null}
                    </div>
                  </div>

                  <div
                    className="header"
                    style={{ display: "flex", marginBottom: "25px" }}
                  >
                    {currentStage == FORM_STAGES.input ? (
                      <img
                        src={ArrowIcon}
                        style={{ marginRight: "16px" }}
                      ></img>
                    ) : (
                      <div className="NewPolicy__Sidebar__IconSpacer"></div>
                    )}

                    <div className="section">
                      <span
                        style={{
                          color: "#1A202C",
                          fontSize: "16px",
                          fontFamily: "Manrope",
                          fontWeight: 700,
                        }}
                      >
                        Input
                      </span>
                      {currentStage != FORM_STAGES.input &&
                        currentInput.service != null &&
                        currentInput.rule != null && (
                          <button
                            onClick={() => {
                              setCurrentStage(FORM_STAGES.input);
                            }}
                            className="btn"
                            style={{ padding: "0 0 0.3125rem 7px" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14"
                              viewBox="0 0 15 14"
                              fill="none"
                            >
                              <path
                                d="M13.4534 4.59342C13.8061 4.24071 14.0029 3.77043 14.0029 3.27008C14.0029 2.76972 13.8088 2.29944 13.4534 1.94674L12.0589 0.552304C11.7035 0.194127 11.2332 0 10.7329 0C10.2325 0 9.76222 0.194127 9.40951 0.54957L0.712087 9.24699C0.225404 9.73368 -0.0316092 10.4035 0.00666927 11.0898L0.00393509 13.3154C0.00393509 13.4959 0.0750237 13.6709 0.20353 13.7994C0.332037 13.9279 0.50429 13.999 0.68748 13.999L2.9131 13.9963C3.59938 14.0345 4.26925 13.7775 4.75594 13.2908C5.02389 13.0229 5.02389 12.5909 4.75594 12.3229C4.48799 12.055 4.05599 12.055 3.78804 12.3229C3.57477 12.5362 3.27675 12.6483 2.97599 12.6292C2.96232 12.6292 2.94591 12.6264 2.93224 12.6264L1.37102 12.6319L1.37376 11.0707C1.37376 11.057 1.37376 11.0406 1.37102 11.0269C1.35189 10.7262 1.46399 10.4282 1.67725 10.2149L8.92556 2.96658L11.0363 5.07737L5.58986 10.5239C5.32191 10.7918 5.32191 11.2238 5.58986 11.4918C5.85781 11.7597 6.28981 11.7597 6.55776 11.4918L13.4534 4.59342ZM9.89346 1.99868L10.3774 1.51473C10.4731 1.41904 10.5989 1.36709 10.7356 1.36709C10.8696 1.36709 10.9981 1.41904 11.0938 1.51473L12.4882 2.90917C12.6851 3.10603 12.6851 3.42866 12.4882 3.62552L12.0042 4.10947L9.89346 1.99868Z"
                                fill="#194BFB"
                              />
                              <path
                                d="M13.3194 12.6319H7.15381C6.7765 12.6319 6.47027 12.9381 6.47027 13.3154C6.47027 13.6928 6.7765 13.999 7.15381 13.999H13.3194C13.6967 13.999 14.0029 13.6928 14.0029 13.3154C14.0029 12.9381 13.6967 12.6319 13.3194 12.6319Z"
                                fill="#194BFB"
                              />
                            </svg>
                          </button>
                        )}

                      {currentStage != FORM_STAGES.input
                        ? currentInput.service != null && (
                            <div
                              style={{
                                color: "#1A202C",
                                fontSize: "14px",
                                fontFamily: "Manrope",
                                fontWeight: 600,
                                marginBottom: "5px",
                              }}
                            >
                              Service:
                              <span
                                className="section-value"
                                style={{
                                  color: "#767980",
                                  fontSize: "14px",
                                  fontFamily: "Manrope",
                                  paddingLeft: "5px",
                                }}
                              >
                                {currentInput.service.value}
                              </span>
                            </div>
                          )
                        : null}

                      {currentStage != FORM_STAGES.input
                        ? currentInput.resourceType != null && (
                            <div
                              style={{
                                color: "#1A202C",
                                fontSize: "14px",
                                fontFamily: "Manrope",
                                fontWeight: 600,
                                marginBottom: "5px",
                              }}
                            >
                              Resource Type:
                              <span
                                className="section-value"
                                style={{
                                  color: "#767980",
                                  fontSize: "14px",
                                  fontFamily: "Manrope",
                                  paddingLeft: "5px",
                                }}
                              >
                                {currentInput.resourceType.value}
                              </span>
                            </div>
                          )
                        : null}

                      {currentStage != FORM_STAGES.input
                        ? currentInput.resourceType != null && (
                            <div
                              style={{
                                color: "#1A202C",
                                fontSize: "14px",
                                fontFamily: "Manrope",
                                fontWeight: 600,
                                marginBottom: "5px",
                              }}
                            >
                              Regions:
                              <span
                                className="section-value"
                                style={{
                                  color: "#767980",
                                  fontSize: "14px",
                                  fontFamily: "Manrope",
                                  paddingLeft: "5px",
                                }}
                              >
                                {currentInput.isRegionAny
                                  ? "Any Region"
                                  : `${currentInput.regions.length} Region${
                                      currentInput.regions.length > 1 ? "s" : ""
                                    }`}
                                {currentInput.isRegionAny ? "" : "Selected"}
                              </span>
                            </div>
                          )
                        : null}

                      {currentStage != FORM_STAGES.input
                        ? currentInput.rule != null && (
                            <div
                              style={{
                                color: "#1A202C",
                                fontSize: "14px",
                                fontFamily: "Manrope",
                                fontWeight: 600,
                                marginBottom: "5px",
                              }}
                            >
                              Rule:
                              <span
                                className="section-value"
                                style={{
                                  color: "#767980",
                                  fontSize: "14px",
                                  fontFamily: "Manrope",
                                  paddingLeft: "5px",
                                }}
                              >
                                {currentInput.rule.value}
                              </span>
                            </div>
                          )
                        : null}

                      {/* {currentStage != FORM_STAGES.input
                        ? currentInput.regions != null && (
                            <div>
                              <h6>Resource Tags</h6>
                              <span className="section-value">
                                {currentInput.resourceTags.map(
                                  (tag, i) =>
                                    `${tag}${
                                      i === currentInput.resourceTags.length - 1
                                        ? ""
                                        : ", "
                                    }`
                                )}
                              </span>
                            </div>
                          )
                        : null} */}

                      {/* {currentStage != FORM_STAGES.input
                        ? currentInput.regions != null && (
                            <div>
                              <h6>Resources</h6>
                              <span className="section-value">
                                {currentInput.resources.map(
                                  (r, i) =>
                                    `${r.resource_name}${
                                      i === currentInput.resources.length - 1
                                        ? ""
                                        : ", "
                                    }`
                                )}
                              </span>
                            </div>
                          )
                        : null} */}
                    </div>
                  </div>
                  <div className="header" style={{ display: "flex" }}>
                    {currentStage == FORM_STAGES.settings ? (
                      <img
                        src={ArrowIcon}
                        style={{ marginRight: "16px" }}
                      ></img>
                    ) : (
                      <div className="NewPolicy__Sidebar__IconSpacer"></div>
                    )}
                    <div
                      className="section"
                      style={{
                        color: "#1A202C",
                        fontSize: "16px",
                        fontFamily: "Manrope",
                        fontWeight: 700,
                      }}
                    >
                      Settings
                      {currentStage != FORM_STAGES.settings &&
                        currentSetting.isFilled != null && (
                          <button
                            onClick={() => {
                              setCurrentStage(FORM_STAGES.setup);
                            }}
                            className="btn"
                            style={{ padding: "0 0 0.3125rem 7px" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14"
                              viewBox="0 0 15 14"
                              fill="none"
                            >
                              <path
                                d="M13.4534 4.59342C13.8061 4.24071 14.0029 3.77043 14.0029 3.27008C14.0029 2.76972 13.8088 2.29944 13.4534 1.94674L12.0589 0.552304C11.7035 0.194127 11.2332 0 10.7329 0C10.2325 0 9.76222 0.194127 9.40951 0.54957L0.712087 9.24699C0.225404 9.73368 -0.0316092 10.4035 0.00666927 11.0898L0.00393509 13.3154C0.00393509 13.4959 0.0750237 13.6709 0.20353 13.7994C0.332037 13.9279 0.50429 13.999 0.68748 13.999L2.9131 13.9963C3.59938 14.0345 4.26925 13.7775 4.75594 13.2908C5.02389 13.0229 5.02389 12.5909 4.75594 12.3229C4.48799 12.055 4.05599 12.055 3.78804 12.3229C3.57477 12.5362 3.27675 12.6483 2.97599 12.6292C2.96232 12.6292 2.94591 12.6264 2.93224 12.6264L1.37102 12.6319L1.37376 11.0707C1.37376 11.057 1.37376 11.0406 1.37102 11.0269C1.35189 10.7262 1.46399 10.4282 1.67725 10.2149L8.92556 2.96658L11.0363 5.07737L5.58986 10.5239C5.32191 10.7918 5.32191 11.2238 5.58986 11.4918C5.85781 11.7597 6.28981 11.7597 6.55776 11.4918L13.4534 4.59342ZM9.89346 1.99868L10.3774 1.51473C10.4731 1.41904 10.5989 1.36709 10.7356 1.36709C10.8696 1.36709 10.9981 1.41904 11.0938 1.51473L12.4882 2.90917C12.6851 3.10603 12.6851 3.42866 12.4882 3.62552L12.0042 4.10947L9.89346 1.99868Z"
                                fill="#194BFB"
                              />
                              <path
                                d="M13.3194 12.6319H7.15381C6.7765 12.6319 6.47027 12.9381 6.47027 13.3154C6.47027 13.6928 6.7765 13.999 7.15381 13.999H13.3194C13.6967 13.999 14.0029 13.6928 14.0029 13.3154C14.0029 12.9381 13.6967 12.6319 13.3194 12.6319Z"
                                fill="#194BFB"
                              />
                            </svg>
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              </ContentBox>
            </div>
          </ContentBox>
        </PageContent>
      </PageContainer>
    )
  );
};

export default AddRemediationPolicy;
