import { Box, Skeleton } from '@mui/material';

import TableSkeleton from '@root/components/table/skeleton';
import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';
import Column from '@root/components/v2/layout/columns/Column';
import ColumnsContainer from '@root/components/v2/layout/columns/ColumnsContainer';

function CompliacneIndividualSkleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton />
      <ContentBox padding="15px">
        <ColumnsContainer columnSeparation={'15px'} marginBottom={'0'}>
          <Column width={'18%'} flexBasis={'unset'} flexGrow={'unset'}>
            <ContentBox
              paddingTop={'10px'}
              containerClassName={'border-box'}
              titleContainerStyle={{
                backgroundColor: 'var(--inventory-box-background)',
              }}
              title="Average Score"
              subtitle="Connected Environment(s) Score"
              titleSize="medium">
              <div
                style={{
                  // overflowY: 'auto',
                  height: '150px',
                  display: 'flex',
                  // flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className="compliance--metric"
                id="average-score">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  <Skeleton height="50px" width="50px" style={{ transform: 'none' }} />
                  <div
                    className="average--score--right--container"
                    style={{
                      alignItems: 'center',
                    }}>
                    <Skeleton height="50px" width="120px" style={{ transform: 'none' }} />
                  </div>
                </div>
              </div>
            </ContentBox>
          </Column>
          <Column width={'18%'} flexBasis={'unset'} flexGrow={'unset'}>
            <ContentBox
              paddingTop={'10px'}
              containerClassName={'border-box'}
              titleContainerStyle={{
                backgroundColor: 'var(--inventory-box-background)',
              }}
              title="Control Test"
              subtitle="Control Test Breakdown"
              titleSize="medium">
              <div
                style={{
                  height: '162px',
                  fontFamily: 'Manrope',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  gap: '20px',
                }}>
                <Skeleton variant="circular" height="88px" width="88px" style={{ transform: 'none' }} />
                <div>
                  <Skeleton height="20px" width="50px" style={{ transform: 'none', marginBottom: '10px' }} />{' '}
                  <Skeleton height="20px" width="50px" style={{ transform: 'none' }} />
                </div>
              </div>
            </ContentBox>
          </Column>
          <Column width={'30%'} flexBasis={'unset'} flexGrow={'unset'}>
            <ContentBox
              paddingTop={'10px'}
              containerClassName={'border-box'}
              titleContainerStyle={{
                backgroundColor: 'var(--inventory-box-background)',
              }}
              title="Environments"
              subtitle="Environment Wise Breakdown"
              titleSize="medium">
              <div
                style={{
                  height: '160px',
                  
                  // padding: '10px',
                }}>
                {[0, 1, 2].map((element, index) => (
                  <div
                    key={index}
                    style={{
                      // paddingTop: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '10px 5px',
                      fontFamily: 'Manrope',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                      }}>
                      <Skeleton height="43px" width="100px" style={{ transform: 'none' }} />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                      }}>
                      <Skeleton height="43px" width="100px" style={{ transform: 'none' }} />
                    </div>
                  </div>
                ))}
              </div>
            </ContentBox>
          </Column>
          <Column width={'30%'} flexBasis={'unset'} flexGrow={'unset'}>
            <ContentBox
              paddingTop={'10px'}
              containerClassName={'border-box'}
              titleContainerStyle={{
                backgroundColor: 'var(--inventory-box-background)',
              }}
              title="Compliance Over Time"
              subtitle="Resource Trend Over Time"
              titleSize="medium">
              <Box marginLeft="15px" marginRight="15px" marginBottom="15px" marginTop="15px">
                <Skeleton height="140px" width="100%" style={{ transform: 'none' }} />
              </Box>
            </ContentBox>
          </Column>
        </ColumnsContainer>
      </ContentBox>{' '}
      <Box className="Borders RoundedCorners" marginBottom="15px" marginTop="15px">
        <Skeleton height="56px" style={{ transform: 'none' }} />
      </Box>
      <ContentBox paddingMode="content" marginBottom="15px">
        <Box
          className="RoundedCorners"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="10px"
          height="40px"
          marginBottom="15px"
          marginTop="10px"
          paddingLeft="15px"
          paddingRight="15px">
          <Skeleton width="100px" height="44px" />
          <Skeleton width="100px" height="44px" />
          <Skeleton width="100px" height="44px" />
        </Box>
        <Box
          className="Borders RoundedCorners"
          marginLeft="15px"
          marginRight="15px"
          marginBottom="15px"
          marginTop="15px">
          <Skeleton height="40px" style={{ transform: 'none' }} />
        </Box>

        <TableSkeleton paddingLeft="15px" paddingRight="15px" />
      </ContentBox>
    </PageContent>
  );
}

export default CompliacneIndividualSkleton;
