import axios from 'axios';
const API_ROOT_LOCAL = import.meta.env.VITE_AXIOS_API_URL_ROOT; //dev
const API_BASE_ROOT = import.meta.env.VITE_AXIOS_BASE_URL_ROOT;

export const axiosPost = async (url, data, isBase = false, page = false) => {
  try {
    const tenantID = localStorage.getItem('tenantID');
    const token = localStorage.getItem('_t');

    if (url === 'account_activate') {
      const config = {
        headers: {
          // authorization: "Token " + token,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      };
      return await axios.post(`${isBase ? API_BASE_ROOT : API_ROOT_LOCAL}/${url}`, data, config);
    } else {
      const config = {
        headers: {
          authorization: 'Token ' + token,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      };
      // pageDetail = `?page=${page}`
      return await axios.post(
        `${isBase ? API_BASE_ROOT : API_ROOT_LOCAL}/${url}/${tenantID}${page ? '?page=' + page : ''}`,
        data,
        config,
      );
    }
  } catch (error) {
    // toast.error('Something went wrong!!',);
    console.error('HTTP POST request failed', error['message']);
  }
};
