import _ from 'lodash';
import {
  FETCH_RISK_DATA,
  FETCH_GLOBAL_RISK_FILTERS,
  UPDATE_GLOBAL_RISK_FILTERS,
  FETCH_RISK_EXPANSION_DATA,
  FETCH_RISK_EXCEPTION_DATA,
  REMOVE_RISK_EXPANSION_DATA,
  FETCH_EXCEPTION_RISK_EXPANSION_DATA,
} from '../constants/actionTypes';

const defaultState = {
  trigger: false,
  // trigger2: false,
  // status2: "loading",
  filters: {
    params: {},
    applied: { cloud_provider: 'aws' },
    filtersOnly: {
      cloud_provider: {
        cloud: 'aws',
        is_selected: 1,
      },
    },
    status: 'loading',
  },
  table: {
    data: [],
    expansion: [], // Array with data about the expanded rows
    expanded: [], // Array with all the ids of expanded rows
  },
  // table2: {
  // 	data: [],
  // 	expansion: [], // Array with data about the expanded rows
  // 	expanded: [], // Array with all the ids of expanded rows
  // },
  charts: {
    network: {},
    data: {},
    identity: {},
    overtime: [],
  },
  status: 'loading',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_RISK_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          trigger: false,
          // Setting the status
          status: action.payload['status'],
          last_updated: action.payload['data']['data']['last_updated_date'],
          // Setting the table info
          table: {
            ...state.table,
            data: !_.isEmpty(action.payload['data']['data']['risk_data'])
              ? action.payload['data']['data']['risk_data'].map((el, i) => {
                  return { ...el, sr_no: i };
                })
              : [],
          },
          // Setting the charts info
          charts: {
            network: !_.isEmpty(action.payload['data']['data']['total_network_risk'])
              ? action.payload['data']['data']['total_network_risk']
              : {},
            data: !_.isEmpty(action.payload['data']['data']['total_data_risk'])
              ? action.payload['data']['data']['total_data_risk']
              : {},
            identity: !_.isEmpty(action.payload['data']['data']['total_identity_risk'])
              ? action.payload['data']['data']['total_identity_risk']
              : {},
            overtime: !_.isEmpty(action.payload['data']['data']['risk_overtime'])
              ? action.payload['data']['data']['risk_overtime']
              : [],
          },
        };
      } else {
        return {
          ...state,
          trigger: false,
        };
      }
    case FETCH_RISK_EXCEPTION_DATA:
      if (!_.isEmpty(action.payload)) {
        // debugger;
        return {
          ...state,
          // Setting the table info
          trigger2: false,
          status2: action.payload['status'],
          table2: {
            ...state.table2,
            data: !_.isEmpty(action.payload['data']['data'])
              ? action.payload['data']['data'].map((el, i) => {
                  return { ...el, sr_no: i };
                })
              : [],
          },
          // Setting the charts info
        };
      } else {
        return {
          ...state,
          trigger2: false,
        };
      }
    case FETCH_GLOBAL_RISK_FILTERS:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            status: action.payload['status'],
            params: action.payload.data.data.filters,
          },
        };
      } else {
        return {
          ...state,
          trigger: false,
        };
      }
    case FETCH_RISK_EXPANSION_DATA:
      if (!_.isEmpty(action.payload)) {
        // console.log(action.payload,"action.payload")
        // debugger;
        return {
          ...state,
          table: {
            ...state.table,
            trigger: false,
            expansion: [
              ...state.table.expansion,
              {
                status: action.payload['response']['status'],
                data: [...action.payload['response'].data.data.expansion_data[0].risk_expansion_data],
                rule_id: action.payload['response'].data.data.expansion_data[0].rule_id,
              },
            ],
            expanded: [...state.table.expanded, action.payload['sr_no']],
          },
        };
      } else {
        return {
          ...state,
          trigger: false,
        };
      }
    case FETCH_EXCEPTION_RISK_EXPANSION_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          table2: {
            ...state.table2,
            trigger2: false,
            expansion: [
              {
                status: action.payload['response']['status'],
                data: [...action.payload['response'].data.data],
                // rule_id: action.payload["response"].data.data.expansion_data[0].rule_id,
              },
            ],
            expanded: [...state.table2.data, action.payload['sr_no']],
          },
        };
      } else {
        return {
          ...state,
          trigger2: false,
        };
      }
    case UPDATE_GLOBAL_RISK_FILTERS:
      return {
        ...state,
        trigger: true,
        filters: {
          ...state.filters,
          applied: action.payload['applied'],
          filtersOnly: action.payload['filtersOnly'],
        },
        table: {
          ...state.table,
          expanded: [],
          expansion: [],
        },
      };
    case REMOVE_RISK_EXPANSION_DATA:
      return {
        ...state,
        table: {
          ...state.table,
          expanded: state.table.expanded.filter(element => element !== action.payload),
        },
      };
    case 'Action_name':
      return {
        trigger: false,
        // trigger2: false,
        // status2: "loading",
        filters: {
          params: {},
          applied: { cloud_provider: 'aws' },
          filtersOnly: {
            cloud_provider: {
              cloud: 'aws',
              is_selected: 1,
            },
          },
          status: 'loading',
        },
        table: {
          data: [],
          expansion: [], // Array with data about the expanded rows
          expanded: [], // Array with all the ids of expanded rows
        },
        // table2: {
        // 	data: [],
        // 	expansion: [], // Array with data about the expanded rows
        // 	expanded: [], // Array with all the ids of expanded rows
        // },
        charts: {
          network: {},
          data: {},
          identity: {},
          overtime: [],
        },
        status: 'loading',
      };

    default:
      return state;
  }
};
