import axios from 'axios';
const API_ROOT_LOCAL = import.meta.env.VITE_AXIOS_API_URL_ROOT; //dev
const API_BASE_ROOT = import.meta.env.VITE_AXIOS_BASE_URL_ROOT;
export const axiosPut = async (url, data, isBase = false, deleteID) => {
  try {
    const tenantID = localStorage.getItem('tenantID');
    const token = localStorage.getItem('_t');
    const config = {
      headers: {
        authorization: 'Token ' + token,
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.put(
      `${isBase ? API_BASE_ROOT : API_ROOT_LOCAL}/${url}/${tenantID}${deleteID ? `/${deleteID}` : ''}`,
      data,
      config,
    );
    return res.data;
  } catch (error) {
    console.error('HTTP PUT request failed', error['message']);
  }
};
