import './style.scss';

function ColumnsContainer({ children, columnSeparation, className, marginTop, marginBottom, justifyContent }) {
  return (
    <div
      className={`ColumnsContainer columns-container columns-container-indi-compliance ${className || ''}`}
      style={{
        ...(columnSeparation && { gap: columnSeparation }),
        ...(marginTop && { marginTop }),
        ...(marginBottom && { marginBottom }),
        ...(justifyContent && { justifyContent }),
      }}>
      {children}
    </div>
  );
}

export default ColumnsContainer;
