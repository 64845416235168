import { TABLE_SORT_TRIGGERED } from '@root/constants/actionTypes';

const defaultState = {
  tableSortTriggered: false,
  sortRef: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TABLE_SORT_TRIGGERED:
      return {
        tableSortTriggered: !state.tableSortTriggered,
        sortRef: action.payload,
      };
    default:
      return defaultState;
  }
};
