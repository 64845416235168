import _ from 'lodash';
import {
  NOTIFICATION_BANNER_ELEMENT_CREATE,
  NOTIFICATION_BANNER_ELEMENT_UPDATE,
  NOTIFICATION_BANNER_ELEMENT_REMOVE,
  FETCH_MASTER_EVENTS_DATA,
  FETCH_CHILD_EVENTS_DATA,
  FETCH_GROUP_WITH_USERS_DATA,
  CLEAR_CHILD_EVENTS_DATA,
  CLEAR_NOTIFICATION_DATA,
  CLEAR_LOGS_NOTIFICATION_DATA,
  FETCH_NON_COMPLIANT_CHILD_EVENTS_DATA,
  CLEAR_GROUP_WITH_USER_DATA,
  // CREATE_NOTIFICATION,
  // UPDATE_NOTIFICATION_DATA,
  FETCH_NOTIFICATION_DATA,
  FETCH_LOGS_NOTIFICATION_DATA,
  // DELETE_NOTIFICATION_DATA,
  FILTER_SUGGESTIONS_NOTIFICATION,
  FILTER_SUGGESTIONS_LOGS,
  NOTIFICATION_BELL_DATA,
  NOTIFICATION_BELL_DATA_UPDATE,
  READ_ALL_NOTIFICATIONS,
} from '@constants/actionTypes';

const defaultState = {
  array: [],
  groups_with_user: {
    status: 0,
    tabledata: [],
    count: 1,
  },
  master_events: {
    m_events: [],
    count: 1,
    meventoptions: [],
    status: 'loading',
  },
  child_events: {
    c_events: [],
    count: 1,
    ceventoptions: [],
    status: 'loading',
  },
  non_compliant_child_events: {
    keys: [],
    values: [],
    status: 'loading',
  },
  tabledata: {
    results: [],
    count: 1,
    status: 'loading',
    message: '',
  },
  logstabledata: {
    results: [],
    count: 1,
    status: 'loading',
    message: '',
  },
  notification_list: {
    results: [],
    count: 0,
    status: 'loading',
    page: 1,
    prevPage: 1,
    hasMore: true,
  },
  settingsfilter: {
    events: [],
    groups: [],
    channels: [],
    createdby: [],
    status: 'loading',
  },
  logsfilter: {
    events: [],
    channels: [],
    status: 'loading',
  },
  status: 'loading',
};

// Each notification will have
// @params: message @type string
// @params: status @type string
// @params: id @type string

// id is created as {environment_id/scan_type}
// Where scan type can be remediation | fullScan | liveScan

export default (state = defaultState, action) => {
  switch (action.type) {
    case NOTIFICATION_BANNER_ELEMENT_CREATE:
      return {
        ...state,
        array: [
          ...state.array,
          {
            ...action.payload,
          },
        ],
      };
    case NOTIFICATION_BANNER_ELEMENT_UPDATE: {
      const { id } = action.payload;
      const index = state.array.findIndex(element => element.id === id);
      // If notification doesn't exist, it creates one instead.

      if (index !== -1) {
        return {
          ...state,
          array: state.array.map(element => (element.id === id ? { ...action.payload } : element)),
        };
      } else {
        return {
          ...state,
          array: [
            ...state.array,
            {
              ...action.payload,
            },
          ],
        };
      }
    }
    case NOTIFICATION_BANNER_ELEMENT_REMOVE: {
      return {
        ...state,
        array: state.array.filter(element => element.id !== action.payload),
      };
    }
    case FETCH_MASTER_EVENTS_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          master_events: {
            m_events: action.payload['data']['results'],
            count: action.payload['data']['count'],
            meventoptions: action.payload['data']['results'].map(itm => {
              return {
                label: itm.event_name,
                value: itm.event_name,
                key: itm.event_name,
              };
            }),
            status: action.payload['status'],
          },
        };
      }
      break;
    case FETCH_CHILD_EVENTS_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          child_events: {
            c_events: action.payload['data']['results'],
            count: action.payload['data']['count'],
            ceventoptions: action.payload['data']['results'].map(itm => {
              return {
                label: itm.child_event_name,
                value: itm.child_event_name,
                key: itm.id,
              };
            }),
            status: action.payload['status'],
          },
        };
      }
      break;
    case FETCH_NON_COMPLIANT_CHILD_EVENTS_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          non_compliant_child_events: {
            keys: Object.keys(action.payload['data'][0]).map(itm => {
              console.log(itm);
              return {
                label: itm,
                value: itm,
                key: itm,
              };
            }),
            values: Object.values(action.payload['data'][0]),
            status: action.payload['status'],
          },
        };
      }
      break;
    case CLEAR_CHILD_EVENTS_DATA:
      return {
        ...state,
        child_events: {
          c_events: [],
          count: 1,
          ceventoptions: [],
          status: 'loading',
        },
      };
    case FETCH_GROUP_WITH_USERS_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          groups_with_user: {
            tabledata: action.payload['data']['results'],
            count: action.payload['data']['count'],
            status: action.payload['status'],
          },
        };
      }
      break;
    case CLEAR_GROUP_WITH_USER_DATA:
      return {
        ...state,
        groups_with_user: {
          ...state.groups_with_user,
          status: 0,
          tabledata: [],
          count: 1,
        },
      };
    case FETCH_NOTIFICATION_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          tabledata: {
            // ...state.tabledata,
            results: action.payload['data']['results'],
            count: action.payload['data']['count'],
            status: action.payload['status'],
            message: action.payload['data']['message'],
          },
        };
      }
      break;
    case FETCH_LOGS_NOTIFICATION_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          logstabledata: {
            results: action.payload['data']['results'],
            count: action.payload['data']['count'],
            status: action.payload['status'],
            message: action.payload['data']['message'],
          },
        };
      }
      break;
    case FILTER_SUGGESTIONS_NOTIFICATION:
      if (!_.isEmpty(action.payload)) {
        let filter = JSON.parse(action.payload.config.data);
        return {
          ...state,
          settingsfilter: {
            ...state.settingsfilter,
            events:
              filter['data']['filter_parameter'] === 'event'
                ? action.payload.data.suggestions
                : state.settingsfilter.events,
            groups:
              filter['data']['filter_parameter'] === 'groups_name'
                ? action.payload.data.suggestions
                : state.settingsfilter.groups,
            channels:
              filter['data']['filter_parameter'] === 'channel'
                ? action.payload.data.suggestions
                : state.settingsfilter.channels,
            createdby:
              filter['data']['filter_parameter'] === 'created_by'
                ? action.payload.data.suggestions
                : state.settingsfilter.createdby,
            status: action.payload.status,
          },
        };
      }
      break;
    case FILTER_SUGGESTIONS_LOGS:
      if (!_.isEmpty(action.payload)) {
        let filter = JSON.parse(action.payload.config.data);
        return {
          ...state,
          logsfilter: {
            ...state.logsfilter,
            events:
              filter['data']['filter_parameter'] === 'event'
                ? action.payload.data.suggestions
                : state.logsfilter.events,
            channels:
              filter['data']['filter_parameter'] === 'channels'
                ? action.payload.data.suggestions
                : state.logsfilter.channels,
            status: action.payload.status,
          },
        };
      }
      break;
    case CLEAR_NOTIFICATION_DATA:
      return {
        ...state,
        tabledata: {
          // ...state.tabledata,
          results: [],
          count: 1,
          status: 'loading',
          message: '',
        },
      };
    case CLEAR_LOGS_NOTIFICATION_DATA:
      return {
        ...state,
        logstabledata: {
          results: [],
          count: 1,
          status: 'loading',
          message: '',
        },
      };

    case 'RESET_BELL_PAGE':
      return {
        ...state,
        notification_list: {
          unread_count: action.payload ?? state.notification_list.unread_count ?? null,
          results: [],
          count: 0,
          status: 'loading',
          page: 1,
          prevPage: 1,
          hasMore: true,
        },
      };
    case NOTIFICATION_BELL_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          notification_list: {
            results: [...state.notification_list.results, ...action.payload['data']['results']],
            count: action.payload['data']['count'],
            unread_count: action.payload['data']['unread_count'],
            status: action.payload['status'],
            page: state.notification_list.page + 1,
            prevPage: state.notification_list.page,
            hasMore: !!action.payload['data']['next'],
          },
        };
      }
      break;
    case NOTIFICATION_BELL_DATA_UPDATE:
      if (!_.isEmpty(action.payload)) {
        let newEntry = action.payload['notification_data'];
        let combinedNotifications = [];
        // if(!state.notification_list['results'].includes(newEntry)){
        // combinedNotifications = [newEntry, ...state.notification_list['results']];
        // }
        if (!state.notification_list['results'].includes(newEntry)) {
          combinedNotifications = [newEntry, ...state.notification_list['results']];
        }
        // let combinedNotifications = [newEntry, ...state.notification_list['results']];
        return {
          ...state,
          notification_list: {
            ...state.notification_list,
            count: state.notification_list.count + 1,
            unread_count: state.notification_list.unread_count + 1,
            results: [...combinedNotifications],
          },
        };
      }
      break;
    case READ_ALL_NOTIFICATIONS:
      return {
        ...state,
        notification_list: {
          ...state.notification_list,
          unread_count: 0,
        },
      };
    default:
      return state;
  }
};
