import { SET_LOGIN, SET_USER, SET_LOGOUT } from '../constants/actionTypes';

const defaultState = {
  isLoggedIn: false,
  info: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_LOGIN: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }
    case SET_USER: {
      return {
        ...state,
        info: action.payload,
      };
    }
    case SET_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        info: {},
      };
    default:
      return state;
  }
};
