import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Text from '@components/v2/ui-elements/Text';
import Button from '@components/v2/ui-elements/Button';

function ErrorBoundaryMessage() {
  const onReloadClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Box height="90vh" flex={1} display="flex" alignItems="center" justifyContent="center">
      <Box
        maxWidth="480px"
        border="1px solid rgba(0, 0, 0, 0.1)"
        padding="24px"
        borderRadius="8px"
        style={{ backgroundColor: '#fff' }}>
        <Text element="h2" fontWeight="bold" className="fs__7">
          Something went wrong.
        </Text>

        <Text element="p" className="fs__3">
          There was an unexpected error on the page. Please reload and try again.
        </Text>

        <Button onClick={onReloadClick}>Reload</Button>
      </Box>
    </Box>
  );
}

export default ErrorBoundaryMessage;
