import React from 'react';
import './style.scss';

function Column({ className, children, width, flexBasis, flexGrow, ...props }) {
  return (
    <div
      className={`Column column ${className || ''}`}
      {...props}
      style={{
        ...(width && { width }),
        ...(flexBasis && { flexBasis }),
        ...(flexGrow && { flexGrow }),
      }}>
      {children}
    </div>
  );
}

export default Column;
