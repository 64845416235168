import { useEffect, useMemo, useState } from "react";
import "./style.scss";

import searchIcon from "@assets/svg/search.svg";
import Switch from "@mui/material/Switch";
import ContentBox from "@root/components/v2/content/ContentBox";

import { Box, Checkbox } from "@mui/material";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import Tooltip from "@root/components/v2/ui-elements/Tooltip";
import _ from "lodash";
import SelectedResourcesModal from "../components/SelectedResourcesModal";

const BpIcon = styled("span")(() => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow: "inset 0px 0px 0px 2px #888888",

  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  // 'input:hover ~ &': {
  //   backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  // },
  // 'input:disabled ~ &': {
  //   boxShadow: 'none',
  //   background:
  //     theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  // },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#194BFB",
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 30%,transparent 32%)",
    content: '""',
  },
});

const SettingsForm = ({
  META,
  currentSetup,
  currentInput,
  rules,
  resources,
  setCurrentInput,
  setCurrentSetting,
}) => {
  const [showSelectedResourcesModal, setShowSelectedResourcesModal] =
    useState(false);
  const [resourceSearchTerm, setResourceSearchTerm] = useState("");

  const selectedRule = useMemo(() => {
    if (!rules || !rules.length) return null;

    return rules.find((r) => r.ruleId === currentInput.rule.ruleId);
  }, [rules, currentInput]);

  const selectedRuleInputs = useMemo(() => {
    if (!currentInput.rule) return [];

    const selectedRule = META.data.rulesDetails.find(
      (r) => r.rulesId === currentInput.rule.ruleId
    );

    const _inputs = [];

    for (
      let i = 1;
      i <= Object.keys(selectedRule.inputParameters).length;
      i++
    ) {
      const key = `p${i}`;
      const nameKey = `parameter_${i}_ui_param_name`;
      const placeholderKey = `parameter_${i}__ui_placeholder_text`;

      if (
        selectedRule.inputParameters[key][nameKey] &&
        selectedRule.inputParameters[key][placeholderKey]
      ) {
        _inputs.push({
          ...selectedRule.inputParameters[key],
          nameKey,
          placeholderKey,
        });
      }
    }

    return _inputs;
  }, [META.data.rulesDetails, currentInput.rule]);

  const filteredResources = useMemo(() => {
    if (!resourceSearchTerm || !resourceSearchTerm.trim()) {
      setCurrentInput((prev) => {
        return {
          ...prev,
          resources_manual: resources,
        };
      });
      return resources;
    }

    const searchTerm = resourceSearchTerm.toLowerCase();

    setCurrentInput((prev) => {
      return {
        ...prev,
        resources_manual: resources.filter((r) => {
          return r.resource_name.toLowerCase().includes(searchTerm);
        }),
      };
    });

    return resources.filter((r) => {
      return r.resource_name.toLowerCase().includes(searchTerm);
    });
  }, [resources, resourceSearchTerm, setCurrentInput]);

  const showManualHelpText = useMemo(() => {
    return true;
  }, []);
  const showAutoHelpText = useMemo(() => {
    if (currentInput.isResourceAny) {
      return currentInput.isResourceAutoAny;
    } else {
      return !_.isEmpty(currentInput.resources_auto);
    }
  }, [currentInput]);
  useEffect(() => {
    if (
      selectedRule.ruleId === "CQ-AWS-IAM-107" ||
      selectedRule.ruleId === "CQ-AWS-IAM-108" ||
      selectedRule.ruleId === "CQ-AWS-IAM-109" ||
      selectedRule.ruleId === "CQ-AWS-IAM-110"
    ) {
      setCurrentInput((prev) => ({
        ...prev,
        isResourceAny: true,
      }));
    }
  }, [selectedRule, setCurrentInput]);
  return (
    <div id="settingsForm">
      <ContentBox>
        <div
          style={{
            display: "flex",
            fontWeight: "700",
            color: "#1A202C",
            fontFamily: "Manrope",
            fontSize: "16px",
            marginBottom: "10px",
          }}
        >
          Select Resources and Remediation Type
          <span
            style={{
              color: "#F62121",
            }}
          >
            *
          </span>
        </div>
        <div className="SettingsForm__AnyAllBar">
          <div>
            <div>
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                height="33px"
                alignItems={"center"}
              >
                <Radio
                  disableRipple
                  color="default"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  checked={currentInput.isResourceAny}
                  disabled={
                    selectedRule.ruleId === "CQ-AWS-IAM-107" ||
                    selectedRule.ruleId === "CQ-AWS-IAM-108" ||
                    selectedRule.ruleId === "CQ-AWS-IAM-109" ||
                    selectedRule.ruleId === "CQ-AWS-IAM-110"
                      ? true
                      : !currentInput.rule
                  }
                  onChange={(e) => {
                    setCurrentInput((prev) => ({
                      ...prev,
                      isResourceAny: e.target.checked,
                    }));
                    if (e.target.checked) {
                      setCurrentInput((prev) => ({
                        ...prev,
                        isResourceManualAny: e.target.checked,
                        resources_manual: e.target.checked
                          ? filteredResources
                          : [],
                      }));
                      setCurrentSetting((prev) => ({
                        ...prev,
                        allowManual: e.target.checked,
                      }));
                    }
                  }}
                />
                <span
                  style={{
                    fontSize: "16px",
                    fontFamily: "Manrope",
                    fontWeight: 600,
                    color: "#484D56",
                    paddingRight: "1rem",
                  }}
                >
                  Any Resources
                  <Tooltip title="Policy will be applicable for selected resources">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 11.2332C8.87188 11.2332 11.2 8.90508 11.2 6.0332C11.2 3.16132 8.87188 0.833203 6 0.833203C3.12812 0.833203 0.800001 3.16132 0.800001 6.0332C0.8 8.90508 3.12812 11.2332 6 11.2332ZM12 6.0332C12 9.34691 9.31371 12.0332 6 12.0332C2.68629 12.0332 2.34843e-07 9.34691 5.24537e-07 6.0332C8.1423e-07 2.71949 2.68629 0.0332023 6 0.0332026C9.31371 0.0332029 12 2.71949 12 6.0332ZM6 9.65976C5.37909 9.65976 5.2 9.05412 5.2 8.8332L5.2 5.4732C5.2 5.25229 5.37909 4.85976 6 4.85977C6.62091 4.85977 6.8 5.25229 6.8 5.4732L6.8 8.8332C6.8 9.05412 6.62091 9.65977 6 9.65976Z"
                        fill="#718096"
                      />
                      <path
                        d="M5.2 3.65976C5.2 3.21794 5.55817 2.85976 6 2.85976C6.44183 2.85976 6.8 3.21794 6.8 3.65976C6.8 4.10159 6.44183 4.45976 6 4.45976C5.55817 4.45976 5.2 4.10159 5.2 3.65976Z"
                        fill="#718096"
                      />
                    </svg>
                  </Tooltip>
                </span>

                <Radio
                  disableRipple
                  color="default"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  checked={!currentInput.isResourceAny}
                  disabled={
                    selectedRule.ruleId === "CQ-AWS-IAM-107" ||
                    selectedRule.ruleId === "CQ-AWS-IAM-108" ||
                    selectedRule.ruleId === "CQ-AWS-IAM-109" ||
                    selectedRule.ruleId === "CQ-AWS-IAM-110"
                      ? true
                      : !currentInput.rule
                  }
                  onChange={(e) =>
                    setCurrentInput((prev) => ({
                      ...prev,
                      isResourceAny: !e.target.checked,
                    }))
                  }
                />
                <span
                  style={{
                    fontSize: "16px",
                    fontFamily: "Manrope",
                    fontWeight: 600,
                    color: "#484D56",
                  }}
                >
                  Select Resources
                </span>

                {!currentInput.isResourceAny && (
                  <div style={{ marginLeft: "1rem", flexGrow: 1 }}>
                    <img src={searchIcon} style={{ width: "12px" }} />

                    <input
                      className="SettingsForm__SearchBar"
                      placeholder="Search (/) for resources ID"
                      value={resourceSearchTerm}
                      onChange={(e) => setResourceSearchTerm(e.target.value)}
                    />
                  </div>
                )}
                <Box flexGrow={1} />

                {!currentInput.isResourceAny ? (
                  <>
                    <Box
                      fontSize="14px"
                      color="#476FFC"
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontFamily: "Manrope",
                        fontWeight: "500",
                        marginRight: "8px",
                        borderRight: "1px solid #CBD5E0",
                        paddingRight: "8px",
                      }}
                      onClick={() => setShowSelectedResourcesModal(true)}
                    >
                      View Selected Resource
                    </Box>
                    <span>
                      <span
                        style={{
                          fontFamily: "Manrope",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#484D56",
                          marginRight: "10px",
                        }}
                      >
                        Include All
                      </span>
                      <Checkbox
                        name="all props.resources"
                        disableRipple
                        size="small"
                        sx={{
                          padding: "0",
                          "& .MuiSvgIcon-root": { fontSize: 19 },
                          "&.Mui-checked.Mui-disabled": {
                            color: "#A3B7FD",
                          },
                          "&.Mui-checked": {
                            color: "#194BFB",
                          },
                        }}
                        checked={
                          resources.length > 0
                            ? resources.every((resource) =>
                                currentInput.resources.find(
                                  (r) => r.resource_id === resource.resource_id
                                )
                              ) || currentInput.isResourceAny
                            : false
                        }
                        disabled={
                          currentInput.isResourceAny ||
                          !currentInput.rule ||
                          !resources.length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCurrentInput((prev) => ({
                              ...prev,
                              resources: [
                                ...prev.resources,
                                ...filteredResources.filter(
                                  (r) =>
                                    !prev.resources.find(
                                      (r2) => r2.resource_id === r.resource_id
                                    )
                                ),
                              ],
                            }));
                          } else {
                            setCurrentInput((prev) => ({
                              ...prev,
                              resources: [],
                            }));
                          }
                        }}
                      />
                    </span>
                  </>
                ) : (
                  <span
                    style={{
                      fontWeight: "500",
                      fontFamily: "Manrope",
                      color: "#484D56",
                      fontSize: "16px",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Switch
                        name="toggle"
                        checked={currentInput.isResourceManualAny}
                        onChange={(e) => {
                          setCurrentInput((prev) => ({
                            ...prev,
                            isResourceManualAny: e.target.checked,
                            resources_manual: e.target.checked
                              ? filteredResources
                              : [],
                          }));

                          setCurrentSetting((prev) => ({
                            ...prev,
                            allowManual: e.target.checked,
                          }));
                        }}
                        size="small"
                        color="primary"
                      />{" "}
                      Allow Manual
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                        marginLeft: "10px",
                      }}
                    >
                      <Switch
                        // checked={props.currentSetting.allowAutomated}
                        checked={currentInput.isResourceAutoAny}
                        disabled={
                          !(
                            META?.data.environments.filter(
                              (item) =>
                                item.enviromentId ===
                                currentSetup.envId?.value.toString()
                            )[0] &&
                            META?.data.environments.filter(
                              (item) =>
                                item.enviromentId ===
                                currentSetup.envId?.value.toString()
                            )[0]?.autoRemediation
                          ) ||
                          selectedRule.ruleId === "CQ-AWS-IAM-107" ||
                          selectedRule.ruleId === "CQ-AWS-IAM-108" ||
                          selectedRule.ruleId === "CQ-AWS-IAM-109" ||
                          selectedRule.ruleId === "CQ-AWS-IAM-110"
                        }
                        onChange={(e) => {
                          setCurrentInput((prev) => ({
                            ...prev,
                            isResourceAutoAny: e.target.checked,
                            resources_auto: e.target.checked
                              ? filteredResources
                              : [],
                          }));

                          setCurrentSetting((prev) => ({
                            ...prev,
                            allowAutomated: e.target.checked,
                          }));
                        }}
                        size="small"
                        color="primary"
                      />
                      Allow Automated
                    </div>
                  </span>
                )}
              </Box>
            </div>

            {!filteredResources.length ? (
              <Box
                className="fs__-2"
                sx={{
                  padding: "24px",
                  textAlign: "center",
                  fontFamily: "Manrope",
                  fontSize: "14px !important",
                  fontWeight: 600,
                }}
              >
                No Resources Available
              </Box>
            ) : null}

            <div
              style={{
                maxHeight: "200px",
                overflow: "auto",
                marginTop: "10px",
              }}
              className="CustomScrollbar"
            >
              {!currentInput.isResourceAny &&
                filteredResources.map((resource) => {
                  return (
                    <div key={resource} className="SettingsForm__ResourceItem">
                      <span title={resource.resource_id}>
                        {resource.resource_name || resource.resource_id}
                      </span>
                      <div style={{ flexGrow: "1" }} />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                          fontSize: "16px",
                        }}
                      >
                        <Switch
                          name="toggle"
                          checked={currentInput.resources_manual.find(
                            (r) => r.resource_id === resource.resource_id
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCurrentInput((prev) => ({
                                ...prev,
                                resources_manual: [
                                  ...prev.resources_manual,
                                  resource,
                                ],
                              }));
                            } else {
                              setCurrentInput((prev) => ({
                                ...prev,
                                resources_manual: prev.resources_manual.filter(
                                  (r) => r.resource_id !== resource.resource_id
                                ),
                              }));
                            }
                          }}
                          size="small"
                          color="primary"
                        />{" "}
                        Allow Manual
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                          fontSize: "16px",
                        }}
                      >
                        <Switch
                          // checked={props.currentSetting.allowAutomated}
                          checked={currentInput.resources_auto.find(
                            (r) => r.resource_id === resource.resource_id
                          )}
                          disabled={
                            !(
                              META?.data.environments.filter(
                                (item) =>
                                  item.enviromentId ===
                                  currentSetup.envId?.value.toString()
                              )[0] &&
                              META?.data.environments.filter(
                                (item) =>
                                  item.enviromentId ===
                                  currentSetup.envId?.value.toString()
                              )[0]?.autoRemediation
                            )
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCurrentInput((prev) => ({
                                ...prev,
                                resources_auto: [
                                  ...prev.resources_auto,
                                  resource,
                                ],
                              }));
                            } else {
                              setCurrentInput((prev) => ({
                                ...prev,
                                resources_auto: prev.resources_auto.filter(
                                  (r) => r.resource_id !== resource.resource_id
                                ),
                              }));
                            }
                            setCurrentSetting((prev) => ({
                              ...prev,
                              allowAutomated: e.target.checked,
                            }));
                          }}
                          size="small"
                          color="primary"
                        />
                        Allow Automated
                      </div>
                      <Checkbox
                        disableRipple
                        size="small"
                        sx={{
                          padding: "0",
                          "& .MuiSvgIcon-root": { fontSize: 19 },
                          "&.Mui-checked.Mui-disabled": {
                            color: "#A3B7FD",
                          },
                          "&.Mui-checked": {
                            color: "#194BFB",
                          },
                        }}
                        id={resource}
                        checked={
                          currentInput.resources.find(
                            (r) => r.resource_id === resource.resource_id
                          ) || currentInput.isResourceAny
                        }
                        disabled={
                          currentInput.isResourceAny || !currentInput.rule
                        }
                        name={resource}
                        onChange={(ev) => {
                          if (ev.target.checked) {
                            setCurrentInput((prev) => ({
                              ...prev,
                              resources: [...prev.resources, resource],
                            }));
                          } else {
                            setCurrentInput((prev) => ({
                              ...prev,
                              resources: prev.resources.filter(
                                (r) => r.resource_id !== resource.resource_id
                              ),
                            }));
                          }
                        }}
                        value={resource}
                        type="checkbox"
                      />
                    </div>
                  );
                })}
            </div>
            {/* <Box className="CreateRemediationPolicy__TableFooter">
              <Box onClick={() => setIsResourcesExpanded((prev) => !prev)}>
                {isResourcesExpanded ? (
                  <KeyboardDoubleArrowUp />
                ) : (
                  <KeyboardDoubleArrowDown />
                )}
                <span style={{ color: "#476FFC" }}>
                  {isResourcesExpanded ? "Collapse" : "Expand"} Resource
                </span>
              </Box>
              <Box flexGrow={1} />
              <Box>{currentInput.resources.length} Selected</Box>
            </Box> */}

            {/* <FormControlLabel
              control={<CustomSwitch sx={{ m: 1 }} defaultChecked />}
              id="manual"
              checked={props.currentSetting.allowManual}
              onChange={(e) => {
                props.setCurrentSetting((prev) => ({
                  ...prev,
                  allowManual: e.target.checked,
                }));
              }}
            />
            <span
              style={{
                color: "#484D56",
                fontFamily: "Manrope",
                fontSize: "16px",
                margin: "6px 20px 0 0",
              }}
            >
              Allow Manual
            </span>
            <FormControlLabel
              control={<CustomSwitch sx={{ m: 1 }} defaultChecked />}
              id="automated"
              checked={props.currentSetting.allowAutomated}
              disabled={
                !(
                  META?.data.environments.filter(
                    (item) =>
                      item.enviromentId === currentSetup.envId?.value.toString()
                  )[0] &&
                  META?.data.environments.filter(
                    (item) =>
                      item.enviromentId === currentSetup.envId?.value.toString()
                  )[0]?.autoRemediation
                )
              }
              onChange={(e) => {
                props.setCurrentSetting((prev) => ({
                  ...prev,
                  allowAutomated: e.target.checked,
                }));
              }}
            />
            <span
              style={{
                color: "#484D56",
                fontFamily: "Manrope",
                fontSize: "16px",
                margin: "6px 20px 0 0",
              }}
            >
              Allow Automated
            </span> */}

            {/* <FormControlLabel
            control={<CustomSwitch sx={{ m: 1 }} defaultChecked />}
            id="notification"
            checked={props.currentSetting.notification}
            disabled
            onChange={(e) => {
              props.setCurrentSetting((prev) => ({
                ...prev,
                notification: e.target.checked,
              }));
            }}
          />
          <span
            style={{
              color: "#484D56",
              fontFamily: "Manrope",
              fontSize: "16px",
              margin: "6px 20px 0 0",
            }}
          >
            Notification
          </span> */}
          </div>
        </div>
      </ContentBox>
      <ContentBox>
        <div className="w-100">
          <div
            style={{
              color: "#1A202C",
              fontSize: "16px",
              fontFamily: "Manrope",
              fontWeight: 700,
              marginBottom: "5px",
            }}
          >
            Settings*
          </div>

          <div>
            {/* {props.currentSetting.notification && (
            <div className="my-4" style={{ maxWidth: "400px" }}>
              <MultiReactSelect
                allowSelectAll={false}
                isSearchable={false}
                name="notif_select"
                value={
                  Object.keys(props.currentSetting.notificationDestination)
                    .length
                    ? props.currentSetting.notificationDestination
                    : null
                }
                onChange={(e) => {
                  props.setCurrentSetting((currentConfig) => ({
                    ...currentConfig,
                    notificationDestination: e,
                  }));
                }}
                inputProps={{
                  id: "notif_select",
                }}
                className="notif_select"
                placeholder="Select Destination"
                options={
                  DEMO_DATA.notification != null
                    ? DEMO_DATA.notification.map((e) => {
                        return {
                          value: e,
                          label: (
                            <div>
                              <img src={NotifIconMap[e]} />
                              <span className="mx-2">{e}</span>
                            </div>
                          ),
                        };
                      })
                    : []
                }
              />
            </div>
          )} */}
            {/* {props.currentSetting.notification &&
            props.currentSetting.notificationDestination && (
              <div>
                {props.currentSetting.notificationDestination.value ===
                "Email" ? (
                  <div style={{ maxWidth: "400px", marginBottom: "32px" }}>
                    <Input
                      value={props.currentSetting.notificationEmail ?? ""}
                      onChange={(e) => {
                        e.persist();
                        props.setCurrentSetting((currentConfig) => ({
                          ...currentConfig,
                          notificationEmail: e.target.value,
                        }));
                      }}
                      type="text"
                      className="input"
                      placeholder="Please enter a valid email address"
                    ></Input>
                  </div>
                ) : null}

                <h5 className="my-3">Notification Frequency*</h5>
                <div className="radio-group d-flex flex-row">
                  <div.Check
                    label="Remediation Initiation"
                    id="initiated"
                    value={"remediation_initiation"}
                    checked={
                      props.currentSetting.notificationFreq
                        .remediation_initiation
                    }
                    onChange={(e) => {
                      props.setCurrentSetting((input) => ({
                        ...input,
                        notificationFreq: {
                          ...input.notificationFreq,
                          remediation_initiation: e.target.checked,
                        },
                      }));
                    }}
                  />
                  <div.Check
                    label="Remediation Execution"
                    id="execution"
                    value={"remediation_execution"}
                    checked={
                      props.currentSetting.notificationFreq
                        .remediation_execution
                    }
                    onChange={(e) => {
                      props.setCurrentSetting((input) => ({
                        ...input,
                        notificationFreq: {
                          ...input.notificationFreq,
                          remediation_execution: e.target.checked,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            )} */}
          </div>
          {/* <MultiReactSelect
          allowSelectAll={false}
          name="service_select"
          value={currentSetting.service}
          onChange={(e) => {
            setCurrentSetting((currentConfig) => ({
              ...currentConfig,
              service: e,
            }));
          }}
          inputProps={{
            placeholder: "Select Service",
            id: "service_select",
          }}
          className="service_select"
          options={
            DEMO_DATA.services != null
              ? DEMO_DATA.services.sort().map((e) => {
                  return { value: e, label: e };
                })
              : []
          }
        /> */}
          <ContentBox marginTop="15px">
            <div
              style={{
                color: "#A3A6AB",
                fontSize: "14px",
                fontFamily: "Manrope",
              }}
            >
              {selectedRule ? selectedRule.ruleData.etrText : ""}
            </div>
            <div
              style={{
                color: "#1A202C",
                fontSize: "16px",
                fontFamily: "Manrope",
                fontWeight: 600,
                margin: "15px 0",
              }}
            >
              This Policy Supports :
            </div>
            <div>
              {selectedRuleInputs.length ? (
                <div className="check-line">
                  <svg
                    style={{ position: "relative", top: "3px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_12281_432329)">
                      <path
                        d="M6.88 9.44L5.14 7.7C4.99333 7.55333 4.81333 7.48 4.6 7.48C4.38667 7.48 4.2 7.56 4.04 7.72C3.89333 7.86667 3.82 8.05333 3.82 8.28C3.82 8.50667 3.89333 8.69333 4.04 8.84L6.32 11.12C6.46667 11.2667 6.65333 11.34 6.88 11.34C7.10667 11.34 7.29333 11.2667 7.44 11.12L11.98 6.58C12.1267 6.43333 12.2 6.25333 12.2 6.04C12.2 5.82667 12.12 5.64 11.96 5.48C11.8133 5.33333 11.6267 5.26 11.4 5.26C11.1733 5.26 10.9867 5.33333 10.84 5.48L6.88 9.44ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9493 3.06 14.3795 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210667 10.1467 0.000533333 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05067 3.90667 1.62053 3.06 2.34 2.34C3.06 1.62 3.90667 1.05013 4.88 0.6304C5.85333 0.210667 6.89333 0.000533333 8 0C9.10667 0 10.1467 0.210133 11.12 0.6304C12.0933 1.05067 12.94 1.62053 13.66 2.34C14.38 3.06 14.9501 3.90667 15.3704 4.88C15.7907 5.85333 16.0005 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9493 12.0933 14.3795 12.94 13.66 13.66C12.94 14.38 12.0933 14.9501 11.12 15.3704C10.1467 15.7907 9.10667 16.0005 8 16Z"
                        fill="#03BB34"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_12281_432329">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{" "}
                  <span
                    style={{
                      color: "#A3A6AB",
                      fontSize: "14px",
                      fontFamily: "Manrope",
                    }}
                  >
                    Custom Input Parameters
                  </span>
                </div>
              ) : null}

              {selectedRule.ruleData ? (
                <>
                  <div
                    className="check-line"
                    style={{
                      transition: "all 200ms",
                      height: showManualHelpText ? "21px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <svg
                      style={{ position: "relative", top: "3px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_12281_432329)">
                        <path
                          d="M6.88 9.44L5.14 7.7C4.99333 7.55333 4.81333 7.48 4.6 7.48C4.38667 7.48 4.2 7.56 4.04 7.72C3.89333 7.86667 3.82 8.05333 3.82 8.28C3.82 8.50667 3.89333 8.69333 4.04 8.84L6.32 11.12C6.46667 11.2667 6.65333 11.34 6.88 11.34C7.10667 11.34 7.29333 11.2667 7.44 11.12L11.98 6.58C12.1267 6.43333 12.2 6.25333 12.2 6.04C12.2 5.82667 12.12 5.64 11.96 5.48C11.8133 5.33333 11.6267 5.26 11.4 5.26C11.1733 5.26 10.9867 5.33333 10.84 5.48L6.88 9.44ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9493 3.06 14.3795 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210667 10.1467 0.000533333 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05067 3.90667 1.62053 3.06 2.34 2.34C3.06 1.62 3.90667 1.05013 4.88 0.6304C5.85333 0.210667 6.89333 0.000533333 8 0C9.10667 0 10.1467 0.210133 11.12 0.6304C12.0933 1.05067 12.94 1.62053 13.66 2.34C14.38 3.06 14.9501 3.90667 15.3704 4.88C15.7907 5.85333 16.0005 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9493 12.0933 14.3795 12.94 13.66 13.66C12.94 14.38 12.0933 14.9501 11.12 15.3704C10.1467 15.7907 9.10667 16.0005 8 16Z"
                          fill="#03BB34"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_12281_432329">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>{" "}
                    <span
                      style={{
                        color: "#A3A6AB",
                        fontSize: "14px",
                        fontFamily: "Manrope",
                      }}
                    >
                      Manually-triggered remediations via scan reports
                    </span>
                  </div>
                  <div
                    className="check-line"
                    style={{
                      marginTop: showManualHelpText ? "7px" : "",
                      transition: "all 200ms",
                      height: showAutoHelpText ? "21px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <svg
                      style={{ position: "relative", top: "3px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_12281_432329)">
                        <path
                          d="M6.88 9.44L5.14 7.7C4.99333 7.55333 4.81333 7.48 4.6 7.48C4.38667 7.48 4.2 7.56 4.04 7.72C3.89333 7.86667 3.82 8.05333 3.82 8.28C3.82 8.50667 3.89333 8.69333 4.04 8.84L6.32 11.12C6.46667 11.2667 6.65333 11.34 6.88 11.34C7.10667 11.34 7.29333 11.2667 7.44 11.12L11.98 6.58C12.1267 6.43333 12.2 6.25333 12.2 6.04C12.2 5.82667 12.12 5.64 11.96 5.48C11.8133 5.33333 11.6267 5.26 11.4 5.26C11.1733 5.26 10.9867 5.33333 10.84 5.48L6.88 9.44ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9493 3.06 14.3795 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210667 10.1467 0.000533333 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05067 3.90667 1.62053 3.06 2.34 2.34C3.06 1.62 3.90667 1.05013 4.88 0.6304C5.85333 0.210667 6.89333 0.000533333 8 0C9.10667 0 10.1467 0.210133 11.12 0.6304C12.0933 1.05067 12.94 1.62053 13.66 2.34C14.38 3.06 14.9501 3.90667 15.3704 4.88C15.7907 5.85333 16.0005 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9493 12.0933 14.3795 12.94 13.66 13.66C12.94 14.38 12.0933 14.9501 11.12 15.3704C10.1467 15.7907 9.10667 16.0005 8 16Z"
                          fill="#03BB34"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_12281_432329">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>{" "}
                    <span
                      style={{
                        color: "#A3A6AB",
                        fontSize: "14px",
                        fontFamily: "Manrope",
                      }}
                    >
                      Automated Remediation
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </ContentBox>
        </div>
      </ContentBox>
      <SelectedResourcesModal
        open={showSelectedResourcesModal}
        resources={currentInput.resources}
        isResourceAny={currentInput.isResourceAny}
        onClose={() => setShowSelectedResourcesModal(false)}
      />
    </div>
  );
};

export default SettingsForm;
