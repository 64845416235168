import Box from '@mui/material/Box';
import './style.scss';

function InfoBox({ message = '', children, style = {} }) {
  return (
    <Box className="InfoBox" style={{ ...style }}>
      {children || message}
    </Box>
  );
}

export default InfoBox;
