import { Box, Grid, Skeleton } from '@mui/material';

function NotificationPanelSkleton() {
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}>
        <Box style={{ padding: '8px' }}>
          <Skeleton height="30px" width="60px" style={{ transform: 'none' }} />
        </Box>
        <Box style={{ cursor: 'pointer' }}>
          <Skeleton height="30px" width="180px" style={{ transform: 'none' }} />
        </Box>
      </div>
      {Array.from(new Array(6)).map((e, index) => {
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '12px 16px',
                borderBottom: index === 6 ? '' : '1px solid #cbd5e0',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '10px',
                  flexDirection: 'column',
                  gap: '10px',
                }}>
                <Skeleton height="15px" width="140px" style={{ transform: 'none' }} />
                <Skeleton height="15px" width="140px" style={{ transform: 'none' }} />
                <Skeleton height="15px" width="140px" style={{ transform: 'none' }} />
              </Box>
              <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton height="15px" width="80px" style={{ transform: 'none' }} />
              </Grid>
            </Box>
          </>
        );
      })}
    </>
  );
}

export default NotificationPanelSkleton;
