import Loader from '@components/common/Loader';
import { Box } from '@mui/material';

function RouteLoader() {
  return (
    <Box
      position={'absolute'}
      left={240} // The left drawer width
      top={0}
      width="calc(100% - 240px)"
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent="center"
      height={'100%'}>
      <Loader />
    </Box>
  );
}

export default RouteLoader;
