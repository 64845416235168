import _ from 'lodash';
import {
  FETCH_COMPLIANCE_DASHBOARD_DATA,
  UPDATE_COMPLIANCE_DASHBOARD_FILTER,
  FETCH_INDIVIDUAL_COMPLIANCE_DATA,
  FETCH_INDIVIDUAL_COMPLIANCE_EXPANSION,
  REMOVE_INDIVIDUAL_COMPLIANCE_EXPANSION,
  UPDATE_INDIVIDUAL_COMPLIANCE_FILTER,
  FETCH_ALL_COMPLIANCE_CATEGORIES,
} from '../constants/actionTypes';

const defaultState = {
  dashboard: {
    status: 'loading',
    data: {},
    filter: {
      applied: { cloud_provider: 'aws' },
      params: { cloud_provider: 'aws' },
    },
    categories: [],
    trigger: false, // A boolean to trigger filter reload
  },
  individualCompliance: {
    status: 'loading',
    data: {},
    expansion: [], // Array with data about the expanded rows
    expanded: [], // Array with all the ids of expanded rows
    filter: {
      applied: {},
      params: {},
    },
    trigger: false, // A boolean to trigger filter reload
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_COMPLIANCE_DASHBOARD_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            status: action.payload['status'],
            // If the data is empty, keep it empty and show no data card in compliance
            data: !_.isEmpty(action.payload['data']) ? action.payload['data']['data']['data'] : {},
            filter: {
              ...state.dashboard.filter,
              params: !_.isEmpty(action.payload.data.data.data.filters)
                ? action.payload.data.data.data.filters
                : state.dashboard.filter.params,
            },
            trigger: false,
          },
        };
      }
      break;
    case FETCH_ALL_COMPLIANCE_CATEGORIES:
      if (!_.isEmpty(action.payload)) {
        if (!_.isEmpty(action.payload)) {
          console.log(action.payload, 'complianceCategories');
          return {
            ...state,
            dashboard: {
              ...state.dashboard,
              categories: !_.isEmpty(action.payload['data']) ? action.payload['data']['data']['categories'] : {},
            },
          };
        }
      }
      break;

    case UPDATE_COMPLIANCE_DASHBOARD_FILTER:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          filter: {
            ...state.dashboard.filter,
            applied: action.payload,
          },
          trigger: true,
        },
      };
    case FETCH_INDIVIDUAL_COMPLIANCE_DATA:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          individualCompliance: {
            ...state.individualCompliance,
            status: action.payload['status'],
            // If the data is empty, keep it empty and show no data card in compliance
            data: !_.isEmpty(action.payload['data']) ? action.payload['data']['data']['data'] : {},
            trigger: false,
            filter: {
              ...state.individualCompliance.filter,
              params: !_.isEmpty(action.payload.data.data.data.filters)
                ? action.payload.data.data.data.filters
                : state.individualCompliance.filter.params,
            },
            expansion: [],
            expanded: [],
          },
        };
      }
      break;
    case FETCH_INDIVIDUAL_COMPLIANCE_EXPANSION:
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          individualCompliance: {
            ...state.individualCompliance,
            expansion: [
              ...state.individualCompliance.expansion,
              {
                control: action.payload.data.data.data.control,
                data: action.payload.data.data.data.rule_expansion_data,
                status: action.payload['status'],
              },
            ],
            expanded: [action.payload.data.data.data.control],
          },
        };
      }
      break;
    case UPDATE_INDIVIDUAL_COMPLIANCE_FILTER:
      return {
        ...state,
        individualCompliance: {
          ...state.individualCompliance,
          filter: {
            ...state.individualCompliance.filter,
            applied: action.payload,
          },
          trigger: true,
        },
      };
    case REMOVE_INDIVIDUAL_COMPLIANCE_EXPANSION:
      return {
        ...state,
        individualCompliance: {
          ...state.individualCompliance,
          expanded: state.individualCompliance.expanded.filter(element => element !== action.payload),
        },
      };
    default:
      return state;
  }
};
